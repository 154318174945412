import FeatureGate from '@components/Feature/FeatureGate';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import { AutoAwesome, Delete, Edit, Save, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { useRecommendedStore } from '@store/recommendedStore';
import { useSavedSearchesStore } from '@store/savedSearchesStore';
import { AiSearch, FeatureId, LicitacoesSearchParams, SavedSearch } from '@types';
import { isEqual } from '@utils/isEqual';
import { buildAiGeneratedSearch, buildAiSearches } from '@utils/search';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AiSearchModal from './AiSearchModal';

interface SavedSearchesProps {
  currentSearchParams: LicitacoesSearchParams;
  onSearchSelect: (params: LicitacoesSearchParams, searchId: string) => void;
  activeSearchId: string | null;
}

type SearchType = 'ai' | 'manual' | 'preference' | 'ai-search';

interface SearchChipProps {
  type: SearchType;
  label: string;
  active: boolean;
  onSelect: () => void;
  onDelete?: () => void;
  hasPreferences?: boolean;
  tooltip?: string;
}

const SearchChip: React.FC<SearchChipProps> = ({
  type,
  label,
  tooltip = '',
  active,
  onSelect,
  onDelete,
  hasPreferences = false,
}) => {
  const getChipIcon = () => {
    switch (type) {
      case 'ai':
        return <AutoAwesome fontSize="small" />;
      case 'preference':
        return <Settings fontSize="small" />;
      default:
        return undefined;
    }
  };

  return (
    <Chip
      icon={getChipIcon()}
      label={label}
      title={tooltip}
      onClick={onSelect}
      onDelete={type === 'manual' ? onDelete : undefined}
      deleteIcon={type === 'manual' ? <Delete fontSize="small" /> : undefined}
      color={active ? 'primary' : 'default'}
      sx={{
        backgroundColor: active
          ? 'primary.main'
          : type === 'preference'
            ? hasPreferences
              ? 'rgba(0, 0, 0, 0.08)'
              : 'grey.300'
            : undefined,
        color: active ? 'primary.contrastText' : 'text.primary',
        '&:hover': {
          backgroundColor: active
            ? 'primary.dark'
            : type === 'preference'
              ? hasPreferences
                ? 'rgba(0, 0, 0, 0.12)'
                : 'grey.400'
              : undefined,
        },
      }}
    />
  );
};

const SavedSearches: React.FC<SavedSearchesProps> = ({
  currentSearchParams,
  onSearchSelect,
  activeSearchId,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { userPreferences, currentProfileId } = useProfileStore();
  const {
    savedSearches,
    fetchSavedSearches,
    addSavedSearch,
    removeSavedSearch,
    updateSavedSearch,
    getPreferencesAsSearch,
  } = useSavedSearchesStore();
  const { aiProfile, fetchAiProfile } = useRecommendedStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [editingSearch, setEditingSearch] = useState<string | null>(null);
  const [deletingSearch, setDeletingSearch] = useState<string | null>(null);
  const [aiSearches, setAiSearches] = useState<AiSearch[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { hasAccess: hasAiAccess } = useFeatureAccess(FeatureId.AI_ANALYSIS);
  const [isAiSearchModalOpen, setIsAiSearchModalOpen] = useState(false);

  useEffect(() => {
    fetchSavedSearches();
    fetchAiProfile();
  }, [fetchSavedSearches, fetchAiProfile, currentProfileId]);

  useEffect(() => {
    if (aiProfile) {
      setAiSearches(buildAiSearches(aiProfile));
    }
  }, [aiProfile]);

  const handleSaveSearch = () => {
    if (searchName.length <= 3) {
      setError('O nome da busca deve ter mais de 3 caracteres');
      return;
    }
    if (editingSearch) {
      updateSavedSearch(editingSearch, searchName, currentSearchParams);
    } else {
      addSavedSearch(searchName, currentSearchParams);
    }
    setIsModalOpen(false);
    setSearchName('');
    setEditingSearch(null);
  };

  const handleDeleteSearch = (id: string) => {
    setDeletingSearch(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteSearch = () => {
    if (deletingSearch) {
      removeSavedSearch(deletingSearch);
    }
    setIsDeleteModalOpen(false);
    setDeletingSearch(null);
  };

  const handlePreferencesClick = () => {
    if (userPreferences) {
      onSearchSelect(getPreferencesAsSearch(userPreferences).params, 'preferences');
    } else {
      navigate('/empresa/preferencias?tour=1');
    }
  };

  const hasSearchParams = useMemo(
    () =>
      Object.keys(currentSearchParams).some(
        (key) =>
          currentSearchParams[key as keyof LicitacoesSearchParams] !== undefined &&
          currentSearchParams[key as keyof LicitacoesSearchParams] !== null &&
          currentSearchParams[key as keyof LicitacoesSearchParams] !== '' &&
          (!Array.isArray(currentSearchParams[key as keyof LicitacoesSearchParams]) ||
            (currentSearchParams[key as keyof LicitacoesSearchParams] as any).length > 0),
      ),
    [currentSearchParams],
  );

  const normalizeParams = useCallback((params: LicitacoesSearchParams) => {
    const normalized = { ...params };

    Object.entries(normalized).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        delete normalized[key as keyof LicitacoesSearchParams];
      } else if (value === '' || value === undefined || value === null) {
        delete normalized[key as keyof LicitacoesSearchParams];
      } else if (typeof value === 'boolean') {
        normalized[key as keyof LicitacoesSearchParams] = value as any;
      }
    });

    delete normalized.pagina;
    delete normalized.licitacoesPorPagina;
    delete normalized.sortBy;

    return normalized;
  }, []);

  const isSearchActive = useCallback(
    (searchParams: LicitacoesSearchParams) => {
      const normalizedCurrent = normalizeParams(currentSearchParams);
      const normalizedSearch = normalizeParams(searchParams);
      return isEqual(normalizedCurrent, normalizedSearch);
    },
    [currentSearchParams, normalizeParams],
  );

  const isPreferencesActive = useMemo(() => {
    if (!userPreferences) return false;
    const preferencesSearch = getPreferencesAsSearch(userPreferences);
    return isSearchActive(preferencesSearch.params);
  }, [userPreferences, isSearchActive]);

  const hasSearchChanged = useCallback(
    (activeSearch: SavedSearch | undefined, currentParams: LicitacoesSearchParams) => {
      if (!activeSearch) return false;

      const normalizedCurrent = normalizeParams(currentParams);
      const normalizedSaved = normalizeParams(activeSearch.params);

      return !isEqual(normalizedCurrent, normalizedSaved);
    },
    [normalizeParams],
  );

  const handleAiSearchGenerated = (response: any) => {
    const aiGeneratedSearch = buildAiGeneratedSearch(response);
    onSearchSelect(aiGeneratedSearch.params, aiGeneratedSearch.name);
  };

  return (
    <Box
      className="saved-searches"
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ minHeight: 'fit-content', mb: 2 }}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        gap={2}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          flexGrow={1}
          gap={1}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: 'text.secondary',
              minWidth: 'fit-content',
            }}
          >
            Buscas Inteligentes:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            <SearchChip
              key="preferences"
              type="preference"
              label="Minhas Preferências"
              tooltip="Buscas baseadas em suas preferências"
              active={isPreferencesActive}
              onSelect={handlePreferencesClick}
              hasPreferences={!!userPreferences}
            />
            {aiSearches?.map((search) => (
              <SearchChip
                key={`ai-${search.name}`}
                type="ai"
                label={search.name}
                tooltip={search.explanation}
                active={activeSearchId === search.name}
                onSelect={() => onSearchSelect(search.params, search.name)}
              />
            ))}
          </Box>
        </Box>

        <Box display="flex" gap={1} sx={{ flexShrink: 0 }}>
          <FeatureGate featureId={FeatureId.AI_ANALYSIS} message="Usar AI para criar busca">
            <Button
              className="ai-search-button"
              variant="contained"
              color="secondary"
              onClick={hasAiAccess ? () => setIsAiSearchModalOpen(true) : undefined}
              startIcon={<AutoAwesome fontSize="small" />}
              sx={{
                padding: '4px 8px',
                minWidth: isMobile ? 'unset' : '140px',
                height: '32px',
                fontSize: '0.8125rem',
              }}
            >
              {'Nova Busca IA'}
            </Button>
          </FeatureGate>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        justifyContent="space-between"
        gap={2}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          flexGrow={1}
          gap={1}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: 'text.secondary',
              minWidth: 'fit-content',
            }}
          >
            {!aiSearches?.length && !userPreferences ? 'Buscas:' : 'Buscas Salvas:'}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {!aiSearches?.length && !userPreferences && (
              <SearchChip
                key="preferences"
                type="preference"
                label="Minhas Preferências"
                tooltip="Buscas baseadas em suas preferências"
                active={isPreferencesActive}
                onSelect={handlePreferencesClick}
                hasPreferences={!!userPreferences}
              />
            )}
            {savedSearches?.length > 0 ? (
              savedSearches.map((search: SavedSearch) => (
                <SearchChip
                  key={`manual-${search.id}`}
                  type="manual"
                  label={search.name}
                  active={activeSearchId === search.id}
                  onSelect={() => onSearchSelect(search.params, search.id)}
                  onDelete={() => handleDeleteSearch(search.id)}
                />
              ))
            ) : (
              <Typography>Nenhuma busca salva</Typography>
            )}
          </Box>
        </Box>

        <Box display="flex" gap={1} sx={{ flexShrink: 0 }}>
          {activeSearchId && savedSearches?.find((s) => s.id === activeSearchId) && (
            <Button
              className="update-search-button"
              variant="outlined"
              onClick={() => {
                const activeSearch = savedSearches.find((s) => s.id === activeSearchId);
                if (activeSearch) {
                  setSearchName(activeSearch.name);
                  setEditingSearch(activeSearchId);
                  setIsModalOpen(true);
                }
              }}
              startIcon={<Edit fontSize="small" />}
              disabled={
                !hasSearchParams ||
                !hasSearchChanged(
                  savedSearches.find((s) => s.id === activeSearchId),
                  currentSearchParams,
                )
              }
              sx={{
                padding: '4px 8px',
                minWidth: 'unset',
                height: '32px',
                fontSize: '0.8125rem',
                opacity:
                  !hasSearchParams ||
                  !hasSearchChanged(
                    savedSearches.find((s) => s.id === activeSearchId),
                    currentSearchParams,
                  )
                    ? 0.5
                    : 1,
                pointerEvents:
                  !hasSearchParams ||
                  !hasSearchChanged(
                    savedSearches.find((s) => s.id === activeSearchId),
                    currentSearchParams,
                  )
                    ? 'none'
                    : 'auto',
              }}
            >
              {isMobile ? 'Atualizar' : 'Atualizar Esta Busca'}
            </Button>
          )}
          <Button
            className="save-search-button"
            variant="contained"
            onClick={() => {
              setSearchName('');
              setEditingSearch(null);
              setIsModalOpen(true);
            }}
            startIcon={<Save fontSize="small" />}
            disabled={!hasSearchParams}
            sx={{
              padding: '4px 8px',
              minWidth: 'unset',
              height: '32px',
              fontSize: '0.8125rem',
              opacity: !hasSearchParams ? 0.5 : 1,
              pointerEvents: !hasSearchParams ? 'none' : 'auto',
            }}
          >
            {'Salvar Busca Atual'}
          </Button>
        </Box>
      </Box>

      {/* Existing Dialogs */}
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>{editingSearch ? 'Editando Busca Salva' : 'Criando Nova Busca'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            error={!!error}
            helperText={error}
            label="Nome da Busca"
            type="text"
            fullWidth
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <DialogContentText
            sx={{ mt: 2, color: editingSearch ? 'warning.main' : 'text.secondary' }}
          >
            {editingSearch
              ? 'Esta ação irá atualizar a busca salva com os parâmetros de busca atuais.'
              : 'Uma nova busca será criada com os parâmetros de busca atuais.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleSaveSearch}>{editingSearch ? 'Atualizar' : 'Salvar'}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir esta busca salva?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteModalOpen(false)}>Cancelar</Button>
          <Button onClick={confirmDeleteSearch} color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <AiSearchModal
        open={isAiSearchModalOpen}
        onClose={() => setIsAiSearchModalOpen(false)}
        onSearchGenerated={handleAiSearchGenerated}
        context="Esta busca é para criar um filtro de pesquisa de licitações. Adicione palavras-chave, e outros parâmetros relevantes para encontrar licitações específicas. Adicione estados e cidades caso o usuário especifique"
        title="Nova Busca com AI"
        description="Descreva o tipo de licitação que você está procurando. A AI irá gerar uma busca personalizada com os melhores filtros para você."
        placeholder="Ex: Busco licitações de material de escritório, principalmente papel A4 e cartuchos de impressora, em São Paulo e região metropolitana"
      />
    </Box>
  );
};

export default SavedSearches;
