import { features, plans } from '@constants/plans';
import CNAES from '@data/CNAE.json';
import Api from '@services/api';
import {
  storageManager,
  useAnnotationsStore,
  useBulletinStore,
  useDocumentsStore,
  useFavoritesStore,
  useFollowingStore,
  useKanbanStore,
  useLicitacoesStore,
  useNotificationStore,
  usePaymentStore,
  useRecommendedStore,
  useSavedSearchesStore,
  useTagsStore,
} from '@store';
import {
  CNAE,
  Company,
  FeatureAccessResult,
  FeatureId,
  FirestoreUser,
  PlanId,
  ProfilesInfo,
  UserPreferences,
  UserProfile,
} from '@types';
import { isEqual, isUserActive } from '@utils';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const cnaes: CNAE[] = CNAES;

interface ProfileState {
  userData: FirestoreUser | null;
  company: Company | null;
  error: string | null;
  userPreferences: UserPreferences | null;
  fetchCompany: (
    cnpj: string,
  ) => Promise<Company | { error: string; status?: number; details?: string }>;
  setCompany: (company: Company) => Promise<void>;
  setUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUserCompany: (companyInfo: Company) => Promise<void>;
  saveUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUser: (user: FirestoreUser) => Promise<void>;
  loadUserData: (profileId?: string) => Promise<FirestoreUser | null>;
  isLoading: boolean;
  resetStore: () => void;
  setOnboardingPreferences: (preferences: Partial<UserPreferences> | null) => void;
  setOnboardingComplete: () => Promise<void>;
  checkUserPlanHistory: () => Promise<void>;
  profiles: UserProfile[];
  currentProfileId: string | null;
  getCurrentProfileId: () => string | null;
  setCurrentProfile: (profileId: string, initialLoad?: boolean) => void;
  addProfile: (profile: UserProfile) => void;
  updateProfile: (profileId: string, updates: Partial<UserProfile>) => void;
  deleteProfile: (profileId: string) => void;
  setCurrentProfileId: (profileId: string | null) => void;
  checkFeatureAccess: (featureId: FeatureId, count?: number) => FeatureAccessResult;
  profilesInfo: ProfilesInfo | null;
  uploadCompanyLogo: (file: File) => Promise<string>;
  removeCompanyLogo: () => Promise<void>;
  uploadUserProfileImage: (file: File) => Promise<string>;
  removeUserProfileImage: () => Promise<void>;
  isInitialized: boolean;
  updateTaxInfo: (taxId: string, taxName: string, address?: any) => Promise<void>;
}

export const useProfileStore = create<ProfileState>()(
  persist(
    (set, get) => {
      const initialProfileId = storageManager.get('currentProfileId');

      return {
        company: null,
        userData: null,
        userPreferences: null,
        isLoading: false,
        cnaes: CNAES,
        profiles: [],
        error: null,
        currentProfileId: initialProfileId || null,
        profilesInfo: null,
        isInitialized: false,
        getCurrentProfileId: () => {
          return storageManager.get('currentProfileId') || null;
        },
        setCurrentProfileId: (profileId: string | null) => {
          storageManager.set('currentProfileId', profileId);
          set({ currentProfileId: profileId });
        },
        setCompany: async (newCompany: Company) => {
          const currentCompany = get().company;
          if (!isEqual(currentCompany, newCompany)) {
            set({ company: newCompany });
            await get().saveUserCompany(newCompany);
          }
        },
        setUserPreferences: async (newPreferences: UserPreferences) => {
          const currentPreferences = get().userPreferences;
          if (!isEqual(currentPreferences, newPreferences)) {
            set({ userPreferences: newPreferences });
            await get().saveUserPreferences(newPreferences);
          }
        },
        fetchCompany: async (cnpj: string) => {
          try {
            const response = await Api.getCompany(cnpj);
            if ('error' in response) {
              return response;
            }
            return response;
          } catch (error) {
            console.error('Error fetching company:', error);
            if (error instanceof Error) {
              return {
                error: error.message || 'Ocorreu um erro ao buscar a empresa.',
                status: 500,
              };
            } else {
              return {
                error: 'Ocorreu um erro ao buscar a empresa.',
                status: 500,
              };
            }
          }
        },
        saveUserCompany: async (companyInfo: Company) => {
          const previousCompany = get().company;
          set({ isLoading: true, error: null });
          try {
            const isOnboarding = !get().company;
            await Api.saveUserCompany(companyInfo, isOnboarding);
            set({ company: companyInfo, isLoading: false });
          } catch (error: any) {
            console.error('Error saving company:', error);
            const errorMessage = error.message || 'Erro ao salvar empresa';
            set({ company: previousCompany, error: errorMessage, isLoading: false });
            throw error;
          }
        },
        saveUserPreferences: async (preferences: UserPreferences) => {
          const previousPreferences = get().userPreferences;
          set({ isLoading: true, error: null });
          try {
            const isOnboarding = !get().userPreferences;
            await Api.saveUserPreferences(preferences, isOnboarding);
            set({ userPreferences: preferences, isLoading: false });
          } catch (error: any) {
            console.error('Error saving preferences:', error);
            const errorMessage = error.message || 'Erro ao salvar preferências';
            set({ userPreferences: previousPreferences, error: errorMessage, isLoading: false });
            throw error;
          }
        },
        saveUser: async (user: FirestoreUser) => {
          await Api.saveUserData(user);
          set((state) => ({
            userData: { ...state.userData, ...user },
          }));
        },
        resetStore: () =>
          set({
            company: null,
            userPreferences: null,
            isLoading: false,
            userData: null,
            profiles: [],
            currentProfileId: null,
            profilesInfo: null,
            error: null,
          }),
        loadUserData: async (profileId?: string) => {
          set({ isLoading: true, error: null });
          try {
            const storedProfileId = get().getCurrentProfileId();
            const userData = await Api.getUserData(profileId || storedProfileId || '');

            if (userData) {
              await set(() => ({
                userData,
                profiles: userData.profiles || [],
                profilesInfo: userData.profilesInfo || null,
                currentProfileId:
                  profileId ||
                  storedProfileId ||
                  (userData.profiles && userData.profiles[0]?.id) ||
                  null,
                isInitialized: true,
              }));

              const currentProfileId =
                profileId || storedProfileId || (userData.profiles && userData.profiles[0]?.id);
              if (currentProfileId) {
                const currentProfile = userData.profiles?.find((p) => p.id === currentProfileId);
                if (currentProfile) {
                  await set({
                    company: currentProfile.companyInfo || null,
                    userPreferences: currentProfile.userPreferences || null,
                  });
                  await get().setCurrentProfileId(currentProfileId);
                }
              }
              return userData;
            }
            return null;
          } catch (error: any) {
            console.error('Error loading user data:', error);
            const errorMessage = error.message || 'Erro ao carregar dados do usuário';
            set({ error: errorMessage, isLoading: false });
            throw error;
          } finally {
            set({ isLoading: false });
          }
        },
        setOnboardingPreferences: (preferences: Partial<UserPreferences> | null) =>
          set({ userPreferences: preferences as UserPreferences }),
        setOnboardingComplete: async () => {
          try {
            await Api.setOnboardingComplete();
            set((state) => ({
              userData: state.userData ? { ...state.userData, onboardingComplete: true } : null,
            }));
          } catch (error) {
            console.error('Failed to set onboarding complete:', error);
          }
        },
        checkUserPlanHistory: async () => {
          const userData = get().userData;
          if (userData && userData.hasHadActivePlan === undefined) {
            try {
              const hasHadActivePlan = await Api.checkUserPlanHistory();
              set((state) => ({
                userData: state.userData ? { ...state.userData, hasHadActivePlan } : null,
              }));
            } catch (error) {
              console.error('Error checking user plan history:', error);
            }
          }
        },
        setCurrentProfile: async (profileId: string, initialLoad = false) => {
          const currentProfileId = get().currentProfileId;
          const userData = get().userData;
          if (currentProfileId !== profileId || initialLoad) {
            const previousState = {
              currentProfileId: get().getCurrentProfileId(),
              company: get().company,
              userPreferences: get().userPreferences,
            };
            set({ isLoading: true, error: null });
            try {
              get().setCurrentProfileId(profileId);

              const { profiles } = get();
              const currentProfile = profiles.find((p) => p.id === profileId);
              if (!currentProfile) {
                throw new Error('Perfil não encontrado');
              }

              if (!initialLoad) {
                useFavoritesStore.getState().resetStore();
                useFollowingStore.getState().resetStore();
                useKanbanStore.getState().resetStore();
                useBulletinStore.getState().resetStore();
                useSavedSearchesStore.getState().resetStore();
                useRecommendedStore.getState().resetStore();
                useDocumentsStore.getState().resetStore();
                useNotificationStore.getState().resetStore();
                useLicitacoesStore.getState().resetStore();
                useTagsStore.getState().resetStore();
                useAnnotationsStore.getState().resetStore();
                usePaymentStore.getState().resetStore();
              }
              set({
                company: currentProfile.companyInfo || null,
                userPreferences: currentProfile.userPreferences || null,
              });
              if (isUserActive(userData)) {
                await Promise.all([
                  useFavoritesStore
                    .getState()
                    .fetchFavorites(true)
                    .catch((error) => {
                      console.error('Error fetching favorites:', error);
                    }),
                  useFollowingStore
                    .getState()
                    .fetchFollowing(true)
                    .catch((error) => {
                      console.error('Error fetching following:', error);
                    }),
                  useRecommendedStore
                    .getState()
                    .fetchAiProfile(true)
                    .catch((error) => {
                      console.error('Error fetching AI profile:', error);
                    }),
                  useSavedSearchesStore
                    .getState()
                    .fetchSavedSearches(true)
                    .catch((error) => {
                      console.error('Error fetching saved searches:', error);
                    }),
                  useNotificationStore
                    .getState()
                    .fetchNotifications()
                    .catch((error) => {
                      console.error('Error fetching notifications:', error);
                    }),
                  useDocumentsStore
                    .getState()
                    .fetchDocuments(undefined, true)
                    .catch((error) => {
                      console.error('Error fetching documents:', error);
                    }),
                  useTagsStore
                    .getState()
                    .fetchTags(true)
                    .catch((error) => {
                      console.error('Error fetching tags:', error);
                    }),
                  useAnnotationsStore
                    .getState()
                    .fetchAnnotations(true)
                    .catch((error) => {
                      console.error('Error fetching annotations:', error);
                    }),
                  usePaymentStore
                    .getState()
                    .fetchPaymentInfo()
                    .catch((error) => {
                      console.error('Error fetching payment info:', error);
                    }),
                ]);
              } else {
                usePaymentStore
                  .getState()
                  .fetchPaymentInfo()
                  .catch((error) => {
                    console.error('Error fetching payment info:', error);
                  });
              }
            } catch (error: any) {
              console.error('Error setting current profile:', error);
              const errorMessage = error.message || 'Erro ao definir perfil atual';
              set({ ...previousState, error: errorMessage });
              throw error;
            } finally {
              set({ isLoading: false });
            }
          }
        },
        addProfile: async (profile: Partial<UserProfile>) => {
          const { profilesInfo } = get();
          if (profilesInfo && !profilesInfo.canCreateMore) {
            throw new Error(
              'Limite de perfis atingido. Faça upgrade do seu plano para criar mais perfis.',
            );
          }

          set({ isLoading: true });
          try {
            const newProfile = await Api.createProfile(profile);
            set((state) => ({
              profiles: [...state.profiles, newProfile],
              currentProfileId: newProfile.id,
            }));
            get().setCurrentProfile(newProfile.id);
          } catch (error) {
            console.error('Error adding new profile:', error);
            throw error;
          } finally {
            set({ isLoading: false });
          }
        },
        updateProfile: async (profileId: string, updates: Partial<UserProfile>) => {
          set({ isLoading: true });
          try {
            const success = await Api.updateProfile(profileId, updates);
            if (success) {
              set((state) => ({
                profiles: state.profiles.map((p) =>
                  p.id === profileId ? { ...p, ...updates } : p,
                ),
              }));
            }
          } catch (error) {
            console.error('Error updating profile:', error);
          } finally {
            set({ isLoading: false });
          }
        },
        deleteProfile: async (profileId: string) => {
          set({ isLoading: true });
          try {
            const success = await Api.deleteProfile(profileId);
            if (success) {
              set((state) => ({
                profiles: state.profiles.filter((p) => p.id !== profileId),
                currentProfileId:
                  state.currentProfileId === profileId ? null : state.currentProfileId,
              }));
            }
          } catch (error) {
            console.error('Error deleting profile:', error);
          } finally {
            set({ isLoading: false });
          }
        },
        checkFeatureAccess: (featureId: FeatureId, count?: number): FeatureAccessResult => {
          const state = get();
          const userData = state.userData;
          if (userData?.role === 'admin') {
            return { hasAccess: true, reason: 'Você é um administrador' };
          }

          const activePlans = ['active', 'trialing'];
          const currentPlan = activePlans.includes(userData?.subscriptionStatus || '')
            ? userData?.planId
              ? userData.planId
              : PlanId.BUSCA
            : null;

          if (!currentPlan) {
            return {
              hasAccess: false,
              reason: 'Você precisa ter um plano ativo para acessar este recurso',
            };
          }

          const feature = features[featureId];
          if (!feature) {
            return {
              hasAccess: false,
              reason: 'Recurso não encontrado',
            };
          }

          if (!feature.availableIn.includes(currentPlan as PlanId)) {
            const planNames = feature.availableIn
              .map((planId) => plans.find((p) => p.id === planId)?.name)
              .join(', ');
            return {
              hasAccess: false,
              reason: `Disponível apenas nos planos: ${planNames}`,
            };
          }

          if (feature.maxUsage && count !== undefined) {
            const limit = feature.maxUsage[currentPlan as PlanId];
            const usage = count;

            if (limit !== undefined && usage >= limit) {
              return {
                hasAccess: false,
                reason: `Limite de ${limit} ${feature.name} atingido no seu plano`,
                limit,
                usage,
              };
            }

            return {
              hasAccess: true,
              limit,
              usage,
            };
          }

          return { hasAccess: true };
        },
        uploadCompanyLogo: async (file: File) => {
          try {
            const formData = new FormData();
            formData.append('logo', file);

            const response = await Api.uploadCompanyLogo(formData);
            return response.url;
          } catch (error) {
            console.error('Error uploading company logo:', error);
            throw new Error('Falha ao enviar o logo da empresa');
          }
        },
        removeCompanyLogo: async () => {
          try {
            await Api.removeCompanyLogo();
            await get().setCompany({
              ...get().company!,
              logoUrl: undefined,
            });
          } catch (error) {
            console.error('Error removing company logo:', error);
            throw new Error('Falha ao remover o logo da empresa');
          }
        },
        uploadUserProfileImage: async (file: File) => {
          try {
            const formData = new FormData();
            formData.append('file', file);
            const { url } = await Api.uploadUserProfileImage(formData);
            await get().saveUser({
              ...get().userData!,
              photoURL: url,
            });
            return url;
          } catch (error) {
            console.error('Error uploading user profile image:', error);
            throw new Error('Falha ao fazer upload da imagem do perfil');
          }
        },
        removeUserProfileImage: async () => {
          try {
            await Api.removeUserProfileImage();
            await get().saveUser({
              ...get().userData!,
              photoURL: null,
            });
          } catch (error) {
            console.error('Error removing user profile image:', error);
            throw new Error('Falha ao remover a imagem do perfil');
          }
        },
        updateTaxInfo: async (taxId: string, taxName: string, address?: any) => {
          const previousUser = get().userData;
          set({ isLoading: true, error: null });
          try {
            const updatedUser = await Api.updateTaxInfo(taxId, taxName, address);
            set((state) => ({
              userData: {
                ...(state.userData as FirestoreUser),
                taxId: updatedUser.taxId as string,
                taxName: updatedUser.taxName as string,
                address: updatedUser.address || null,
              },
              isLoading: false,
            }));
          } catch (error: any) {
            console.error('Error updating tax info:', error);
            const errorMessage = error.message || 'Erro ao atualizar dados fiscais';
            set({
              userData: previousUser,
              error: errorMessage,
              isLoading: false,
            });
            throw error;
          }
        },
      };
    },
    {
      name: 'profile-storage',
      partialize: (state) => ({
        ...state,
        profiles: state.profiles,
        currentProfileId: state.currentProfileId,
      }),
    },
  ),
);
