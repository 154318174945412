import { Alert, AlertColor, Button, Snackbar } from '@mui/material';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface NotificationAction {
  label: string;
  onClick: () => void;
}

interface NotificationOptions {
  autoHideDuration?: number;
  action?: NotificationAction;
}

interface NotificationContextData {
  showNotification: (message: string, severity?: AlertColor, options?: NotificationOptions) => void;
  hideNotification: () => void;
}

interface NotificationState {
  message: string;
  severity: AlertColor;
  open: boolean;
  duration: number;
  action?: NotificationAction;
}

const DEFAULT_DURATION = 4000;

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<NotificationState>({
    message: '',
    severity: 'info',
    open: false,
    duration: DEFAULT_DURATION,
  });

  const hideNotification = useCallback(() => {
    setState((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  const showNotification = useCallback(
    (message: string, severity: AlertColor = 'info', options?: NotificationOptions) => {
      setState({
        message,
        severity,
        open: true,
        duration: options?.autoHideDuration || DEFAULT_DURATION,
        action: options?.action,
      });
    },
    [],
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (state.open) {
      timeoutId = setTimeout(() => {
        hideNotification();
      }, state.duration);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [state.open, state.duration, hideNotification]);

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={state.duration}
        onClose={hideNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ zIndex: 10000 }}
      >
        <Alert
          onClose={hideNotification}
          severity={state.severity}
          sx={{ width: '100%' }}
          variant="filled"
          action={
            state.action && (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  state.action?.onClick();
                  hideNotification();
                }}
              >
                {state.action.label}
              </Button>
            )
          }
        >
          {state.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
