import Api from '@services/api';
import { AiSearchResponse, Licitacao } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Message {
  id: string;
  content: string;
  isUser: boolean;
}

interface DocumentRequirement {
  name: string;
  required: boolean;
  description?: string;
}

interface DocumentsRequired {
  [key: string]: boolean | DocumentRequirement;
}

interface AIChatState {
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  documentsRequired: DocumentsRequired | null;
  seguroGarantia: boolean | null;
  isExtracting: boolean;
  isGeneratingSearch: boolean;
  sendMessage: (licitacaoId: string, content: string) => Promise<void>;
  clearMessages: () => void;
  resetStore: () => void;
  setError: (error: string | null) => void;
  extractDocuments: (licitacao: Licitacao) => Promise<void>;
  generateSearch: (searchQuery: string, context?: string) => Promise<AiSearchResponse>;
}

export const useAIStore = create<AIChatState>()(
  persist(
    (set) => ({
      messages: [],
      isLoading: false,
      error: null,
      documentsRequired: null,
      seguroGarantia: null,
      isExtracting: false,
      isGeneratingSearch: false,

      sendMessage: async (licitacaoId: string, content: string) => {
        if (!licitacaoId) {
          const error = 'Nenhuma licitação selecionada';
          set({ error });
          throw new Error(error);
        }

        const newUserMessage: Message = {
          id: Date.now().toString(),
          content,
          isUser: true,
        };

        set((state) => ({
          messages: [...state.messages, newUserMessage],
          isLoading: true,
          error: null,
        }));

        try {
          const response = await Api.askAIQuestion(licitacaoId, content);
          const newAIMessage: Message = {
            id: (Date.now() + 1).toString(),
            content: response,
            isUser: false,
          };

          set((state) => ({
            messages: [...state.messages, newAIMessage],
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Erro ao enviar mensagem:', error);
          const errorMessage =
            error.message || 'Falha ao processar sua pergunta. Por favor, tente novamente.';
          set({ error: errorMessage, isLoading: false });
          throw error;
        }
      },

      clearMessages: () => {
        set({ messages: [], error: null });
      },

      extractDocuments: async (licitacao: Licitacao) => {
        if (!licitacao) {
          const error = 'Nenhuma licitação selecionada';
          set({ error });
          throw new Error(error);
        }

        // Check if we already have the documents extracted
        if (
          licitacao.documentsRequired &&
          licitacao.isEditalExtracted &&
          !licitacao.isEditalExtractionFailed
        ) {
          set({
            documentsRequired: licitacao.documentsRequired,
            seguroGarantia: licitacao.seguroGarantia || false,
            isExtracting: false,
          });
          return;
        }

        set({ isExtracting: true, error: null });

        try {
          const result = await Api.extractLicitacaoDocuments(licitacao.id);
          set({
            documentsRequired: result.documentsRequired,
            seguroGarantia: result.seguroGarantia,
            isExtracting: false,
          });
        } catch (error: any) {
          console.error('Erro ao extrair documentos:', error);
          const errorMessage =
            error.message || 'Falha ao extrair documentos. Por favor, tente novamente.';
          set({ error: errorMessage, isExtracting: false });
          throw error;
        }
      },

      resetStore: () => {
        set({
          messages: [],
          isLoading: false,
          error: null,
          documentsRequired: null,
          seguroGarantia: null,
          isExtracting: false,
        });
      },

      setError: (error: string | null) => {
        set({ error });
      },

      generateSearch: async (searchQuery: string, context?: string) => {
        if (!searchQuery) {
          const error = 'Consulta de busca vazia';
          set({ error });
          throw new Error(error);
        }

        set({ isGeneratingSearch: true, error: null });

        try {
          const response: AiSearchResponse = await Api.generateAiSearch(searchQuery, context);
          set({ isGeneratingSearch: false });
          return response;
        } catch (error: any) {
          console.error('Erro ao gerar busca:', error);
          const errorMessage = error.message || 'Falha ao gerar busca. Por favor, tente novamente.';
          set({ error: errorMessage, isGeneratingSearch: false });
          throw error;
        }
      },
    }),
    {
      name: 'ai-chat-storage',
      partialize: (state) => ({
        messages: state.messages,
        documentsRequired: state.documentsRequired,
        seguroGarantia: state.seguroGarantia,
      }),
    },
  ),
);
