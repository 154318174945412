import { useAnalytics } from '@hooks/useAnalytics';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Typography,
  styled,
} from '@mui/material';
import api from '@services/api';
import React, { useState } from 'react';

const SATISFACTION_LEVELS = [
  { emoji: '😠', label: 'INSATISFEITO' },
  { emoji: '😕', label: 'CONFUSO' },
  { emoji: '😐', label: 'INDIFERENTE' },
  { emoji: '😊', label: 'SATISFEITO' },
  { emoji: '😍', label: 'FELIZ' },
];

const StyledEmoji = styled(Box)(({ selected }: { selected?: boolean }) => ({
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '50%',
  transition: 'transform 0.2s',
  transform: selected ? 'scale(1.2)' : 'scale(1)',
  '&:hover': {
    transform: 'scale(1.2)',
  },
}));

interface AIChatFeedbackProps {
  open: boolean;
  onClose: () => void;
}

const AIChatFeedback: React.FC<AIChatFeedbackProps> = ({ open, onClose }) => {
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { trackEvent } = useAnalytics();

  const handleClose = () => {
    onClose();
    setSelectedLevel(null);
    setFeedback('');
  };

  const handleSubmit = async () => {
    if (selectedLevel === null) return;

    setIsSubmitting(true);
    try {
      await api.sendFeedback(
        `[LicitAI Feedback]\nNível de satisfação: ${SATISFACTION_LEVELS[selectedLevel].label}\n\nFeedback: ${feedback}`,
      );
      trackEvent('ai_feedback', {
        action: 'submit',
        satisfaction_level: SATISFACTION_LEVELS[selectedLevel].label,
        has_text_feedback: Boolean(feedback.trim()),
        feedback_length: feedback.length,
      });
      handleClose();
    } catch (error) {
      trackEvent('ai_feedback_error', {
        error_message: error instanceof Error ? error.message : String(error),
        satisfaction_level: SATISFACTION_LEVELS[selectedLevel].label,
      });
      console.error('Error sending feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Ajude-nos a melhorar a LicitAI</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" gutterBottom>
          Qual o seu nível de satisfação com a LicitAI?
        </Typography>
        <Box display="flex" justifyContent="space-around" mb={3}>
          {SATISFACTION_LEVELS.map((level, index) => (
            <Box key={index} textAlign="center">
              <StyledEmoji
                selected={selectedLevel === index}
                onClick={() => setSelectedLevel(index)}
              >
                <Typography fontSize="2rem">{level.emoji}</Typography>
              </StyledEmoji>
              <Typography variant="caption" display="block">
                {level.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography variant="subtitle1" gutterBottom>
          Como está sendo utilizar a LicitAI
        </Typography>
        <TextareaAutosize
          minRows={4}
          placeholder="Escreva sua experiência aqui..."
          style={{
            width: '100%',
            padding: '8px',
            marginTop: '8px',
            borderRadius: '4px',
          }}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={selectedLevel === null || isSubmitting}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AIChatFeedback;
