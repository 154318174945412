import { features } from '@constants/plans';
import { useFeature } from '@contexts/FeatureContext';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import { Box } from '@mui/material';
import { FeatureId } from '@types';
import React from 'react';

interface FeatureGateProps {
  featureId: FeatureId;
  children: React.ReactNode;
  message?: string;
  loadingFallback?: React.ReactNode;
}

const FeatureGate: React.FC<FeatureGateProps> = ({
  featureId,
  children,
  message,
  loadingFallback = null,
}) => {
  const { hasAccess, reason } = useFeatureAccess(featureId);
  const { openUpgradeModal, trackUpgradeClick } = useFeature();
  const featureName = features[featureId]?.name || 'recurso';

  if (reason === 'Carregando...') {
    return <>{loadingFallback}</>;
  }

  if (hasAccess) {
    return <>{children}</>;
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    trackUpgradeClick(featureId);
    openUpgradeModal(featureId);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'inline-block',
        cursor: 'not-allowed',
        opacity: 0.7,
        position: 'relative',
        '& > *': {
          pointerEvents: 'none',
        },
      }}
      title={message || `Faça upgrade para acessar ${featureName}`}
    >
      {children}
    </Box>
  );
};

export default FeatureGate;
