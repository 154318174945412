import { ComprasRJLicitacao, Licitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class ComprasrjPortalService extends BasePortalService {
  name = 'Compras RJ';
  logo = '/images/portals/comprasrj.png';
  portal = Portal.COMPRASRJ;
  portalData: ComprasRJLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as ComprasRJLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.getPortalUrl();
  }

  getFollowUpUrl(): string | null {
    return this.getPortalUrl();
  }

  getEsclarecimentosUrl(): string | null {
    return this.getPortalUrl();
  }

  getImpugnacaoUrl(): string | null {
    return this.getPortalUrl();
  }

  getLoginUrl(): string | null {
    return 'https://www.compras.rj.gov.br/Portal-Siga/';
  }

  getRegisterUrl(): string | null {
    return 'https://www.compras.rj.gov.br/Portal-Siga/PreFornecedor/primeiraEtapa.action';
  }
}
