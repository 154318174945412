import { FirebaseError } from '@firebase/app';
import { User } from '@firebase/auth';
import { ApiService } from '@services/api';
import { auth } from '@services/firebase';
import { useAuthStore } from '@store/authStore';
import { useProfileStore } from '@store/profileStore';
import { storageManager } from '@store/storageStore';
import { shouldRedirectToWebsite } from '@utils/authChecks';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

export interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  isInitialized: boolean;
  error: Error | FirebaseError | null;
  signIn: (email: string, password: string, remember?: boolean) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signInWithGoogle: (remember?: boolean) => Promise<string>;
  signInWithToken: (token: string, remember?: boolean) => Promise<void>;
  signOut: () => Promise<void>;
  clearError: () => void;
  getIdToken: (forceRefresh?: boolean) => Promise<string | null>;
  getUser: () => User | null;
  resetPassword: (email: string) => Promise<void>;
  resetPasswordError: string | null;
  getAuthToken: () => string | null;
  setAuthToken: (token: string | null, remember?: boolean) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    user,
    isLoading,
    error,
    signIn,
    signUp,
    logout,
    clearError,
    setUser,
    getUser,
    signInWithGoogle,
    signInWithToken,
    resetPassword,
    resetPasswordError,
    getAuthToken,
    setAuthToken,
  } = useAuthStore();
  const {
    loadUserData,
    setCurrentProfile,
    isInitialized: isProfileStoreInitialized,
  } = useProfileStore();
  const getIdToken = useCallback(
    async (forceRefresh: boolean = false) => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const token = await currentUser.getIdToken(forceRefresh);
          setAuthToken(token, true);
          return token;
        } else {
          console.error('No authenticated user when attempting to get ID token');
          return null;
        }
      } catch (error) {
        console.error('Error getting ID token:', error);
        await logout();
        return null;
      }
    },
    [logout],
  );

  const setUserInactive = useCallback(() => {
    if (!window.location.pathname.startsWith('/configuracoes/pagamento')) {
      window.location.href = '/configuracoes/pagamento';
    }
  }, []);

  const setUserNotFound = useCallback(async () => {
    await logout();
    window.location.href = '/login';
  }, [logout]);

  const redirectToLicitouWebsite = useCallback(() => {
    window.location.href = 'https://licitou.com.br';
  }, []);

  const [isInitialized, setIsInitialized] = useState(() => {
    const hasAuthToken = !!storageManager.get('authToken');
    return !hasAuthToken;
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      setIsInitialized(false);

      try {
        if (firebaseUser) {
          setUser(firebaseUser);
          const token = await getIdToken(true);

          if (token) {
            const userData = await loadUserData();

            if (userData && shouldRedirectToWebsite(userData)) {
              redirectToLicitouWebsite();
              return;
            }

            if (userData && userData.profiles && userData.profiles.length > 0) {
              const storedProfileId = storageManager.get('currentProfileId');
              let profileIdToSet = storedProfileId;

              if (!profileIdToSet || !userData.profiles.some((p) => p.id === profileIdToSet)) {
                profileIdToSet = userData.profiles[0].id;
              }

              await setCurrentProfile(profileIdToSet as string, true);
            }
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error during auth initialization:', error);
        setUser(null);
      } finally {
        setIsInitialized(true);
      }
    });
    const tokenChangedUnsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token, true);
      } else {
        setAuthToken(null);
      }
    });
    ApiService.setAuthFunctions({
      getIdToken: getIdToken,
      signOut: logout,
      setUserInactive: setUserInactive,
      setUserNotFound: setUserNotFound,
    });

    return () => {
      unsubscribe();
      tokenChangedUnsubscribe();
      ApiService.setAuthFunctions(null);
    };
  }, [
    setUser,
    loadUserData,
    setCurrentProfile,
    getIdToken,
    setUserInactive,
    redirectToLicitouWebsite,
  ]);

  const value: AuthContextType = {
    user,
    isLoading,
    isInitialized: user ? isInitialized && isProfileStoreInitialized : isInitialized,
    error,
    signIn,
    signUp,
    signInWithGoogle,
    signOut: logout,
    signInWithToken,
    clearError,
    getIdToken,
    getUser,
    resetPassword,
    resetPasswordError,
    getAuthToken,
    setAuthToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
