import {
  Company,
  FollowingItem,
  Licitacao,
  ProposalTemplateData,
  ProposalTemplateItem,
} from '@types';
import { formatCurrency } from '@utils';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const lineHeight = '1.1';
const fontSize = '10pt';
const borderRadius = '8px';
const borderColor = '#ddd';
const sectionPadding = '3mm';
const sectionMargin = '1mm';
const labelColor = '#444';
const tableBorder = '#ddd';
const primaryColor = '#1976d2';
const fontColor = '#666';

export const generateProposalData = (
  company: Company,
  licitacao: Licitacao,
  items: FollowingItem[],
  totalValue: string,
  options: ProposalTemplateData['options'],
  showMarcaFabricante: boolean,
): ProposalTemplateData => {
  const templateItems: ProposalTemplateItem[] = items.map((item) => ({
    ...item,
    valorUnitarioFormatted: formatCurrency(item.customPrice || item.valorUnitarioEstimado),
    valorTotalFormatted: formatCurrency(
      (item.customPrice || item.valorUnitarioEstimado) * item.quantidade,
    ),
    marca: item.customMarca || '',
    fabricante: item.customFabricante || '',
  }));

  return {
    company: {
      ...company,
      contato: {
        ...company.contato,
        telefoneFormatted: `(${company.contato?.ddd || ''}) ${company.contato?.telefone || ''}`,
      },
    },
    licitacao: {
      ...licitacao,
      customOrgao: options.showCustomOrgao ? options.customOrgao : undefined,
      customNumeroEdital: options.showCustomNumeroEdital ? options.customNumeroEdital : undefined,
      customObjeto: options.showCustomObjeto ? options.customObjeto : undefined,
    },
    items: templateItems,
    totalValue,
    date: format(new Date(), "d 'de' MMMM 'de' yyyy", { locale: ptBR }),
    options,
    showMarcaFabricante,
  };
};

export const getProposalTemplate = (theme: 'light' | 'dark', showMarcaFabricante: boolean) => `
  <div style="max-width: 100%; font-family: Roboto, 'Segoe UI', Arial, sans-serif; font-size: ${fontSize}; line-height: ${lineHeight}; margin: 0;">
    <div class="header-info" style="padding: 0 ${sectionPadding} 1mm ${sectionPadding}; margin-bottom: ${sectionMargin}; display: flex; justify-content: space-between; align-items: flex-start;">
      <div style="flex: 1; max-width: 70%;">
        <h2 style="margin: 0 0 2mm 0; font-size: 20pt; color: ${primaryColor}; font-weight: 500;">Proposta comercial</h2>
        <p style="margin: 0; font-size: 14pt; color: ${primaryColor}; font-weight: 500;">{{licitacao.titulo}}</p>
      </div>
      {{#if company.logoUrl}}
        <div style="flex: 0 0 auto; margin-left: 2mm;">
          <img src="{{company.logoUrl}}" style="max-width: 150px; max-height: 60px; object-fit: contain;" class="company-logo" />
        </div>
      {{/if}}
    </div>
    <div class="licitacao-info" style="padding: 0 ${sectionPadding} 2mm ${sectionPadding}; margin-bottom: 2mm;">
      <p style="margin: 0 0 1mm 0; font-size: 12pt; color: ${fontColor}; font-weight: 500;">À {{#if licitacao.customOrgao}}{{licitacao.customOrgao}}{{else}}{{licitacao.orgao}}{{/if}}</p>
      {{#if licitacao.customNumeroEdital}}<p style="margin: 0 0 1mm 0; font-size: 12pt; color: ${fontColor}; font-weight: 500;">Processo Licitatório Nº {{licitacao.customNumeroEdital}}</p>{{/if}}
      {{#if licitacao.customObjeto}}
      <p style="margin: 0; font-size: 11pt; color: ${fontColor};"><b>Objeto:</b> {{licitacao.customObjeto}}</p>
      {{/if}}
    </div>
    <div class="company-info" style="border: 1px solid ${borderColor}; border-radius: ${borderRadius}; padding: ${sectionPadding}; margin-bottom: ${sectionMargin};">
      <h3 style="margin: 0 0 1mm 0; font-size: 12pt;">Dados da Empresa</h3>
      
      <div style="display: grid; grid-template-columns: 70% 30%; gap: 0.5mm;">
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Razão Social:</strong> {{company.razaoSocial}}</p>
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">CNPJ:</strong> {{company.cnpj}}</p>
      </div>
      
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1mm;">
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Endereço:</strong> {{company.endereco.logradouro}}, {{company.endereco.numero}}{{#if company.endereco.complemento}}, {{company.endereco.complemento}}{{/if}}</p>
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Cidade/UF:</strong> {{company.endereco.municipio}}/{{company.endereco.uf}}</p>
      </div>
      
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1mm;">
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Contato:</strong> {{company.contato.telefoneFormatted}}</p>
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Email:</strong> {{company.contato.email}}</p>
      </div>
      
      {{#if company.dadosBancarios}}
      <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Dados Bancários:</strong> Banco {{company.dadosBancarios.banco}} | Agência: {{company.dadosBancarios.agencia}} | Conta {{company.dadosBancarios.tipoConta}}: {{company.dadosBancarios.conta}}</p>
      {{/if}}
    </div>

    <div class="representative-info" style="border: 1px solid ${borderColor}; border-radius: ${borderRadius}; padding: ${sectionPadding}; margin-bottom: ${sectionMargin};">
      <h3 style="margin: 0 0 1mm 0; font-size: 12pt;">Representante Legal</h3>
      
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 0.5mm;">
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Nome:</strong> {{company.representanteLegal.nome}}</p>
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">CPF:</strong> {{company.representanteLegal.cpf}}</p>
      </div>
      
      <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 0.5mm;">
        {{#if company.representanteLegal.rg}}
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">RG:</strong> {{company.representanteLegal.rg}} {{#if company.representanteLegal.orgaoEmissor}}- {{company.representanteLegal.orgaoEmissor}}{{/if}}</p>
        {{/if}}
        <p style="margin: 0; line-height: ${lineHeight};"><strong style="color: ${labelColor};">Cargo:</strong> {{company.representanteLegal.qual}}</p>
      </div>
    </div>

    <div class="items-section" style="border: 1px solid ${borderColor}; border-radius: ${borderRadius}; padding: ${sectionPadding}; margin-bottom: ${sectionMargin};">
      <table class="items-table" style="width: 100%; border-collapse: collapse; font-size: ${fontSize};">
        <thead>
          <tr style="background-color: ${theme === 'light' ? '#f5f5f5 !important' : '#333 !important'};">
            <th style="border: 1px solid ${tableBorder}; padding: 3px 4px; width: ${showMarcaFabricante ? '45%' : '65%'}; font-size: 11pt;">Descrição</th>
            <th style="border: 1px solid ${tableBorder}; padding: 3px 4px; text-align: center; width: 10%; font-size: 11pt;">Quantidade</th>
            {{#if showMarcaFabricante}}
            <th style="border: 1px solid ${tableBorder}; padding: 3px 4px; width: 12%; font-size: 11pt;">Marca</th>
            <th style="border: 1px solid ${tableBorder}; padding: 3px 4px; width: 12%; font-size: 11pt;">Fabricante</th>
            {{/if}}
            <th style="border: 1px solid ${tableBorder}; padding: 3px 4px; text-align: right; width: 8%; font-size: 11pt;">Valor Unit</th>
            <th style="border: 1px solid ${tableBorder}; padding: 3px 4px; text-align: right; width: 8%; font-size: 11pt;">Valor Total</th>
          </tr>
        </thead>
        <tbody>
          {{#each items}}
          <tr>
            <td style="border: 1px solid ${tableBorder}; padding: 3px 4px;">{{descricao}}</td>
            <td style="border: 1px solid ${tableBorder}; padding: 3px 4px; text-align: center;">{{quantidade}} {{unidadeMedida}}</td>
            {{#if ../showMarcaFabricante}}
            <td style="border: 1px solid ${tableBorder}; padding: 3px 4px;">{{marca}}</td>
            <td style="border: 1px solid ${tableBorder}; padding: 3px 4px;">{{fabricante}}</td>
            {{/if}}
            <td style="border: 1px solid ${tableBorder}; padding: 3px 4px; text-align: right;">{{valorUnitarioFormatted}}</td>
            <td style="border: 1px solid ${tableBorder}; padding: 3px 4px; text-align: right;">{{valorTotalFormatted}}</td>
          </tr>
          {{/each}}
        </tbody>
      </table>
      <p style="text-align: right; margin-top: 2mm; font-size: ${fontSize};"><strong>Valor Total da Proposta: {{totalValue}}</strong></p>

      {{#if options.showDeliveryLocation}}
      <div style="margin-top: 2mm; font-size: 9pt; line-height: 1.2;">
        {{options.deliveryLocationText}}
      </div>
      {{/if}}
    </div>

    <div class="additional-info" style="border: 1px solid ${borderColor}; border-radius: ${borderRadius}; padding: ${sectionPadding}; margin-bottom: ${sectionMargin};">
      {{#if options.validity}}
      <p style="margin: 0; line-height: ${lineHeight}; font-size: ${fontSize};"><strong>Validade da Proposta:</strong> {{options.validity.days}} dias</p>
      {{/if}}
      {{#if options.delivery}}
      <p style="margin: 0; line-height: ${lineHeight}; font-size: ${fontSize};"><strong>Prazo de Entrega:</strong> {{options.delivery.text}}</p>
      {{/if}}
      {{#if options.payment}}
      <p style="margin: 0; line-height: ${lineHeight}; font-size: ${fontSize};"><strong>Condições de Pagamento:</strong> {{options.payment.text}}</p>
      {{/if}}
      {{#if options.warranty}}
      <p style="margin: 0; line-height: ${lineHeight}; font-size: ${fontSize};"><strong>Garantia:</strong> {{options.warranty.text}}</p>
      {{/if}}
      {{#if options.customText}}
      <p style="margin: 0; line-height: ${lineHeight}; font-size: ${fontSize};">{{options.customText}}</p>
      {{/if}}
    </div>

    <div class="declarations" style="border: 1px solid ${borderColor}; border-radius: ${borderRadius}; padding: ${sectionPadding}; margin-bottom: ${sectionMargin};">
      <p style="margin: 0; font-size: 8.5pt; line-height: 1.1; text-align: justify;">
        Assim sendo, o valor total da proposta é de <strong>{{totalValue}}</strong>.<br><br>
        Declaramos para todos os efeitos legais que, ao apresentar esta proposta, com os preços e prazos acima indicados, estamos de pleno acordo com as exigências, condições gerais e especiais estabelecidas no Edital para a presente licitação, as quais nos submetemos incondicional e integralmente.<br><br>
        Declaramos que os preços cotados abrangem todas as despesas que, de forma direta ou indireta, integram o objeto em questão. Isso inclui os custos da empresa com suporte técnico e administrativo, impostos, seguros, taxas ou quaisquer outros encargos que possam incidir sobre os gastos da empresa, sem quaisquer acréscimos devido a expectativas inflacionárias, e já considerando quaisquer descontos eventualmente concedidos.<br><br>
        Declaramos, sob pena de desclassificação, que a nossa proposta econômica compreende a integralidade dos custos para atendimento dos direitos trabalhistas assegurados na Constituição Federal, nas leis trabalhistas, nas normas infralegais, nas convenções coletivas de trabalho e nos termos de ajustamento de conduta vigentes na data de sua entrega.<br><br>
        Declaramos que até a presente data inexistem fatos impeditivos a participação desta empresa ao presente certame licitatório, ciente da obrigatoriedade de declarar ocorrências posteriores.<br><br>
        Declaramos que não possuímos em nosso quadro funcional servidor público ou dirigente de órgão ou entidade contratante ou responsável pela licitação, e não possuímos em nosso quadro societário servidor público da ativa, ou empregado de empresa pública ou de sociedade de economia mista.<br><br>
        Declaramos, ainda, sob as penas da lei, que não estamos cumprindo pena de inidoneidade para licitar e contratar com a Administração Pública, em qualquer de suas esferas Federal, Estadual e Municipal, inclusive no Distrito Federal. Depois de homologado o resultado nos comprometemos a assinar o CONTRATO, no prazo determinado no documento de convocação.<br><br>
        Propomo-nos a cumprir o prazo de entrega e aceitamos as condições de pagamento conforme determina o Edital.
      </p>
    </div>

    <div class="signature-area" style="padding: 2mm; text-align: center;">
      <p style="margin: 0 0 4mm 0;">{{company.endereco.municipio}}, {{date}}</p>
      <p style="margin: 0;">_____________________________________________</p>
      <p style="margin: 0 0 0 0; font-size: 8pt;">{{company.representanteLegal.nome}} - {{company.representanteLegal.qual}}</p>
      <p style="margin: 0; font-size: 8pt;">CPF: {{company.representanteLegal.cpf}}</p>
      <p style="margin: 0; font-size: 8pt;">{{company.razaoSocial}} - CNPJ: {{company.cnpj}}</p>
    </div>

    <div class="page-footer">
      <p style="margin: 0 0 0 0;">{{company.razaoSocial}} | CNPJ: {{company.cnpj}}</p>
      <p style="margin: 0;">{{company.endereco.logradouro}}, {{company.endereco.numero}}{{#if company.endereco.complemento}}, {{company.endereco.complemento}}{{/if}} - {{company.endereco.municipio}}/{{company.endereco.uf}} | Tel: {{company.contato.telefoneFormatted}} | Email: {{company.contato.email}}</p>
    </div>
  </div>
`;

export const proposalPrintStyles = `
  @page {
    size: A4;
    margin: 5mm 5mm 15mm 5mm;
    marks: none;
  }
  @media print {
    html {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    @page {
      margin: 5mm 5mm 15mm 5mm;
      size: A4;
    }
    @page :first {
      margin-top: 5mm;
    }
    @page :left {
      margin-left: 5mm;
    }
    @page :right {
      margin-right: 5mm;
    }

    body {
      margin: 0;
      padding: 0;
      font-size: 8pt;
      line-height: 1;
    }
    #printable-area {
      position: relative;
      padding-bottom: 20mm !important;
    }
    .company-logo {
      max-width: 150px !important;
      max-height: 60px !important;
    }
    table {
      width: 100% !important;
      border-collapse: collapse !important;
      font-size: 8pt !important;
    }
    td, th {
      padding: 1px 2px !important;
      border: 1px solid #ccc !important;
    }
    .header-info {
      padding: 0 2mm 2mm 2mm !important;
      margin-bottom: 2mm !important;
    }
    .company-info, .representative-info, .items-section, .additional-info, .declarations {
      border: 1px solid ${borderColor} !important;
      border-radius: ${borderRadius} !important;
      padding: 2mm !important;
      margin-bottom: 1mm !important;
    }
    .signature-area {
      border: none !important;
      padding: 2mm !important;
      margin-bottom: 1mm !important;
    }
    .page-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 15mm;
      font-size: 6pt;
      text-align: center;
      border-top: 1px solid ${borderColor};
      padding-top: 1mm;
      background: white;
      z-index: 1000;
    }
    .items-section {
      margin-bottom: 10mm !important;
    }
    .declarations {
      margin-bottom: 10mm !important;
    }
    #printable-area::after {
      content: '';
      display: block;
      height: 15mm;
      clear: both;
    }
    html {
      -webkit-print-color-adjust: exact !important;
      -webkit-print-header-footer: none !important;
    }
    body {
      -webkit-print-color-adjust: exact !important;
    }

    .items-table thead tr {
      background-color: white !important;
    }
  }
`;
