import Api from '@services/api';
import {
  Bulletin,
  LicitacaoViewFormat,
  LicitacoesSearchParams,
  LicitacoesSearchResponse,
} from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface BulletinState {
  bulletins: Bulletin[];
  currentBulletinLicitacoes: LicitacoesSearchResponse | null;
  currentPage: number;
  isLoadingBulletins: boolean;
  isLoadingLicitacoes: boolean;
  error: string | null;
  totalPages: number;
  itemsPerPage: number;
  viewFormat: LicitacaoViewFormat;
  currentBulletin: Bulletin | null;
  fetchBulletins: (date?: string) => Promise<void>;
  createBulletin: (name: string, searchParams: LicitacoesSearchParams) => Promise<void>;
  fetchBulletinLicitacoes: (bulletinId: string, date?: string, page?: number) => Promise<void>;
  updateBulletin: (
    bulletinId: string,
    updates: Partial<{ name: string; description: string; searchParams: LicitacoesSearchParams }>,
  ) => Promise<void>;
  deleteBulletin: (bulletinId: string) => Promise<void>;
  resetStore: () => void;
  setCurrentPage: (page: number) => void;
  setItemsPerPage: (perPage: number) => void;
  setViewFormat: (format: LicitacaoViewFormat) => void;
  setCurrentBulletin: (bulletin: Bulletin | null) => void;
}

export const useBulletinStore = create<BulletinState>()(
  persist(
    (set, get) => ({
      bulletins: [], // Initialize with an empty array
      currentBulletinLicitacoes: null,
      currentPage: 1,
      isLoadingBulletins: false,
      isLoadingLicitacoes: false,
      error: null,
      totalPages: 0,
      itemsPerPage: 20,
      viewFormat: 'list',
      currentBulletin: null,
      fetchBulletins: async (date?: string) => {
        if (!get().isLoadingBulletins) {
          set({ isLoadingBulletins: true, error: null });
          try {
            const bulletins = await Api.getBulletins(date);
            if (Array.isArray(bulletins)) {
              set({ bulletins: bulletins || [], isLoadingBulletins: false });
            } else {
              const error = 'Formato de resposta inesperado';
              console.error(error, bulletins);
              set({ error, isLoadingBulletins: false, bulletins: [] });
              throw new Error(error);
            }
          } catch (error: any) {
            console.error('Error fetching bulletins:', error);
            const errorMessage = error.message || 'Falha ao buscar boletins';
            set({ error: errorMessage, isLoadingBulletins: false, bulletins: [] });
            throw error;
          }
        }
      },
      createBulletin: async (name: string, searchParams: LicitacoesSearchParams) => {
        set({ isLoadingBulletins: true, error: null });
        try {
          const newBulletin = await Api.createBulletin(name, searchParams);
          set((state) => ({
            bulletins: [...state.bulletins, newBulletin],
            isLoadingBulletins: false,
          }));
        } catch (error: any) {
          console.error('Error creating bulletin:', error);
          const errorMessage = error.message || 'Falha ao criar boletim';
          set({ error: errorMessage, isLoadingBulletins: false });
          throw error;
        }
      },
      fetchBulletinLicitacoes: async (bulletinId: string, date?: string, page: number = 1) => {
        set({ isLoadingLicitacoes: true, error: null });
        try {
          const licitacoes = await Api.getBulletinLicitacoes(
            bulletinId,
            date,
            page,
            get().itemsPerPage,
          );
          set({
            currentBulletinLicitacoes: {
              ...licitacoes,
              licitacoes: licitacoes.licitacoes,
            },
            isLoadingLicitacoes: false,
            currentPage: page,
            totalPages: Math.ceil(licitacoes.totalLicitacoes / get().itemsPerPage),
          });
        } catch (error: any) {
          console.error('Error fetching bulletin licitacoes:', error);
          const errorMessage = error.message || 'Falha ao buscar licitações do boletim';
          set({
            error: errorMessage,
            isLoadingLicitacoes: false,
            totalPages: 0,
          });
          throw error;
        }
      },
      updateBulletin: async (
        bulletinId: string,
        updates: Partial<{
          name: string;
          description: string;
          searchParams: LicitacoesSearchParams;
        }>,
      ) => {
        const previousBulletins = [...get().bulletins];
        set({ isLoadingBulletins: true, error: null });
        try {
          const updatedBulletin = await Api.updateBulletin(bulletinId, updates);
          set((state) => ({
            bulletins: state.bulletins.map((b) => (b.id === bulletinId ? updatedBulletin : b)),
            isLoadingBulletins: false,
          }));
        } catch (error: any) {
          console.error('Error updating bulletin:', error);
          const errorMessage = error.message || 'Falha ao atualizar boletim';
          set({ error: errorMessage, bulletins: previousBulletins, isLoadingBulletins: false });
          throw error;
        }
      },
      deleteBulletin: async (bulletinId: string) => {
        const previousBulletins = [...get().bulletins];
        set({ isLoadingBulletins: true, error: null });
        try {
          await Api.deleteBulletin(bulletinId);
          set((state) => ({
            bulletins: state.bulletins.filter((b) => b.id !== bulletinId),
            isLoadingBulletins: false,
          }));
        } catch (error: any) {
          console.error('Error deleting bulletin:', error);
          const errorMessage = error.message || 'Falha ao deletar boletim';
          set({ error: errorMessage, bulletins: previousBulletins, isLoadingBulletins: false });
          throw error;
        }
      },
      resetStore: () =>
        set({
          bulletins: [],
          currentBulletinLicitacoes: null,
          currentPage: 1,
          isLoadingBulletins: false,
          isLoadingLicitacoes: false,
          error: null,
          totalPages: 0,
          itemsPerPage: 20,
          viewFormat: 'list',
        }),
      setCurrentPage: (page: number) => set({ currentPage: page }),
      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage, currentPage: 1 });

        const currentBulletinId = get().currentBulletin?.id;
        if (currentBulletinId) {
          get().fetchBulletinLicitacoes(currentBulletinId, undefined, 1);
        }
      },
      setViewFormat: (format: LicitacaoViewFormat) => set({ viewFormat: format }),
      setCurrentBulletin: (bulletin: Bulletin | null) => set({ currentBulletin: bulletin }),
    }),
    {
      name: 'bulletin-storage',
      partialize: (state: BulletinState) => ({
        itemsPerPage: state.itemsPerPage,
        viewFormat: state.viewFormat,
      }),
    },
  ),
);
