import { Close as CloseIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DurationId, PlanId } from '@types';
import React from 'react';
import SubscriptionFlow from './SubscriptionFlow';

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  initialStep?: number;
  initialDuration?: DurationId | null;
  initialPlanId?: PlanId | null;
  title?: string;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  initialStep = 0,
  initialDuration = null,
  initialPlanId = null,
  title = 'Assinatura',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : '80%',
          m: isMobile ? 0 : 2,
          maxHeight: isMobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center' }}>
        {title}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <SubscriptionFlow
          initialStep={initialStep}
          onClose={onClose}
          onSuccess={onSuccess}
          initialDurationId={initialDuration}
          initialPlanId={initialPlanId}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionModal;
