import { useAnalytics } from '@hooks/useAnalytics';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, CircularProgress, Fade, TextareaAutosize } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAnnotationsStore } from '@store/annotationsStore';
import React, { useCallback, useEffect, useState } from 'react';

interface AnnotationProps {
  licitacaoId: string;
  isVisible: boolean;
  type: 'favorite' | 'following';
}

const Annotation: React.FC<AnnotationProps> = ({ licitacaoId, isVisible, type }) => {
  const { trackEvent } = useAnalytics();
  const theme = useTheme();
  const { updateAnnotation, getAnnotation } = useAnnotationsStore();
  const [annotation, setAnnotation] = useState('');
  const [savingState, setSavingState] = useState<'saving' | 'success' | 'error' | null>(null);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();
  const [lastSavedAnnotation, setLastSavedAnnotation] = useState('');

  useEffect(() => {
    const currentAnnotation = getAnnotation(licitacaoId);
    setAnnotation(currentAnnotation);
    setLastSavedAnnotation(currentAnnotation);
  }, [licitacaoId, getAnnotation]);

  const saveAnnotation = useCallback(async () => {
    if (isVisible && annotation !== lastSavedAnnotation) {
      setSavingState('saving');
      try {
        await updateAnnotation(licitacaoId, annotation);
        setLastSavedAnnotation(annotation);
        setSavingState('success');
        setTimeout(() => setSavingState(null), 2000);
        trackEvent('annotation_update', {
          type: type,
          licitacao_id: licitacaoId,
          annotation_length: annotation.length,
          save_status: 'success',
        });
      } catch (error) {
        setSavingState('error');
        setTimeout(() => setSavingState(null), 3000);
        trackEvent('annotation_error', {
          type: type,
          licitacao_id: licitacaoId,
          error_message: error instanceof Error ? error.message : String(error),
        });
      }
    }
  }, [licitacaoId, annotation, lastSavedAnnotation, isVisible, updateAnnotation, trackEvent, type]);

  const handleAnnotationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnnotation(event.target.value);
    setSavingState(null);

    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    const timeout = setTimeout(() => {
      saveAnnotation();
    }, 3000);

    setSaveTimeout(timeout);

    trackEvent('annotation_update', {
      type: type,
      licitacao_id: licitacaoId,
      new_length: event.target.value.length,
    });
  };

  const handleAnnotationBlur = () => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    saveAnnotation();
  };

  useEffect(() => {
    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [saveTimeout]);

  const handleAnnotationClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box sx={{ mt: 2, position: 'relative' }}>
      <TextareaAutosize
        minRows={2}
        placeholder="Adicione uma anotação para esta licitação"
        value={annotation}
        onClick={handleAnnotationClick}
        onChange={handleAnnotationChange}
        onBlur={handleAnnotationBlur}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderColor: theme.palette.primary.main,
          borderRadius: '4px',
          fontFamily: 'inherit',
          fontSize: 'inherit',
        }}
      />
      <Box
        sx={{ position: 'absolute', right: 8, bottom: 8, display: 'flex', alignItems: 'center' }}
      >
        <Fade in={savingState === 'saving'}>
          <CircularProgress size={16} />
        </Fade>
        <Fade in={savingState === 'success'}>
          <CheckCircleIcon color="success" sx={{ fontSize: 16 }} />
        </Fade>
        <Fade in={savingState === 'error'}>
          <ErrorIcon color="error" sx={{ fontSize: 16 }} />
        </Fade>
      </Box>
    </Box>
  );
};

export default Annotation;
