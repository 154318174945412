import EyeIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { MouseEventHandler } from 'react';

interface FollowingButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  dataTour?: string | undefined;
}

const FollowingButton: React.FC<FollowingButtonProps> = ({
  onClick = () => {},
  dataTour = undefined,
}) => {
  return (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      onClick={onClick}
      startIcon={<EyeIcon />}
      className="following-button"
      sx={{
        fontSize: '0.75rem',
        py: 0.5,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        gap: 0.5,
      }}
      data-tour={dataTour}
    >
      Acompanhar
    </Button>
  );
};

export default FollowingButton;
