import { useAnalytics } from '@hooks/useAnalytics';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { IconButton, SxProps, Tooltip } from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import React from 'react';

interface FavoriteButtonProps {
  licitacaoId: string;
  isAlreadyFavorite?: boolean;
  className?: string;
  dataTour?: string | undefined;
  sx?: SxProps;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  licitacaoId,
  isAlreadyFavorite,
  className,
  sx,
  dataTour = undefined,
}) => {
  const { trackEvent } = useAnalytics();
  const { isFavorite, addFavorite, removeFavorite } = useFavoritesStore();
  const { isFollowing } = useFollowingStore();
  const isFav = !isAlreadyFavorite ? isFavorite(licitacaoId) : true;

  const handleToggleFavorite = async (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      if (isFav && !isFollowing(licitacaoId)) {
        await removeFavorite(licitacaoId);
        trackEvent('favorite_remove', {
          licitacao_id: licitacaoId,
          was_following: false,
        });
      } else if (!isFav) {
        await addFavorite(licitacaoId);
        trackEvent('favorite_add', {
          licitacao_id: licitacaoId,
          was_following: isFollowing(licitacaoId),
        });
      }
    } catch (error) {
      trackEvent('favorite_error', {
        type: 'action',
        licitacao_id: licitacaoId,
        action: isFav ? 'remove' : 'add',
        error_message: error instanceof Error ? error.message : String(error),
      });
    }
  };

  const getTooltipTitle = () => {
    if (isFav && isFollowing(licitacaoId)) {
      return 'Não é possível remover dos favoritos enquanto estiver acompanhando a licitação';
    }
    return isFav ? 'Remover dos favoritos' : 'Adicionar aos favoritos';
  };

  return (
    <Tooltip title={getTooltipTitle()} sx={sx || {}}>
      <span>
        <IconButton
          onClick={handleToggleFavorite}
          size="small"
          className={`${className} favorite-button`}
          disabled={isFav && isFollowing(licitacaoId)}
          data-tour={dataTour}
        >
          {isFav ? <StarIcon sx={{ color: 'yellow' }} /> : <StarBorderIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default FavoriteButton;
