import { Info as InfoIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

interface InfoSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
  icon?: React.ElementType;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  title,
  children,
  className,
  sx = {},
  icon: Icon = InfoIcon,
  onClick,
}) => {
  return (
    <Box className={className} onClick={onClick} sx={{ mb: 3, ...sx }}>
      <Typography variant="h6" color="primary" gutterBottom sx={{ fontSize: '1rem' }}>
        <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Icon color="primary" sx={{ fontSize: 20 }} />
          {title}
        </Box>
      </Typography>
      {children}
    </Box>
  );
};

export default InfoSection;
