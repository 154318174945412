import InfoSection from '@components/Common/InfoSection';
import MessageBox from '@components/Common/MessageBox';
import { Login as LoginIcon, PersonAdd as PersonAddIcon } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PortalService } from '@types';

interface LoginStepProps {
  portalService: PortalService;
  portalName?: string;
}

export const LoginStep: React.FC<LoginStepProps> = ({ portalService, portalName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: isMobile ? 2 : 3,
      }}
    >
      <MessageBox variant="info" sx={{ mb: isMobile ? 1 : 2 }}>
        O primeiro passo para participar de uma licitação é ter um cadastro válido no{' '}
        <strong>{portalName || portalService.name}</strong>.
      </MessageBox>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          width: '100%',
        }}
      >
        {/* Left Column */}
        <Box
          sx={{
            flex: 1,
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 2 : 3,
          }}
        >
          <Box>
            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              color="primary"
              gutterBottom
              sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
            >
              Cadastro no Portal
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Acesse o portal para se cadastrar:
            </Typography>
            <Button
              fullWidth={isMobile}
              variant="contained"
              color="primary"
              size={isMobile ? 'small' : 'medium'}
              startIcon={<PersonAddIcon />}
              href={portalService.getRegisterUrl() || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isMobile ? 'Cadastrar' : 'Cadastre-se no portal'}
            </Button>
          </Box>

          <InfoSection title="Requisitos para Cadastro" sx={{ mt: isMobile ? 1 : 2 }}>
            <ul style={{ paddingLeft: isMobile ? '1rem' : '2rem' }}>
              <li>CNPJ ativo e regular</li>
              <li>Certificado Digital válido</li>
              <li>Email corporativo válido</li>
            </ul>
          </InfoSection>

          {!isMobile && (
            <InfoSection title="Documentos Necessários">
              Cada portal possui requisitos específicos para o cadastro. Os mais comuns são:
              <ul>
                <li>Contrato Social ou equivalente</li>
                <li>RG e CPF dos sócios</li>
                <li>Procuração (se aplicável)</li>
              </ul>
            </InfoSection>
          )}
        </Box>

        {/* Right Column */}
        <Box
          sx={{
            flex: 1,
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 2 : 3,
          }}
        >
          <Box>
            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              color="primary"
              gutterBottom
              sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
            >
              Acesso ao Portal
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Já possui cadastro? Acesse sua conta:
            </Typography>
            <Button
              fullWidth={isMobile}
              variant="contained"
              color="secondary"
              size={isMobile ? 'small' : 'medium'}
              startIcon={<LoginIcon />}
              href={portalService.getLoginUrl() || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isMobile ? 'Login' : 'Fazer login no portal'}
            </Button>
          </Box>

          <InfoSection title="Passos para Cadastro">
            <ol style={{ paddingLeft: isMobile ? '1rem' : '2rem' }}>
              <li>Acesse o portal através do botão "Cadastre-se"</li>
              <li>Localize a opção "Cadastro de Fornecedor"</li>
              <li>Preencha todos os dados da empresa</li>
              <li>Anexe a documentação solicitada</li>
              <li>Aguarde a validação do cadastro</li>
            </ol>
          </InfoSection>

          {!isMobile && (
            <InfoSection title="Checklist de Acesso">
              <ol>
                <li>Confira se o seu cadastro no portal está completo</li>
                <li>Cheque se a sua conta está ativa</li>
                <li>Verifique se sua conta possui pendências</li>
                <li>Resolva as pendências para continuar</li>
              </ol>
            </InfoSection>
          )}
        </Box>
      </Box>
    </Box>
  );
};
