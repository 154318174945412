import { CompanySizeEnum } from '@types';

export * from './cnaes';
export * from './documents';
export * from './events';
export * from './kanban';
export * from './licitacao';
export * from './mappings';
export * from './plans';
export * from './proposal';
export * from './steps';
export * from './templates';

export const TIME_ZONE = 'America/Sao_Paulo';

export const AI_CHAT_EXAMPLE_QUESTIONS = [
  'Quais documentos são necessários nesta licitação?',
  'Qual é o prazo para envio da proposta?',
  'Quais são os principais requisitos técnicos?',
  'Existe alguma exigência específica para participação?',
  'Precisa de seguro garantia? Quais?',
  'Quando será a sessão de abertura das propostas?',
  'Há possibilidade de visita técnica?',
  'Quais são as condições de pagamento?',
  'Existe previsão de subcontratação?',
  'Quais são os critérios de julgamento das propostas?',
];

export const COMPANY_SIZES: Record<CompanySizeEnum, string> = {
  [CompanySizeEnum.MEI]: 'MICRO EMPREENDEDOR INDIVIDUAL',
  [CompanySizeEnum.MICRO]: 'MICRO EMPRESA',
  [CompanySizeEnum.SMALL]: 'EMPRESA DE PEQUENO PORTE',
  [CompanySizeEnum.MEDIUM]: 'EMPRESA DE MÉDIO PORTE',
  [CompanySizeEnum.LARGE]: 'EMPRESA DE GRANDE PORTE',
};

export const COMPANY_WHATSAPP = '+5541998591793';
export const WHATSAPP_DEFAULT_MESSAGE = 'Olá! Preciso de ajuda com o Licitou.';
