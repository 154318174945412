import CnaeSelector from '@components/Input/CnaeSelector';
import Keywords from '@components/Input/Keywords';
import MultiSelect from '@components/Input/MultiSelect';
import { brazilianStates, ESFERAS, modalidadesMap } from '@constants';
import { Autocomplete, Box, FormControl, TextField, useTheme } from '@mui/material';
import Api from '@services/api';
import { Esfera, LicitacoesSearchParams, Portal } from '@types';
import { createPortalOptions } from '@utils';
import React, { useCallback, useEffect, useState } from 'react';

const modalidadesOptions = Object.entries(modalidadesMap).map(([value, { name }]) => ({
  value,
  label: name,
}));

const portalOptions = createPortalOptions();

const stateOptions = brazilianStates.map((state) => ({
  value: state.value,
  label: state.label,
}));

const esferaOptions = Object.entries(ESFERAS).map(([key, value]) => ({
  value: key,
  label: value,
}));

interface BulletinSearchParamsProps {
  onParamsChange: (params: LicitacoesSearchParams) => void;
  initialParams?: LicitacoesSearchParams;
}

interface FormState {
  uf: string[];
  selectedCity: string[];
  modalidade: string[];
  selectedPortal: string[];
  minValue: string;
  maxValue: string;
  selectedCnaes: string[];
  codigoUasg: { label: string; value: string } | null;
  item: string;
  edital: string;
  keywords: string[];
  negativeKeywords: string[];
  selectedEsfera: string[];
}

const BulletinSearchParams: React.FC<BulletinSearchParamsProps> = ({
  onParamsChange,
  initialParams,
}) => {
  const theme = useTheme();

  const [formState, setFormState] = useState<FormState>({
    uf: [],
    selectedCity: [],
    modalidade: [],
    selectedPortal: [],
    minValue: '',
    maxValue: '',
    selectedCnaes: [],
    codigoUasg: null,
    item: '',
    edital: '',
    keywords: [],
    negativeKeywords: [],
    selectedEsfera: [],
  });

  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);

  const updateFormState = useCallback((updates: Partial<FormState>) => {
    setFormState((prevState) => ({ ...prevState, ...updates }));
  }, []);

  useEffect(() => {
    if (initialParams) {
      setFormState({
        uf: initialParams.uf || [],
        selectedCity: initialParams.municipio_ibge || [],
        modalidade: initialParams.modalidade || [],
        selectedPortal: initialParams.id_portal || [],
        minValue: initialParams.valor_min || '',
        maxValue: initialParams.valor_max || '',
        selectedCnaes: initialParams.cnae || [],
        codigoUasg: initialParams.codigo_uasg
          ? { label: initialParams.codigo_uasg[0], value: initialParams.codigo_uasg[0] }
          : null,
        item: initialParams.item || '',
        edital: initialParams.edital || '',
        keywords: initialParams.palavra_chave || [],
        negativeKeywords: initialParams.palavras_excluidas || [],
        selectedEsfera: initialParams.esfera || [],
      });
    }
  }, [initialParams]);

  useEffect(() => {
    const params: LicitacoesSearchParams = {
      uf: formState.uf,
      municipio_ibge: formState.selectedCity,
      modalidade: formState.modalidade,
      id_portal: formState.selectedPortal.map(String) as Portal[],
      valor_min: formState.minValue ? String(formState.minValue) : undefined,
      valor_max: formState.maxValue ? String(formState.maxValue) : undefined,
      cnae: formState.selectedCnaes,
      codigo_uasg: formState.codigoUasg ? [formState.codigoUasg.value] : undefined,
      item: formState.item,
      edital: formState.edital,
      palavra_chave: formState.keywords,
      palavras_excluidas: formState.negativeKeywords,
      esfera: formState.selectedEsfera.map(String) as Esfera[],
    };
    onParamsChange(params);
  }, [formState, onParamsChange]);

  useEffect(() => {
    const fetchCities = async () => {
      if (formState.uf.length > 0) {
        const citiesPromises = formState.uf.map((state) => Api.getCitiesByStateUF(state));
        const citiesDataArray = await Promise.all(citiesPromises);

        const availableCities = citiesDataArray.flatMap((citiesData) =>
          citiesData.map((city) => ({
            label: city.nome_municipio,
            value: city.codigo_ibge.toString(),
          })),
        );

        setCities(availableCities);

        // Filter out cities that are not available for the selected states
        const validCities = formState.selectedCity.filter((city) =>
          availableCities.some((availableCity) => availableCity.value === city),
        );

        if (validCities.length !== formState.selectedCity.length) {
          updateFormState({ selectedCity: validCities });
        }
      } else {
        setCities([]);
        updateFormState({ selectedCity: [] });
      }
    };

    fetchCities();
  }, [formState.uf, updateFormState]);

  const handleStateChange = async (selectedStates: string[]) => {
    updateFormState({ uf: selectedStates });

    // The city update logic is now handled in the useEffect above
  };

  const commonInputStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.dark,
      },
    },
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <Keywords
          keywords={formState.keywords}
          onChange={(value) => updateFormState({ keywords: value })}
          placeholder="Digite palavras-chave e pressione ENTER"
          label="Palavras-chave"
          helperText="Digite palavras-chave e pressione ENTER"
          sx={{
            width: '100%',
            mr: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
              },
            },
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <FormControl fullWidth>
          <CnaeSelector
            value={formState.selectedCnaes}
            onChange={(cnaes) => updateFormState({ selectedCnaes: cnaes })}
            label="CNAE"
          />
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <MultiSelect<string>
          label="Estados"
          options={stateOptions}
          value={formState.uf}
          onChange={(value) => {
            updateFormState({ uf: value });
            handleStateChange(value);
          }}
          showValueInChip={true}
          sx={{ mr: 1, flex: 1 }}
        />
        <MultiSelect<string>
          label="Cidades"
          options={cities}
          value={formState.selectedCity}
          onChange={(value) => updateFormState({ selectedCity: value })}
          disabled={formState.uf.length === 0}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', mb: 2, gap: 1 }}>
        <Box sx={{ width: '33%' }}>
          <MultiSelect<number>
            label="Portais"
            options={portalOptions}
            value={formState.selectedPortal.map(Number)}
            onChange={(value) => updateFormState({ selectedPortal: value.map(String) })}
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ width: '33%' }}>
          <MultiSelect<string>
            label="Modalidades"
            options={modalidadesOptions}
            value={formState.modalidade}
            onChange={(value) => updateFormState({ modalidade: value })}
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ width: '33%' }}>
          <Autocomplete
            options={[]}
            value={formState.codigoUasg}
            onChange={(event, newValue) => updateFormState({ codigoUasg: newValue })}
            renderInput={(params) => (
              <TextField {...params} label="Código UASG" sx={commonInputStyle} />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', mb: 2, gap: 1 }}>
        <Box sx={{ width: '33%' }}>
          <MultiSelect<string>
            label="Esfera"
            options={esferaOptions}
            value={formState.selectedEsfera}
            onChange={(value) => updateFormState({ selectedEsfera: value })}
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ width: '33%' }}>
          <TextField
            label="Valor Mínimo"
            value={formState.minValue}
            onChange={(e) => updateFormState({ minValue: e.target.value })}
            type="number"
            fullWidth
            sx={{ ...commonInputStyle }}
          />
        </Box>
        <Box sx={{ width: '33%' }}>
          <TextField
            label="Valor Máximo"
            value={formState.maxValue}
            onChange={(e) => updateFormState({ maxValue: e.target.value })}
            type="number"
            fullWidth
            sx={{ ...commonInputStyle }}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', mb: 2 }}>
        <Keywords
          keywords={formState.negativeKeywords}
          onChange={(value) => updateFormState({ negativeKeywords: value })}
          placeholder="Digite palavras para excluir e pressione ENTER"
          label="Palavras para excluir"
          helperText="Digite palavras para excluir e pressione ENTER"
          addHelper={false}
          sx={{
            width: '100%',
            mr: 1,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.error.main,
              },
              '&:hover fieldset': {
                borderColor: theme.palette.error.light,
              },
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.error.dark,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BulletinSearchParams;
