import InfoIcon from '@mui/icons-material/Info';
import { Box, Paper, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

interface EmptyStateBoxProps {
  title: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  sx?: SxProps<Theme>;
  icon?: React.ElementType;
}

const EmptyStateBox = ({
  title,
  children,
  onClick,
  className,
  sx = {},
  icon: Icon = InfoIcon,
}: EmptyStateBoxProps) => {
  return (
    <Box className={className} onClick={onClick}>
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <Icon color="primary" />
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </Box>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          ...sx,
        }}
      >
        {typeof children === 'string' ? (
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary">
              {children}
            </Typography>
          </Box>
        ) : (
          children
        )}
      </Paper>
    </Box>
  );
};

export default EmptyStateBox;
