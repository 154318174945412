import UpgradeButton from '@components/Feature/UpgradeButton';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip, Typography } from '@mui/material';
import { FeatureId } from '@types';

interface UpgradePromptProps {
  message: string;
  reason?: string;
  compact?: boolean;
  featureId?: FeatureId;
}

const UpgradePrompt: React.FC<UpgradePromptProps> = ({ message, reason, compact, featureId }) => {
  return compact ? (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="caption" color="text.secondary">
        {message}
        {reason && (
          <Tooltip title={reason}>
            <InfoIcon sx={{ ml: 0.5, fontSize: 14, verticalAlign: 'middle', cursor: 'help' }} />
          </Tooltip>
        )}
      </Typography>
      <UpgradeButton featureId={featureId} message={'Upgrade'} compact />
    </Box>
  ) : (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography color="text.secondary" mb={1}>
        {message}
      </Typography>
      {reason && (
        <Typography variant="caption" color="text.secondary" display="block" mb={2}>
          {reason}
        </Typography>
      )}
      <UpgradeButton featureId={featureId} message={'Fazer Upgrade do Plano'} compact />
    </Box>
  );
};

export default UpgradePrompt;
