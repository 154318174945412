import {
  Box,
  CircularProgress,
  Fade,
  Pagination as MuiPagination,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
}

interface PaginationInfoProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  isLoading?: boolean;
  id?: string;
  sx?: SxProps<Theme>;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  isLoading = false,
  id,
  sx,
}) => {
  const validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));
  const startItem = (validCurrentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(validCurrentPage * itemsPerPage, totalItems);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        whiteSpace: 'nowrap',
        ...sx,
      }}
      id={id}
    >
      <Typography variant="body2" color="text.secondary">
        {startItem}-{endItem} de {totalItems}
      </Typography>
      {isLoading && (
        <Fade in={isLoading}>
          <CircularProgress size={20} />
        </Fade>
      )}
    </Box>
  );
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  onPageChange,
  isLoading = false,
  sx,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  const validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row', // isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: isMobile ? 1 : 2,
        mt: isMobile ? 2 : 3,
        mb: isMobile ? 1 : 2,
        width: '100%',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          order: isMobile ? 2 : 1,
        }}
      >
        <PaginationInfo
          currentPage={validCurrentPage}
          totalPages={totalPages}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          isLoading={isLoading}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          order: isMobile ? 1 : 2,
        }}
      >
        <MuiPagination
          count={totalPages}
          page={validCurrentPage}
          onChange={handleChange}
          color="primary"
          showFirstButton={!isMobile}
          showLastButton={!isMobile}
          siblingCount={isMobile ? 0 : 1}
          size={isMobile ? 'small' : 'medium'}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
