import {
  getTourIdFromPath,
  isTourAvailable,
  useTour as useTourContext,
} from '@contexts/TourContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type UseOnboardingProps = {
  tourId?: string | null;
  onComplete?: () => void;
};

export const useTour = ({ tourId = null, onComplete = () => {} }: UseOnboardingProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { setIsOpen, setCurrentTourId, completeTour, completedTours, setCloseCallback } =
    useTourContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const shouldStartTour = searchParams.get('tour') === '1';
    const isTourValid = isTourAvailable(location.pathname);
    const currentTourId = tourId || getTourIdFromPath(location.pathname);

    if (shouldStartTour && isTourValid && currentTourId) {
      trackEvent('tour_start', {
        tour_id: currentTourId,
        path: location.pathname,
        completed_tours: completedTours.length,
      });

      setCurrentTourId(currentTourId);
      setCloseCallback(() => {
        searchParams.delete('tour');
        navigate(
          {
            pathname: location.pathname,
            search: searchParams.toString() ? `?${searchParams.toString()}` : '',
          },
          { replace: true },
        );

        if (currentTourId) {
          completeTour(currentTourId);
          trackEvent('tour_complete', {
            tour_id: currentTourId,
            path: location.pathname,
            total_completed: Object.keys(completedTours).length + 1,
          });
        }
        onComplete();
      });
      setIsOpen(true);
    }
  }, [location.search, tourId, location.pathname]);

  return {
    setIsOpen: (open: boolean) => {
      setIsOpen(open);
    },
    setCurrentTourId,
    completeTour: (id: string) => {
      completeTour(id);
    },
    completedTours,
    setCloseCallback,
    isAvailable: isTourAvailable(location.pathname),
  };
};
