import Annotation from '@components/Annotation/Annotation';
import DetailsTabs from '@components/Details/DetailsTabs';
import DocumentRequiredModal from '@components/Documents/DocumentsRequiredModal';
import FeatureGate from '@components/Feature/FeatureGate';
import CustomButton from '@components/Input/CustomButton';
import Loading from '@components/Layout/Loading';
import AddToCalendarButton from '@components/Licitacao/AddToCalendarButton';
import AIChatModal from '@components/Licitacao/AIChatModal';
import InfoItem from '@components/Licitacao/InfoItem';
import PortalDetails from '@components/Licitacao/PortalDetails';
import StepsModal from '@components/Licitacao/StepsModal';
import WhatsAppButton from '@components/Licitacao/WhatsAppButton';
import LicitacaoActions from '@components/Search/LicitacaoActions';
import { ESFERAS, PORTAL_NAMES } from '@constants';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import {
  AccountBalance,
  Assignment as AssignmentIcon,
  AttachMoney,
  Business,
  Category,
  Chat as ChatIcon,
  ContentCopy,
  Description,
  Dns,
  Email,
  Event,
  EventAvailable,
  FilePresent,
  ListAlt,
  LocationOn,
  OpenInNew,
  PictureAsPdf,
  Public as PublicIcon,
  Search,
  Today,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PortalServiceFactory } from '@services/portals/PortalServiceFactory';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { FeatureId, Licitacao, RecommendedLicitacao } from '@types';
import { formatCurrency, formatIsoDate, getLicitacaoStatus } from '@utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';

type CombinedLicitacao = Licitacao & RecommendedLicitacao;

const LicitacaoDetails: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id_licitacao } = useParams<{ id_licitacao: string }>();
  const { getLicitacaoById, fetchLicitacaoDetails, isLoadingDetails, getModalidadeInfo, error } =
    useLicitacoesStore();
  const { isFollowing, following } = useFollowingStore();
  const { favorites, isFavorite } = useFavoritesStore();
  const [licitacao, setLicitacao] = useState<CombinedLicitacao | null>(null);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isBiddingModalOpen, setIsBiddingModalOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const [showAnnotation, setShowAnnotation] = useState(false);
  const { showNotification } = useNotification();
  const [searchParams] = useSearchParams();
  const isUpdated = searchParams.get('updated') === 'true';
  const [highlightedFields, setHighlightedFields] = useState<string[]>([]);
  const { hasAccess: hasAiAccess } = useFeatureAccess(FeatureId.AI_ANALYSIS);
  const { hasAccess: hasDocAccess } = useFeatureAccess(FeatureId.DOC_VERIFICATION);

  useEffect(() => {
    if (!id_licitacao) return;

    const fetchData = async () => {
      const currentLicitacao = getLicitacaoById(id_licitacao) as CombinedLicitacao | undefined;

      if (currentLicitacao) {
        setLicitacao(currentLicitacao);
      } else if (!isLoadingDetails) {
        const updatedLicitacao = await fetchLicitacaoDetails(id_licitacao);
        if (updatedLicitacao) {
          setLicitacao(updatedLicitacao as CombinedLicitacao);
        }
      }
    };

    fetchData();
  }, [id_licitacao]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
      trackEvent('licitacao_error', {
        action: 'load',
        error_message: error,
        licitacao_id: id_licitacao,
      });
    }
  }, [error]);

  useEffect(() => {
    if (licitacao) {
      setShowAnnotation(isFavorite(licitacao.id) || isFollowing(licitacao.id));
    }
  }, [licitacao, favorites, following]);

  useEffect(() => {
    if (isUpdated && licitacao?.updatedFields) {
      setHighlightedFields(licitacao.updatedFields);
    }
  }, [isUpdated, licitacao]);

  const handleModalActions = useMemo(
    () => ({
      openChat: () => {
        if (!hasAiAccess) {
          showNotification('Faça upgrade do seu plano para usar a IA', 'warning');
          return;
        }
        setIsChatModalOpen(true);
      },
      closeChat: () => setIsChatModalOpen(false),
      extractDocuments: () => {
        if (!hasDocAccess) {
          showNotification('Faça upgrade do seu plano para analisar documentos', 'warning');
          return;
        }
        setIsDocumentModalOpen(true);
      },
      closeDocument: () => setIsDocumentModalOpen(false),
      openBidding: () => setIsBiddingModalOpen(true),
      closeBidding: () => setIsBiddingModalOpen(false),
    }),
    [hasAiAccess, hasDocAccess, showNotification],
  );

  if (isLoadingDetails || (!licitacao && !error)) {
    return <Loading />;
  }

  if (!licitacao && error) {
    return (
      <>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            Licitação não encontrada
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Search />}
            component={RouterLink}
            to={`/busca`}
          >
            Voltar para a lista de licitações
          </Button>
        </Box>
      </>
    );
  }

  if (!licitacao) {
    return <Loading />;
  }

  const handleCopyKeyInfo = () => {
    const keyInfo = `
📋 Resumo da Licitação

🏛️ Órgão: ${licitacao.orgao}
📍 Local: ${licitacao.municipio} - ${licitacao.uf}
💰 Valor: ${licitacao.valor > 0 ? formatCurrency(licitacao.valor) : 'Não informado'}
📅 Abertura: ${formatIsoDate(licitacao.abertura_datetime)}

🔗 Link: ${window.location.href}
`.trim();

    navigator.clipboard.writeText(keyInfo);
    showNotification('Informações copiadas!', 'success');
  };

  const modalidadeInfo = getModalidadeInfo(licitacao.id_tipo);

  const currentStatus = getLicitacaoStatus(
    licitacao.status,
    licitacao.abertura_datetime,
    licitacao.fim_recebimento_propostas_datetime,
  );

  const portalService = PortalServiceFactory.create(licitacao);

  return (
    <>
      <Helmet>
        <title>
          {licitacao ? `${licitacao.titulo} - Licitou` : 'Detalhes da Licitação - Licitou'}
        </title>
        <meta
          name="description"
          content={
            licitacao?.objeto ||
            'Detalhes completos da licitação, incluindo documentos, itens e informações do portal'
          }
        />
      </Helmet>
      <Box my={isMobile ? 2 : 4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography variant={isMobile ? 'h5' : 'h4'} component="h1" color="primary">
                {licitacao.titulo}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  width: isMobile ? '100%' : 'auto',
                  justifyContent: isMobile ? 'stretch' : 'flex-end',
                }}
              >
                {portalService.getEditalUrl() && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<PictureAsPdf />}
                    href={portalService.getEditalUrl() || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth={isMobile}
                  >
                    Baixar Edital
                  </Button>
                )}
                {portalService.getPortalUrl() && (
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<OpenInNew />}
                    href={portalService.getPortalUrl() || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth={isMobile}
                  >
                    Abrir no portal
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ backgroundColor: theme.palette.background.paper }}>
              <CardContent sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: isMobile ? 'relative' : 'absolute',
                    top: isMobile ? 0 : 16,
                    right: isMobile ? 0 : 16,
                    mb: isMobile ? 2 : 0,
                    display: 'flex',
                    justifyContent: isMobile ? 'flex-end' : 'flex-start',
                  }}
                >
                  <LicitacaoActions licitacaoId={licitacao.id} />
                </Box>

                <Grid container spacing={2} sx={{ mt: isMobile ? 2 : 0 }}>
                  <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <InfoItem
                      icon={AccountBalance}
                      label="Órgão"
                      value={licitacao.orgao}
                      highlight={highlightedFields.includes('orgao')}
                    />
                    <Button
                      onClick={handleCopyKeyInfo}
                      size="small"
                      title="Copiar resumo"
                      sx={{
                        minWidth: 'auto',
                        ml: 1,
                        color: theme.palette.text.secondary,
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      <ContentCopy />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InfoItem
                      icon={LocationOn}
                      label="Local"
                      value={`${licitacao.municipio} - ${licitacao.uf}`}
                      link={`/busca?municipio_ibge=${licitacao.municipio_IBGE}`}
                      highlight={highlightedFields.includes('municipio_ibge')}
                    />
                    <InfoItem
                      icon={Business}
                      label="Portal"
                      value={
                        licitacao.id_portal
                          ? PORTAL_NAMES[licitacao.id_portal]
                          : licitacao.portal || '-'
                      }
                      link={
                        licitacao.id_portal ? `/busca?id_portal=${licitacao.id_portal}` : undefined
                      }
                      highlight={highlightedFields.includes('id_portal')}
                    />
                    <InfoItem
                      icon={Category}
                      label="Código CNAE"
                      value={licitacao.cnae || '-'}
                      link={licitacao.cnae ? `/busca?cnae=${licitacao.cnae}` : undefined}
                      highlight={highlightedFields.includes('cnae')}
                    />
                    <InfoItem
                      icon={Business}
                      label="Código UASG"
                      value={licitacao.codigo_uasg || '-'}
                      link={
                        licitacao.codigo_uasg
                          ? `/busca?codigo_uasg=${licitacao.codigo_uasg}`
                          : undefined
                      }
                      highlight={highlightedFields.includes('codigo_uasg')}
                    />
                    <InfoItem
                      icon={modalidadeInfo.icon}
                      label="Modalidade"
                      value={modalidadeInfo.name}
                      link={`/busca?modalidade=${licitacao.id_tipo}`}
                      highlight={highlightedFields.includes('id_tipo')}
                    />
                    <InfoItem
                      icon={ListAlt}
                      label="Número do edital"
                      value={licitacao.numeroEdital || '-'}
                      link={
                        licitacao.numeroEdital
                          ? `/busca?edital=${licitacao.numeroEdital}`
                          : undefined
                      }
                      highlight={highlightedFields.includes('numeroEdital')}
                    />
                    <InfoItem
                      icon={PublicIcon}
                      label="Esfera"
                      value={ESFERAS[licitacao.esfera] || '-'}
                      link={`/busca?esfera=${licitacao.esfera}`}
                      highlight={highlightedFields.includes('esfera')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InfoItem
                      icon={AttachMoney}
                      label="Valor estimado"
                      value={licitacao.valor > 0 ? formatCurrency(licitacao.valor) : '-'}
                      highlight={highlightedFields.includes('valor')}
                    />
                    <InfoItem
                      icon={Today}
                      label="Data publicação"
                      value={
                        licitacao.publicacao_datetime
                          ? formatIsoDate(licitacao.publicacao_datetime)
                          : formatIsoDate(licitacao.createdAt)
                      }
                      highlight={highlightedFields.includes('publicacao_datetime')}
                    />
                    <InfoItem
                      icon={Event}
                      label="Data final para propostas"
                      value={
                        licitacao.fim_recebimento_propostas_datetime
                          ? formatIsoDate(licitacao.fim_recebimento_propostas_datetime)
                          : '-'
                      }
                      highlight={highlightedFields.includes('fim_recebimento_propostas_datetime')}
                    />
                    <InfoItem
                      icon={EventAvailable}
                      label="Data de abertura do pregão"
                      value={formatIsoDate(licitacao.abertura_datetime)}
                      highlight={highlightedFields.includes('abertura_datetime')}
                    />
                    <InfoItem
                      icon={Email}
                      label="Contato"
                      value={licitacao.telefoneContato || licitacao.emailContato || '-'}
                      highlight={highlightedFields.includes('telefoneContato')}
                    />
                    <InfoItem
                      icon={Dns}
                      label="Status"
                      value={currentStatus}
                      highlight={highlightedFields.includes('status')}
                    />
                    <InfoItem
                      icon={Description}
                      label="ID Licitou"
                      value={licitacao.id}
                      copyable
                      highlight={highlightedFields.includes('id')}
                    />
                  </Grid>
                </Grid>

                <Box mt={4}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Descrição
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {licitacao.objeto}
                  </Typography>
                </Box>
                <Box
                  className="licitacao-actions"
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  gap={isMobile ? 2 : 1}
                  justifyContent="space-between"
                  mt={2}
                >
                  <WhatsAppButton data-tour="whatsapp-button" licitacao={licitacao} />
                  <AddToCalendarButton data-tour="calendar-button" licitacao={licitacao} />
                  <Button
                    data-tour="participate-button"
                    variant="contained"
                    color="primary"
                    onClick={handleModalActions.openBidding}
                    startIcon={<AssignmentIcon />}
                  >
                    Participar da Licitação
                  </Button>
                  {licitacao.documents && licitacao.documents.length > 0 && (
                    <FeatureGate
                      featureId={FeatureId.DOC_VERIFICATION}
                      message="Faça upgrade para analisar documentos"
                    >
                      <CustomButton
                        data-tour="documents-button"
                        onClick={hasDocAccess ? handleModalActions.extractDocuments : undefined}
                        icon={<FilePresent />}
                        text="Documentos Necessários"
                        backgroundColor="#1976d2"
                        hoverColor="#1565c0"
                        sx={{ width: isMobile ? '100%' : 'auto' }}
                      />
                    </FeatureGate>
                  )}
                  <FeatureGate
                    featureId={FeatureId.AI_ANALYSIS}
                    message="Faça upgrade para usar a IA"
                  >
                    <Button
                      data-tour="ai-chat-button"
                      variant="contained"
                      color="warning"
                      startIcon={<ChatIcon />}
                      onClick={hasAiAccess ? handleModalActions.openChat : undefined}
                      sx={{
                        backgroundColor: theme.palette.warning.main,
                        width: isMobile ? '100%' : 'auto',
                        '&:hover': {
                          backgroundColor: theme.palette.warning.dark,
                        },
                      }}
                    >
                      Pergunte à LicitAI
                    </Button>
                  </FeatureGate>
                </Box>
                {showAnnotation && (
                  <Annotation
                    licitacaoId={licitacao.id}
                    isVisible={true}
                    type={isFollowing(licitacao.id) ? 'following' : 'favorite'}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <DetailsTabs licitacao={licitacao} />
          {licitacao.id_portal &&
            licitacao.portalData &&
            Object.keys(licitacao.portalData).length > 0 && (
              <Grid item xs={12} className="portal-details">
                <PortalDetails portalData={licitacao.portalData} licitacao={licitacao} />
              </Grid>
            )}
        </Grid>
      </Box>
      {hasAiAccess && (
        <AIChatModal
          open={isChatModalOpen}
          onClose={handleModalActions.closeChat}
          licitacao={licitacao}
        />
      )}
      {hasDocAccess && (
        <DocumentRequiredModal
          open={isDocumentModalOpen}
          onClose={handleModalActions.closeDocument}
          licitacao={licitacao}
        />
      )}
      <StepsModal
        open={isBiddingModalOpen}
        onClose={handleModalActions.closeBidding}
        licitacao={licitacao}
      />
    </>
  );
};

export default React.memo(LicitacaoDetails);
