import { features, getPlanHighlights, planDurations, plans } from '@constants/plans';
import CheckIcon from '@mui/icons-material/Check';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { usePaymentStore } from '@store/paymentStore';
import { DisabledPlan, DurationId, FeatureId, PlanId } from '@types';
import React, { useEffect, useState } from 'react';

interface PlanSelectionProps {
  onChoosePlan: (planId: PlanId, duration: DurationId) => void;
  onBack?: () => void;
  defaultPlanId?: PlanId | null;
  defaultDurationId?: DurationId | null;
  onCompare: () => void;
  disabledPlans?: DisabledPlan[];
  featuredPlanId?: PlanId;
}

const PlanSelection: React.FC<PlanSelectionProps> = ({
  onChoosePlan,
  onBack = () => {},
  defaultPlanId,
  defaultDurationId,
  onCompare,
  disabledPlans = [],
  featuredPlanId = PlanId.PREMIUM,
}) => {
  const { currentPlans } = usePaymentStore();
  const theme = useTheme();
  const [selectedDuration, setSelectedDuration] = useState<DurationId>(
    defaultDurationId || DurationId.MENSAL,
  );
  const [selectedPlan, setSelectedPlan] = useState<PlanId | null>(defaultPlanId || null);

  useEffect(() => {
    if (currentPlans.length > 0) {
      const currentPlan = currentPlans.find((plan) => plan.id === 'active');
      if (currentPlan) {
        const [planId, durationId] = currentPlan.id.split('_');
        setSelectedDuration(durationId as DurationId);
        setSelectedPlan(planId as PlanId);
      }
    }
  }, [currentPlans]);

  const handleDurationChange = (event: React.MouseEvent<HTMLElement>, newDuration: string) => {
    if (newDuration !== null) {
      setSelectedDuration(newDuration as DurationId);
    }
  };

  const handlePlanSelect = (planId: PlanId) => {
    setSelectedPlan(planId);
  };

  const handleContinueWithPlan = (planId: PlanId) => {
    handlePlanSelect(planId);
    onChoosePlan(planId, selectedDuration);
  };

  const handleBack = () => {
    onBack();
  };

  useEffect(() => {
    if (defaultPlanId) {
      setSelectedPlan(defaultPlanId);
    }
  }, [defaultPlanId]);

  const selectedDurationObj = planDurations.find((d) => d.id === selectedDuration);

  const isPlanDisabled = (planId: PlanId) => {
    return disabledPlans.some(
      (disabled) => disabled.planId === planId && disabled.durationId === selectedDuration,
    );
  };

  const getDisabledMessage = (planId: PlanId) => {
    const disabledPlan = disabledPlans.find(
      (disabled) => disabled.planId === planId && disabled.durationId === selectedDuration,
    );
    return disabledPlan?.message;
  };

  return (
    <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }} id="available-plans">
      <CardHeader title="Planos Disponíveis" />
      <CardContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ToggleButtonGroup
            value={selectedDuration}
            exclusive
            onChange={handleDurationChange}
            aria-label="Duração do plano"
            sx={{ mb: 2 }}
          >
            {planDurations.map((duration) => (
              <ToggleButton key={duration.id} value={duration.id} aria-label={duration.text}>
                {duration.switcherText}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Button
            startIcon={<CompareArrowsIcon />}
            onClick={() => onCompare()}
            variant="outlined"
            sx={{ mb: 3 }}
          >
            Comparar Todos os Planos
          </Button>

          <Box display="flex" justifyContent="center" flexWrap="wrap" gap={3}>
            {plans.map((plan) => {
              const isDisabled = isPlanDisabled(plan.id as PlanId);
              const disabledMessage = getDisabledMessage(plan.id as PlanId);
              const isFeatured = plan.id === featuredPlanId;
              const isSelected = plan.id === selectedPlan;
              const isInDevelopment = plan.inDevelopment;

              return (
                <Card
                  key={plan.id}
                  elevation={isFeatured ? 4 : 1}
                  sx={{
                    width: 280,
                    position: 'relative',
                    transform: isFeatured ? 'scale(1.02)' : 'none',
                    transition: 'all 0.3s ease',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    border:
                      theme.palette.mode === 'light'
                        ? isSelected
                          ? `2px solid ${theme.palette.primary.main}`
                          : isFeatured
                            ? `2px solid ${theme.palette.secondary.main}`
                            : 'none'
                        : isSelected
                          ? `2px solid ${theme.palette.primary.main}`
                          : isFeatured
                            ? `2px solid ${theme.palette.secondary.light}`
                            : 'none',
                    bgcolor: isDisabled ? 'action.disabledBackground' : 'background.paper',
                    color: 'text.primary',
                    opacity: isDisabled ? 0.7 : 1,
                    '&:hover': {
                      transform: isDisabled
                        ? 'none'
                        : isFeatured
                          ? 'scale(1.03) translateY(-4px)'
                          : 'translateY(-4px)',
                      boxShadow: isDisabled ? undefined : theme.shadows[8],
                    },
                  }}
                  onClick={() => {
                    if (!isDisabled) {
                      handlePlanSelect(plan.id as PlanId);
                    }
                  }}
                >
                  {isFeatured && !isDisabled && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        bgcolor: 'secondary.main',
                        color: 'secondary.contrastText',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        fontWeight: 'medium',
                      }}
                    >
                      Recomendado
                    </Box>
                  )}

                  {isInDevelopment && !isDisabled && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        bgcolor: 'warning.main',
                        color: 'warning.contrastText',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                      }}
                    >
                      Em breve
                    </Box>
                  )}

                  {isDisabled && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: isFeatured ? 50 : 16,
                        right: 16,
                        bgcolor: 'info.main',
                        color: 'info.contrastText',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                      }}
                    >
                      {disabledMessage}
                    </Box>
                  )}

                  <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {plan.name}
                    </Typography>

                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          textDecoration: 'line-through',
                          color: 'text.secondary',
                          mb: 1,
                        }}
                      >
                        {
                          plan.originalPrice[
                            planDurations.findIndex((d) => d.id === selectedDuration)
                          ]
                        }
                      </Typography>
                      <Typography variant="h4" component="div" gutterBottom>
                        {
                          plan.durationPrices[
                            planDurations.findIndex((d) => d.id === selectedDuration)
                          ]
                        }
                      </Typography>
                    </Box>

                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {selectedDurationObj?.text}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {plan.description}
                    </Typography>

                    <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
                      {plan.mainFeature}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      {getPlanHighlights(plan.id as PlanId).map((featureId: FeatureId) => {
                        const feature = features[featureId];
                        if (!feature) return null;

                        return (
                          <Box key={featureId} display="flex" alignItems="center" gap={1} mb={1}>
                            <CheckIcon fontSize="small" />
                            <Typography variant="body2">{feature.title}</Typography>
                          </Box>
                        );
                      })}
                      <Button
                        variant="text"
                        size="small"
                        color={
                          plan.id === featuredPlanId || selectedPlan === plan.id
                            ? 'secondary'
                            : 'primary'
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          onCompare();
                        }}
                        sx={{
                          mt: 1,
                          display: 'block',
                          mx: 'auto',
                          color: plan.id === featuredPlanId ? 'common.white' : 'inherit',
                          '&:hover': {
                            backgroundColor:
                              plan.id === featuredPlanId ? 'rgba(255, 255, 255, 0.1)' : undefined,
                          },
                        }}
                      >
                        Ver todos os recursos
                      </Button>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ p: 2, pt: 0 }}>
                    <Button
                      variant="contained"
                      color={isSelected ? 'primary' : 'secondary'}
                      fullWidth
                      disabled={isInDevelopment || isDisabled}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isDisabled) {
                          handleContinueWithPlan(plan.id as PlanId);
                        }
                      }}
                    >
                      {isInDevelopment
                        ? 'Em breve'
                        : isDisabled
                          ? disabledMessage
                          : 'Selecionar Plano'}
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </Box>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'flex-end',
          p: 2,
          gap: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          '& > button': {
            width: { xs: '100%', sm: 'auto' },
          },
        }}
      >
        <Button variant="outlined" sx={{ height: '40px' }} onClick={handleBack}>
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ height: '40px' }}
          disabled={!selectedPlan}
          onClick={() => handleContinueWithPlan(selectedPlan as PlanId)}
        >
          Continuar
        </Button>
      </CardActions>
    </Card>
  );
};

export default PlanSelection;
