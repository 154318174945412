import CustomButton from '@components/Input/CustomButton';
import { useAnalytics } from '@hooks/useAnalytics';
import { AddTask as AddTaskIcon } from '@mui/icons-material';
import { Button, SxProps, Theme } from '@mui/material';
import { useKanbanStore } from '@store/kanbanStore';
import { Licitacao } from '@types';
import { useState } from 'react';

interface AddToKanbanButtonProps {
  licitacao: Licitacao;
  onSuccess?: () => void;
  variant?: 'button' | 'custom';
  fullWidth?: boolean;
  dataTour?: string;
  sx?: SxProps<Theme>;
}

const AddToKanbanButton: React.FC<AddToKanbanButtonProps> = ({
  licitacao,
  onSuccess,
  variant = 'button',
  fullWidth = false,
  dataTour,
  sx = {},
}) => {
  const { trackEvent } = useAnalytics();
  const [isAddingToKanban, setIsAddingToKanban] = useState(false);
  const { addKanbanLicitacoes } = useKanbanStore();

  const handleAddToKanban = async () => {
    setIsAddingToKanban(true);
    try {
      await addKanbanLicitacoes('oportunidade', [licitacao.id], {});
      trackEvent('kanban_add_licitacoes', {
        licitacao_id: licitacao.id,
        portal: licitacao.portal,
      });
      onSuccess?.();
    } catch (error) {
      trackEvent('kanban_error', {
        action: 'add',
        licitacao_id: licitacao.id,
        error_message: error instanceof Error ? error.message : String(error),
      });
    } finally {
      setIsAddingToKanban(false);
    }
  };

  if (variant === 'custom') {
    return (
      <CustomButton
        dataTour={dataTour}
        icon={<AddTaskIcon />}
        text={isAddingToKanban ? 'Adicionando ao Kanban...' : 'Adicionar ao Kanban'}
        onClick={handleAddToKanban}
        backgroundColor="#2e7d32"
        hoverColor="#1b5e20"
        disabled={isAddingToKanban}
        sx={sx}
      />
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<AddTaskIcon />}
      onClick={handleAddToKanban}
      disabled={isAddingToKanban}
      fullWidth={fullWidth}
      sx={sx}
    >
      {isAddingToKanban ? 'Adicionando ao Kanban...' : 'Adicionar ao Kanban'}
    </Button>
  );
};

export default AddToKanbanButton;
