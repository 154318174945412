import MessageBox from '@components/Common/MessageBox';
import { useAnalytics } from '@hooks/useAnalytics';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';

interface PaymentMethodFormProps {
  onSubmit: (paymentMethodId: string) => void;
  onClose?: () => void;
  error?: string | null;
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({
  onSubmit,
  onClose = () => {},
  error: externalError,
}) => {
  const { trackEvent } = useAnalytics();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const theme = useTheme();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      trackEvent('payment_error', {
        action: 'form',
        error_type: 'stripe_not_loaded',
      });
      throw new Error('Stripe não está carregado. Por favor, tente novamente mais tarde.');
    }

    const cardNumber = elements.getElement(CardNumberElement);
    if (!cardNumber) {
      trackEvent('payment_error', {
        action: 'form',
        error_type: 'elements_not_found',
      });
      throw new Error('Elementos do cartão não encontrados. Por favor, recarregue a página.');
    }

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });

    if (stripeError) {
      trackEvent('payment_error', {
        action: 'create_payment_method',
        error_type: stripeError.type,
        error_code: stripeError.code,
        error_message: stripeError.message,
      });
      throw new Error(stripeError.message);
    }

    if (paymentMethod) {
      await onSubmit(paymentMethod.id);
      trackEvent('payment_method_created', {
        last_4: paymentMethod.card?.last4,
        brand: paymentMethod.card?.brand,
      });
    }
    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        '::placeholder': {
          color: theme.palette.text.secondary,
        },
        ':-webkit-autofill': {
          color: theme.palette.text.primary,
        },
      },
      invalid: {
        color: theme.palette.error.main,
        iconColor: theme.palette.error.main,
      },
    },
  };

  const cardElementWrapperStyle = {
    p: 1.5,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color']),
    '&:hover': {
      borderColor: theme.palette.action.hover,
    },
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <IconButton
          onClick={onClose}
          size="small"
          aria-label="fechar"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Número do Cartão
        </Typography>
        <Box sx={cardElementWrapperStyle}>
          <CardNumberElement options={cardElementOptions} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <Box sx={{ flex: '2 1 auto' }}>
          <Typography variant="subtitle1" gutterBottom>
            Data de Expiração
          </Typography>
          <Box sx={cardElementWrapperStyle}>
            <CardExpiryElement options={cardElementOptions} />
          </Box>
        </Box>
        <Box sx={{ flex: '1 1 auto' }}>
          <Typography variant="subtitle1" gutterBottom>
            CVC
          </Typography>
          <Box sx={cardElementWrapperStyle}>
            <CardCvcElement options={cardElementOptions} />
          </Box>
        </Box>
      </Box>
      {externalError && (
        <MessageBox variant="error" sx={{ mb: 2 }}>
          {externalError}
        </MessageBox>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!stripe || isProcessing}
        sx={{ mt: 2 }}
      >
        {isProcessing ? 'Processando...' : 'Salvar Método de Pagamento'}
      </Button>
    </form>
  );
};

export default PaymentMethodForm;
