import { Licitacao, PnetLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class PetronectPortalService extends BasePortalService {
  name = 'Petronect';
  logo = '/images/portals/petronect.png';
  portal = Portal.PNET;
  portalData: PnetLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as PnetLicitacao;
  }

  getBaseUrl(): string | null {
    return 'https://www.petronect.com.br';
  }

  getPortalUrl(): string | null {
    return this.getBaseUrl();
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return `${this.getBaseUrl()}/irj/go/km/docs/pccshrcontent/Site%20Content%20(Legacy)/Portal2018/pt/inscricao_opp.html`;
  }

  getRegisterUrl(): string | null {
    return `${this.getBaseUrl()}/irj/go/km/docs/pccshrcontent/Site%20Content%20(Legacy)/Portal2018/pt/identificacao.html`;
  }
}
