import EmptyStateBox from '@components/Common/EmptyStateBox';
import { TrendingUp } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useStatsStore } from '@store/statsStore';
import { TIMEZONE } from '@utils';
import { format, toZonedTime } from 'date-fns-tz';
import React, { useCallback, useEffect, useMemo } from 'react';

interface SearchWelcomeMessageProps {
  lastSearchParams: any;
  visible: boolean;
}

const SearchWelcomeMessage: React.FC<SearchWelcomeMessageProps> = ({
  lastSearchParams,
  visible,
}) => {
  const {
    dailyCounts,
    isLoading: isLoadingStats,
    totalLicitacoes: totalStats,
    averageDailyLicitacoes,
    fetchDailyCounts,
    hasFetched,
  } = useStatsStore();

  useEffect(() => {
    if (visible && !hasFetched) {
      fetchDailyCounts(30);
    }
  }, [visible, hasFetched, fetchDailyCounts]);

  const getTodayCount = useCallback(() => {
    if (!dailyCounts.length) return 0;
    const today = format(toZonedTime(new Date(), TIMEZONE), 'yyyy-MM-dd');
    return dailyCounts.find((count) => count.date === today)?.count || 0;
  }, [dailyCounts]);

  const todayCount = useMemo(() => {
    const count = getTodayCount();
    if (count === 0) {
      return (
        <>
          <Typography variant="h4" color="text.secondary" sx={{ opacity: 0.7 }}>
            Buscando...
          </Typography>
          <Typography variant="body2" color="text.secondary">
            atualizando licitações do dia
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography variant="h4" color="text.primary">
          {count}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          novas licitações publicadas
        </Typography>
      </>
    );
  }, [getTodayCount]);

  if (!visible) return null;

  return (
    <EmptyStateBox
      title={
        lastSearchParams
          ? 'Nenhuma licitação encontrada para sua busca'
          : 'Bem-vindo à Busca de Licitações'
      }
      sx={{ display: 'block' }}
    >
      {lastSearchParams ? (
        <>
          <Typography color="text.secondary" paragraph>
            Tente ajustar seus filtros ou fazer uma nova busca.
          </Typography>
          {!isLoadingStats && (
            <Box mt={3}>
              <Typography variant="subtitle1" color="primary.main" gutterBottom>
                Você sabia?
              </Typography>
              <Typography color="text.secondary" paragraph>
                Hoje já foram publicadas {getTodayCount()} novas licitações.
              </Typography>
              <Typography color="text.secondary" paragraph>
                Em média, {averageDailyLicitacoes} novas licitações são publicadas por dia em nosso
                sistema.
              </Typography>
              <Typography color="text.secondary">
                Nos últimos 30 dias, foram publicadas {totalStats} licitações.
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography color="text.secondary" paragraph>
            Use a barra de busca acima para encontrar licitações por palavras-chave, localização,
            modalidade e outros filtros.
          </Typography>
          <Typography color="text.secondary" paragraph>
            Você também pode usar suas preferências salvas ou buscas salvas para encontrar
            rapidamente as licitações mais relevantes para você.
          </Typography>
          {!isLoadingStats && (
            <Box mt={4}>
              <Box display="flex" alignItems="center" mb={2}>
                <TrendingUp color="primary" sx={{ mr: 2, fontSize: 24 }} />
                <Typography variant="h6" color="primary.main">
                  Panorama das Licitações
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Box p={2} bgcolor="action.hover" borderRadius={2}>
                    <Typography variant="subtitle1" color="primary.main" gutterBottom>
                      Hoje
                    </Typography>
                    {todayCount}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box p={2} bgcolor="action.hover" borderRadius={2}>
                    <Typography variant="subtitle1" color="primary.main" gutterBottom>
                      Média Diária
                    </Typography>
                    <Typography variant="h4" color="text.primary">
                      {averageDailyLicitacoes}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      licitações por dia
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box p={2} bgcolor="action.hover" borderRadius={2}>
                    <Typography variant="subtitle1" color="primary.main" gutterBottom>
                      Últimos 30 dias
                    </Typography>
                    <Typography variant="h4" color="text.primary">
                      {totalStats}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      licitações publicadas
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </EmptyStateBox>
  );
};

export default SearchWelcomeMessage;
