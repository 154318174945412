import { Chip, Stack, useMediaQuery, useTheme } from '@mui/material';
import { LicitacaoStatusFilter } from '@types';
import React from 'react';

interface StatusFilterProps {
  value: LicitacaoStatusFilter;
  onChange: (status: LicitacaoStatusFilter) => void;
  disabled?: boolean;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ value, onChange, disabled }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={1}
      sx={{
        minWidth: isMobile ? 80 : 'auto',
        '& .MuiChip-root': {
          width: isMobile ? '100%' : 'auto',
          justifyContent: 'center',
        },
      }}
    >
      <Chip
        label="Todas"
        color={value === 'all' ? 'primary' : 'default'}
        onClick={() => onChange('all')}
        variant={value === 'all' ? 'filled' : 'outlined'}
        disabled={disabled}
        sx={{ height: 32, fontSize: '0.875rem' }}
      />
      <Chip
        label="Em Aberto"
        color={value === 'open' ? 'primary' : 'default'}
        onClick={() => onChange('open')}
        variant={value === 'open' ? 'filled' : 'outlined'}
        disabled={disabled}
        sx={{ height: 32, fontSize: '0.875rem' }}
      />
      <Chip
        label="Encerradas"
        color={value === 'closed' ? 'primary' : 'default'}
        onClick={() => onChange('closed')}
        variant={value === 'closed' ? 'filled' : 'outlined'}
        disabled={disabled}
        sx={{ height: 32, fontSize: '0.875rem' }}
      />
    </Stack>
  );
};

export default StatusFilter;
