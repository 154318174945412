import { useNotification } from '@contexts/NotificationContext';
import { Search as SearchIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useAIStore } from '@store/aiStore';
import React, { useState } from 'react';

interface AiSearchModalProps {
  open: boolean;
  onClose: () => void;
  onSearchGenerated: (params: any) => void;
  context?: string;
  title?: string;
  description?: string;
  placeholder?: string;
  buttonText?: string;
}

const AiSearchModal: React.FC<AiSearchModalProps> = ({
  open,
  onClose,
  onSearchGenerated,
  context,
  title = 'Nova Busca com AI',
  description = 'Descreva o que você está procurando e a AI irá gerar uma busca otimizada baseada no seu perfil.',
  placeholder = 'Ex: Busco licitações de equipamentos médicos hospitalares em São Paulo, principalmente aparelhos de raio-x e ultrassom',
  buttonText = 'Criar',
}) => {
  const [aiSearchQuery, setAiSearchQuery] = useState('');
  const { generateSearch, isGeneratingSearch, error: aiError } = useAIStore();
  const { showNotification } = useNotification();

  const handleAiSearch = async () => {
    try {
      const response = await generateSearch(aiSearchQuery, context);
      onSearchGenerated(response);
      setAiSearchQuery('');
      onClose();

      if (response.explanation) {
        showNotification(response.explanation, 'info', {
          autoHideDuration: 15 * 1000,
        });
      }
    } catch (error) {
      console.error('Error in AI search:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>{description}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="O que você está procurando?"
          placeholder={placeholder}
          type="text"
          fullWidth
          multiline
          rows={4}
          value={aiSearchQuery}
          onChange={(e) => setAiSearchQuery(e.target.value)}
          error={!!aiError}
          helperText={aiError}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 'auto',
              padding: '12px',
              '& textarea': {
                padding: 0,
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancelar
        </Button>
        <Button
          onClick={handleAiSearch}
          variant="contained"
          color="primary"
          disabled={aiSearchQuery.length < 3 || isGeneratingSearch}
          startIcon={<SearchIcon />}
        >
          {isGeneratingSearch ? 'Gerando...' : buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AiSearchModal;
