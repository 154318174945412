// Define known storage keys and their value types
interface StorageKeyTypes {
  authToken?: string | null;
  currentProfileId?: string | null;
  subscriptionReminder?: boolean;
  onboardingReminder?: boolean;
  completedTours?: Record<string, boolean>;
}

interface StorageItem {
  value: any;
  expiresAt?: string;
  persistent?: boolean;
}

interface StorageData {
  [key: string]: StorageItem;
}

class StorageManager {
  private static instance: StorageManager;
  private readonly STORAGE_KEY = 'app-storage';
  private data: StorageData = {};

  private constructor() {
    this.loadFromStorage();
  }

  public static getInstance(): StorageManager {
    if (!StorageManager.instance) {
      StorageManager.instance = new StorageManager();
    }
    return StorageManager.instance;
  }

  private loadFromStorage(): void {
    try {
      const storedData = localStorage.getItem(this.STORAGE_KEY);
      if (storedData) {
        const parsedData = JSON.parse(storedData);

        // Only load persistent items
        this.data = Object.fromEntries(
          Object.entries(parsedData).filter(([_, value]) => (value as StorageItem).persistent),
        ) as StorageData;
      }
    } catch (error) {
      console.error('Failed to load from storage:', error);
      this.data = {};
    }
  }

  private saveToStorage(): void {
    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.data));
    } catch (error) {
      console.error('Failed to save to storage:', error);
    }
  }

  public set<K extends keyof StorageKeyTypes>(
    key: K,
    value: StorageKeyTypes[K],
    options: { expiresIn?: number; persistent?: boolean } = {},
  ): void {
    const { expiresIn, persistent = true } = options;
    const expiresAt = expiresIn ? new Date(Date.now() + expiresIn).toISOString() : undefined;

    this.data[key] = {
      value,
      expiresAt,
      persistent,
    };

    this.saveToStorage();
  }

  public get<K extends keyof StorageKeyTypes>(key: K): StorageKeyTypes[K] | undefined {
    const item = this.data[key];

    if (!item) return undefined;

    if (item.expiresAt) {
      const now = new Date();
      const expiresAt = new Date(item.expiresAt);

      if (now > expiresAt) {
        this.remove(key);
        return undefined;
      }
    }

    return item.value;
  }

  public remove(key: keyof StorageKeyTypes | string): void {
    delete this.data[key];
    this.saveToStorage();
  }

  public clear(): void {
    this.data = {};
    this.saveToStorage();
  }
}

// Export singleton instance
export const storageManager = StorageManager.getInstance();

// Usage example:
/*
// Set a value
storageManager.set('authToken', 'xyz123', { expiresIn: 3600000 }); // 1 hour

// Get a value
const token = storageManager.get('authToken');

// Remove a value
storageManager.remove('authToken');

// Clear all storage
storageManager.clear();
*/
