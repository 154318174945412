import FeedbackButton from '@components/Layout/FeedbackButton';
import Header from '@components/Layout/Header';
import WhatsAppButton from '@components/Layout/WhatsAppButton';
import SubscriptionReminder from '@components/Payment/SubscriptionReminder';
import OnboardingReminder from '@components/Tour/OnboardingReminder';
import { useAuth } from '@contexts/AuthContext';
import { getTourIdFromPath } from '@contexts/TourContext';
import { useLicitacaoAlerts } from '@hooks/useLicitacaoAlerts';
import { useTour } from '@hooks/useTour';
import { useTrialCheck } from '@hooks/useTrialCheck';
import { Box, CircularProgress, Container, useMediaQuery, useTheme } from '@mui/material';
import { ApiService } from '@services/api';
import { useProfileStore } from '@store/profileStore';
import { storageManager } from '@store/storageStore';
import { SubscriptionStatus } from '@types';
import { isOnboardingComplete, shouldRedirectToPayment } from '@utils/authChecks';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, isLoading } = useAuth();
  const { company, userPreferences, userData } = useProfileStore();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tourId = getTourIdFromPath(location.pathname);

  const [subscriptionDismissed, setSubscriptionDismissed] = React.useState(
    () => !!storageManager.get('subscriptionReminder'),
  );
  const [onboardingDismissed, setOnboardingDismissed] = React.useState(
    () => !!storageManager.get('onboardingReminder'),
  );

  useLicitacaoAlerts();

  useTour({
    tourId,
    onComplete: () => {
      // trackEvent('tour_complete');
    },
  });

  const handleTemporaryDismiss = () => {
    storageManager.set('subscriptionReminder', true, {
      expiresIn: 3 * 60 * 60 * 1000, // 3 hours
      persistent: true,
    });
    setSubscriptionDismissed(true);
  };

  const handleOnboardingTemporaryDismiss = () => {
    storageManager.set('onboardingReminder', true, {
      expiresIn: 3 * 60 * 60 * 1000, // 3 hours
      persistent: true,
    });
    setOnboardingDismissed(true);
  };

  if (!isLoading && !user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const opacity = isLoading ? 0.7 : 1;

  useTrialCheck();

  if (
    user &&
    shouldRedirectToPayment(userData, location.pathname, ApiService.getUnauthorizedCount())
  ) {
    return <Navigate to="/configuracoes/pagamento" replace />;
  }

  const showOnboardingReminder = !isOnboardingComplete(
    userData,
    Boolean(company?.cnaePrincipal?.id),
    Boolean(userPreferences?.companySummary),
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        opacity,
        transition: 'opacity 0.2s',
        position: 'relative',
        paddingBottom: isMobile ? '80px' : '100px',
        overflowX: isMobile ? 'hidden' : 'auto',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress size={20} />
        </Box>
      )}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          overflowX: isMobile ? 'hidden' : 'auto',
          paddingBottom: '20px',
        }}
      >
        <Box px={2}>
          <Header />
          {userData && (
            <Box mt={1}>
              {[SubscriptionStatus.PAST_DUE, SubscriptionStatus.TRIALING].includes(
                userData.subscriptionStatus,
              ) &&
                !subscriptionDismissed && (
                  <SubscriptionReminder onTemporaryDismiss={handleTemporaryDismiss} />
                )}
              {showOnboardingReminder && !onboardingDismissed && (
                <OnboardingReminder onTemporaryDismiss={handleOnboardingTemporaryDismiss} />
              )}
            </Box>
          )}
          <Box my={2}>{children}</Box>
        </Box>
      </Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: '80px',
          background: `linear-gradient(to top, ${theme.palette.background.default} 60%, transparent)`,
          zIndex: 9997,
          pointerEvents: 'none',
        }}
      />
      <Box sx={{ position: 'fixed', bottom: 20, left: 0, right: 0, zIndex: 9998 }}>
        <FeedbackButton position="bottom-left" />
        <WhatsAppButton position="bottom-right" />
      </Box>
    </Box>
  );
};

export default PrivateRoute;
