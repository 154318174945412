import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Box, Button, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface OnboardingReminderProps {
  onTemporaryDismiss?: () => void;
}

const OnboardingReminder: React.FC<OnboardingReminderProps> = ({ onTemporaryDismiss }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { company, userPreferences, userData } = useProfileStore();
  const navigate = useNavigate();

  const isCompanyInfoComplete = company && company.cnaePrincipal && company.cnaePrincipal.id;
  const isPreferencesComplete = userPreferences && userPreferences.companySummary;

  if (userData?.onboardingComplete) return null;

  const message = !isCompanyInfoComplete
    ? isMobile
      ? 'Complete as informações da empresa.'
      : 'Complete suas informações de empresa para melhorar sua experiência.'
    : !isPreferencesComplete
      ? isMobile
        ? 'Configure suas preferências.'
        : 'Configure suas preferências para obter recomendações personalizadas.'
      : '';

  const link = !isCompanyInfoComplete ? '/empresa/perfil?tour=1' : '/empresa/preferencias?tour=1';

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onTemporaryDismiss?.();
    navigate(link);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: 1,
      }}
    >
      <Box display="flex" gap={isMobile ? 1 : 2}>
        <Box display="flex" alignItems="center" gap={2} flex={1}>
          <InfoIcon sx={{ color: theme.palette.info.main }} />
          <Typography variant="body1">{message}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={1}
          alignItems={isMobile ? 'stretch' : 'center'}
        >
          <Button
            variant="text"
            size="small"
            onClick={onTemporaryDismiss}
            sx={{ mr: isMobile ? 0 : 1 }}
          >
            {isMobile ? 'Dispensar' : 'Lembrar mais tarde'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            size="small"
            fullWidth={isMobile}
          >
            Completar
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default OnboardingReminder;
