import { useProfileStore } from '@store/profileStore';
import { storageManager } from '@store/storageStore';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface ProfileContextType {
  currentProfileId: string | null;
  refreshProfile: () => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentProfileId, setCurrentProfileId } = useProfileStore();
  const [profileVersion, setProfileVersion] = useState(0);

  const refreshProfile = useCallback(() => {
    setProfileVersion((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (!currentProfileId) return;

    const storedProfileId = storageManager.get('currentProfileId');

    if (storedProfileId !== currentProfileId) {
      setCurrentProfileId(currentProfileId);
    }
  }, [currentProfileId, profileVersion]);

  return (
    <ProfileContext.Provider value={{ currentProfileId: currentProfileId || null, refreshProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};
