import { SubscriptionStatus, FirestoreUser as User } from '@types';
import { isBefore, parseISO } from 'date-fns';

const inativeStatueses: SubscriptionStatus[] = [
  SubscriptionStatus.PAST_DUE,
  SubscriptionStatus.CANCELED,
  SubscriptionStatus.PENDING,
  SubscriptionStatus.INACTIVE,
  SubscriptionStatus.INCOMPLETE,
  SubscriptionStatus.INCOMPLETE_EXPIRED,
  SubscriptionStatus.PAUSED,
  SubscriptionStatus.UNPAID,
];

export function shouldRedirectToWebsite(userData: User | null): boolean {
  if (!userData) return false;

  return (
    !userData.hasHadTrial &&
    !userData.hasHadActivePlan &&
    userData.role !== 'admin' &&
    userData.subscriptionStatus !== SubscriptionStatus.TRIALING &&
    (!userData.planId || !userData.durationId)
  );
}

export function isTrialExpired(userData: User | null): boolean {
  if (!userData) return false;

  return Boolean(
    userData.subscriptionStatus === SubscriptionStatus.TRIALING &&
      userData.gracePeriodEnd &&
      isBefore(parseISO(userData.gracePeriodEnd), new Date()),
  );
}

export function shouldRedirectToPayment(
  userData: User | null,
  currentPath: string,
  unauthorizedCount: number,
): boolean {
  if (!userData) return false;

  return (
    !isUserActive(userData) &&
    !currentPath.startsWith('/configuracoes/pagamento') &&
    unauthorizedCount === 0
  );
}

export function isOnboardingComplete(
  userData: User | null,
  hasCnaePrincipal: boolean,
  hasCompanySummary: boolean,
): boolean {
  if (!userData) return true;

  return userData &&
    (userData.isActive || userData.subscriptionStatus === SubscriptionStatus.TRIALING)
    ? Boolean(hasCnaePrincipal && hasCompanySummary)
    : true;
}

export const isUserActive = (userData: User | null): boolean => {
  // If no user data, return false
  if (!userData) return false;

  // Check if user is admin (admins are always active)
  if (userData.role === 'admin') return true;

  // Check basic active flag
  if (!userData.isActive) return false;

  // For active subscription status, user is active
  if (userData.subscriptionStatus === 'active') return true;

  // Check if user has inactive status
  const hasInactiveStatus = inativeStatueses.includes(userData.subscriptionStatus);
  if (hasInactiveStatus) return false;

  // Special handling for trial users
  if (userData.subscriptionStatus === SubscriptionStatus.TRIALING) {
    return !isTrialExpired(userData); // Active if trial not expired
  }

  // User is active if they reach here
  return true;
};
