import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useFollowingStore } from '@store/followingStore';
import { differenceInMinutes, parseISO } from 'date-fns';
import { useEffect, useRef } from 'react';

interface AlertConfig {
  minutesBefore: number[]; // Array of minutes to alert before [30, 15, 5]
  modalDuration: number; // How long the modal stays open in seconds
}

export const useLicitacaoAlerts = (
  config: AlertConfig = {
    minutesBefore: [30, 15, 5],
    modalDuration: 60,
  },
) => {
  const { following } = useFollowingStore();
  const { showNotification } = useNotification();
  const { trackEvent } = useAnalytics();
  const timersRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

  const clearAllTimers = () => {
    Object.values(timersRef.current).forEach((timer) => clearTimeout(timer));
    timersRef.current = {};
  };

  useEffect(() => {
    if (!following.length) return;

    clearAllTimers();

    following.forEach((item) => {
      if (!item.abertura_datetime) return;

      const openingTime = parseISO(item.abertura_datetime);
      const now = new Date();

      config.minutesBefore.forEach((minutes) => {
        const diffInMinutes = differenceInMinutes(openingTime, now);

        if (diffInMinutes > 0 && diffInMinutes <= minutes) {
          const timeUntilAlert = diffInMinutes * 60 * 1000;

          timersRef.current[`${item.licitacaoId}-${minutes}`] = setTimeout(() => {
            showNotification(
              `Licitação ${item.licitacaoId} abre em ${minutes} minutos!`,
              'warning',
              {
                autoHideDuration: config.modalDuration * 1000,
                action: {
                  label: 'Ver Detalhes',
                  onClick: () => {
                    window.open(`/licitacao/${item.licitacaoId}`, '_blank');
                  },
                },
              },
            );

            trackEvent('licitacao_alert', {
              action: 'show',
              licitacaoId: item.licitacaoId,
              minutesBefore: minutes,
            });
          }, timeUntilAlert);
        }
      });

      const timeUntilOpening = differenceInMinutes(openingTime, now) * 60 * 1000;
      if (timeUntilOpening > 0) {
        timersRef.current[`${item.licitacaoId}-opening`] = setTimeout(() => {
          showNotification(`A Licitação ${item.licitacaoId} está aberta!`, 'success', {
            autoHideDuration: config.modalDuration * 1000,
            action: {
              label: 'Participar Agora',
              onClick: () => {
                window.open(`/licitacao/${item.licitacaoId}`, '_blank');
              },
            },
          });

          trackEvent('licitacao_alert', {
            action: 'show',
            minutesBefore: 0,
            licitacaoId: item.licitacaoId,
          });
        }, timeUntilOpening);
      }
    });

    return () => clearAllTimers();
  }, [following]);
};
