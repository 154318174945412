import { Box, SxProps, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import ChipInput from './ChipInput';
import KeywordHelp from './KeywordHelp';

interface KeywordsProps {
  keywords: string[];
  onChange: (keywords: string[]) => void;
  placeholder?: string;
  maxKeywords?: number;
  onInputChange?: (inputValue: string) => void;
  sx?: SxProps;
  error?: boolean;
  variant?: 'default' | 'negative';
  customStyle?: {
    borderColor?: string;
    hoverBorderColor?: string;
    focusBorderColor?: string;
  };
  className?: string;
  onChipRemove?: (chip: string, index: number) => void;
  onEmptyEnter?: () => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  addHelper?: boolean;
  label?: string;
  helperText?: string;
  showChips?: boolean;
}

// Variation estimation constants
const VARIATION_COUNTS = {
  SIMPLE: 1, // No patterns
  SINGLE_PATTERN: 8, // Has one [] or ()
  COMPLEX: 16, // Has multiple patterns
};

const MAX_TOTAL_VARIATIONS = 250; // Matching backend MAX_EXPANDED_KEYWORDS

function estimateVariations(keyword: string): number {
  const hasSquareBrackets = keyword.includes('[');
  const hasParentheses = keyword.includes('(');

  if (hasSquareBrackets && hasParentheses) {
    return VARIATION_COUNTS.COMPLEX;
  } else if (hasSquareBrackets || hasParentheses) {
    return VARIATION_COUNTS.SINGLE_PATTERN;
  }
  return VARIATION_COUNTS.SIMPLE;
}

const Keywords: React.FC<KeywordsProps> = ({
  keywords,
  onChange,
  placeholder = 'Digite uma palavra-chave e pressione Enter',
  onInputChange = () => {},
  sx = {},
  error = false,
  variant = 'default',
  customStyle,
  className,
  onChipRemove,
  onEmptyEnter,
  onFocus,
  onKeyDown,
  addHelper = true,
  label = 'Palavras-chave',
  helperText,
  showChips = true,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { totalVariations, remainingVariations } = useMemo(() => {
    const total = keywords.reduce((sum, kw) => sum + estimateVariations(kw), 0);
    return {
      totalVariations: total,
      remainingVariations: MAX_TOTAL_VARIATIONS - total,
    };
  }, [keywords]);

  const handleChange = (newKeywords: string[]) => {
    // Calculate variations for the new keywords
    let runningTotal = 0;
    const limitedKeywords = newKeywords.filter((kw) => {
      const variations = estimateVariations(kw);
      if (runningTotal + variations <= MAX_TOTAL_VARIATIONS) {
        runningTotal += variations;
        return true;
      }
      return false;
    });

    onChange(limitedKeywords);
  };

  const getPlaceholder = () => {
    if (totalVariations >= MAX_TOTAL_VARIATIONS) {
      return 'Limite máximo de variações atingido';
    }
    if (remainingVariations < VARIATION_COUNTS.COMPLEX) {
      return 'Apenas palavras simples podem ser adicionadas';
    }
    return isMobile ? 'Digite uma palavra-chave' : placeholder;
  };

  const getCustomStyles = () => {
    if (variant === 'negative') {
      return {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: theme.palette.error.main,
          },
          '&:hover fieldset': {
            borderColor: theme.palette.error.light,
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.error.dark,
          },
        },
      };
    }
    if (customStyle) {
      return {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: customStyle.borderColor,
          },
          '&:hover fieldset': {
            borderColor: customStyle.hoverBorderColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: customStyle.focusBorderColor,
          },
        },
      };
    }
    return {};
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 1,
        width: '100%',
      }}
      className={className}
    >
      <ChipInput
        value={keywords}
        onChangeValue={handleChange}
        placeholder={getPlaceholder()}
        onInputChange={onInputChange}
        onChipRemove={onChipRemove}
        onEmptyEnter={onEmptyEnter}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        label={label}
        helperText={helperText}
        sx={{
          ...sx,
          ...getCustomStyles(),
          flex: 1,
          '& .MuiOutlinedInput-root': {
            minHeight: '40px',
          },
        }}
        error={error || totalVariations >= MAX_TOTAL_VARIATIONS}
        showChips={showChips}
      />
      {addHelper && !isMobile && (
        <KeywordHelp color={error ? theme.palette.error.main : theme.palette.primary.main} />
      )}
    </Box>
  );
};

export default Keywords;
