import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

interface ItemsPerPageSelectProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const ItemsPerPageSelect: React.FC<ItemsPerPageSelectProps> = ({ value, onChange, disabled }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControl size="small" sx={{ minWidth: isMobile ? 40 : 160 }}>
      <Select
        value={value}
        onChange={handleChange}
        disabled={disabled}
        variant="outlined"
        sx={{ height: 32 }}
      >
        <MenuItem value={10}>{isMobile ? '10' : '10 por página'}</MenuItem>
        <MenuItem value={20}>{isMobile ? '20' : '20 por página'}</MenuItem>
        <MenuItem value={50}>{isMobile ? '50' : '50 por página'}</MenuItem>
        <MenuItem value={100}>{isMobile ? '100' : '100 por página'}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ItemsPerPageSelect;
