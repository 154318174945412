import { Theme } from '@mui/material/styles';

export const getGlobalStyles = (theme: Theme) => ({
  body: {
    minHeight: '100vh',
    background: theme.palette.background.default,
    '&::before': {
      content: '""',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage:
        theme.palette.mode === 'dark'
          ? 'radial-gradient(circle at 50% 50%, rgba(255,255,255,0.05) 0%, transparent 70%)'
          : 'none',
      opacity: 0.6,
      pointerEvents: 'none',
      zIndex: -1,
    },
  },
});
