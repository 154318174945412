import FeatureGate from '@components/Feature/FeatureGate';
import { useProfile } from '@contexts/ProfileContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Business as BusinessIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { FeatureId, UserProfile } from '@types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MIN_PROFILE_NAME_LENGTH = 3;

interface ProfileSelectProps {}

const ProfileSelect: React.FC<ProfileSelectProps> = () => {
  const theme = useTheme();
  const {
    profiles,
    currentProfileId,
    setCurrentProfile,
    addProfile,
    updateProfile,
    deleteProfile,
  } = useProfileStore();
  const { refreshProfile } = useProfile();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newProfileName, setNewProfileName] = useState('');
  const [isAddingProfile, setIsAddingProfile] = useState(false);
  const [editingProfileId, setEditingProfileId] = useState<string | null>(null);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    currentProfileId || null,
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [profileToSwitch, setProfileToSwitch] = useState<string | null>(null);
  const [dialogAction, setDialogAction] = useState<'switch' | 'create'>('switch');
  const { trackEvent } = useAnalytics();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState<string | null>(null);
  const [deleteConfirmText, setDeleteConfirmText] = useState('');
  const { hasAccess: hasMultiProfileAccess, reason } = useFeatureAccess(
    FeatureId.MULTI_CNPJ,
    profiles.length,
  );
  const navigate = useNavigate();

  const isActive = (path: string) => location.pathname.startsWith(path);

  useEffect(() => {
    setSelectedProfileId(currentProfileId || null);
  }, [currentProfileId]);

  const currentProfile = useMemo(
    () => profiles.find((profile) => profile.id === selectedProfileId),
    [profiles, selectedProfileId],
  );

  const isValidProfileName = useCallback((name: string) => {
    return name.trim().length >= MIN_PROFILE_NAME_LENGTH;
  }, []);

  const handleProfileMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [profiles.length, currentProfileId],
  );

  const handleProfileMenuClose = useCallback(() => {
    setAnchorEl(null);
    setIsAddingProfile(false);
    setEditingProfileId(null);
    setNewProfileName('');
  }, [isAddingProfile, editingProfileId]);

  const handleProfileChange = useCallback(
    (profileId: string) => {
      if (profileId !== currentProfileId) {
        setProfileToSwitch(profileId);
        setConfirmDialogOpen(true);
      } else {
        handleProfileMenuClose();
      }
    },
    [currentProfileId, handleProfileMenuClose],
  );

  const handleAddProfileClick = useCallback(() => {
    if (!hasMultiProfileAccess) {
      return;
    }
    setIsAddingProfile(true);
    setNewProfileName('');
  }, [hasMultiProfileAccess, reason]);

  const handleNewProfileNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewProfileName(event.target.value);
  }, []);

  const handleNewProfileSubmit = useCallback(() => {
    if (isValidProfileName(newProfileName)) {
      setDialogAction('create');
      setConfirmDialogOpen(true);
    }
  }, [newProfileName, isValidProfileName]);

  const handleEditProfileClick = useCallback((profileId: string, profileName: string) => {
    setEditingProfileId(profileId);
    setNewProfileName(profileName);
  }, []);

  const handleEditProfileSubmit = useCallback(() => {
    if (editingProfileId && isValidProfileName(newProfileName)) {
      updateProfile(editingProfileId, { name: newProfileName.trim() });
      setEditingProfileId(null);
      setNewProfileName('');
    }
  }, [editingProfileId, newProfileName, profiles, isValidProfileName, updateProfile]);

  const handleConfirmAction = useCallback(() => {
    if (dialogAction === 'switch' && profileToSwitch) {
      setCurrentProfile(profileToSwitch);
      refreshProfile();
    } else if (dialogAction === 'create') {
      const newProfile = { id: Date.now().toString(), name: newProfileName.trim() };
      addProfile(newProfile as UserProfile);
      trackEvent('profile_create', { success: true });
      refreshProfile();
    }
    setConfirmDialogOpen(false);
    handleProfileMenuClose();
  }, [
    dialogAction,
    profileToSwitch,
    currentProfileId,
    profiles.length,
    newProfileName,
    setCurrentProfile,
    refreshProfile,
    addProfile,
    handleProfileMenuClose,
  ]);

  const handleCancelAction = useCallback(() => {
    setConfirmDialogOpen(false);
    setProfileToSwitch(null);
    if (dialogAction === 'create') {
      setIsAddingProfile(false);
    }
    setNewProfileName('');
  }, [dialogAction, profileToSwitch, newProfileName]);

  const handleDeleteClick = useCallback((e: React.MouseEvent, profileId: string) => {
    e.stopPropagation();
    if (profileId === 'default') return;
    setProfileToDelete(profileId);
    setShowDeleteDialog(true);
  }, []);

  const handleDeleteProfile = async () => {
    if (!profileToDelete) return;

    try {
      await deleteProfile(profileToDelete);
      trackEvent('profile_delete', { success: true });
      handleProfileMenuClose();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Erro desconhecido';
      trackEvent('profile_delete_error', {
        success: false,
        error: errorMessage,
      });
    } finally {
      setShowDeleteDialog(false);
      setProfileToDelete(null);
    }
  };

  const renderProfileNameInput = useCallback(
    (isEditing: boolean) => (
      <Box display="flex" alignItems="center" sx={{ p: 1, width: '100%' }}>
        <TextField
          value={newProfileName}
          onChange={handleNewProfileNameChange}
          placeholder={isEditing ? 'Nome do perfil' : 'Nome do novo perfil'}
          size="small"
          autoFocus
          fullWidth
          error={newProfileName.length > 0 && !isValidProfileName(newProfileName)}
          helperText={
            newProfileName.length > 0 && !isValidProfileName(newProfileName)
              ? `Mínimo de ${MIN_PROFILE_NAME_LENGTH} caracteres`
              : null
          }
        />
        <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
          <IconButton
            onClick={isEditing ? handleEditProfileSubmit : handleNewProfileSubmit}
            size="small"
            color={isValidProfileName(newProfileName) ? 'success' : 'inherit'}
            disabled={!isValidProfileName(newProfileName)}
          >
            <CheckIcon color={isValidProfileName(newProfileName) ? 'success' : 'disabled'} />
          </IconButton>
          {isEditing && (
            <IconButton
              onClick={() => {
                setEditingProfileId(null);
                setNewProfileName('');
              }}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    ),
    [
      newProfileName,
      handleNewProfileNameChange,
      isValidProfileName,
      handleEditProfileSubmit,
      handleNewProfileSubmit,
    ],
  );

  return (
    <Box sx={{ position: 'relative', minWidth: isMobile ? 'auto' : 120, mr: 2 }}>
      <Button
        onClick={handleProfileMenuOpen}
        endIcon={!isMobile && <ArrowDropDownIcon />}
        startIcon={isMobile && <BusinessIcon />}
        sx={{
          textTransform: 'none',
          ...(isMobile && {
            minWidth: 0,
            p: 1,
            '& .MuiButton-startIcon': {
              mr: 0,
            },
          }),
        }}
      >
        {!isMobile && (currentProfile?.name || 'Selecionar Perfil')}
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleProfileMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isMobile ? 'center' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'center' : 'left',
        }}
      >
        {isMobile && (
          <Box
            sx={{
              p: 2,
              borderBottom: 1,
              borderColor: 'divider',
              bgcolor: 'background.default',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
              {currentProfile?.name || 'Selecionar Perfil'}
            </Typography>
          </Box>
        )}

        <List sx={{ minWidth: 200 }}>
          {profiles.map((profile) => (
            <ListItem key={profile.id} disablePadding>
              {editingProfileId === profile.id ? (
                renderProfileNameInput(true)
              ) : (
                <ListItemButton
                  onClick={() => handleProfileChange(profile.id)}
                  selected={profile.id === selectedProfileId}
                >
                  <ListItemText primary={profile.name} />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {profile.id !== 'default' && profiles.length > 1 && (
                      <IconButton
                        onClick={(e) => {
                          if (profiles.length === 1) return;
                          handleDeleteClick(e, profile.id);
                        }}
                        size="small"
                        color="error"
                        disabled={profiles.length === 1}
                      >
                        <DeleteIcon fontSize="small" color="error" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditProfileClick(profile.id, profile.name);
                      }}
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItemButton>
              )}
            </ListItem>
          ))}
          <ListItem disablePadding>
            {isAddingProfile ? (
              renderProfileNameInput(false)
            ) : (
              <Box sx={{ width: '100%' }}>
                <FeatureGate featureId={FeatureId.MULTI_CNPJ} message="Limite de perfis atingido">
                  <ListItemButton
                    onClick={hasMultiProfileAccess ? handleAddProfileClick : undefined}
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Adicionar Perfil" />
                  </ListItemButton>
                </FeatureGate>
              </Box>
            )}
          </ListItem>
          <Divider sx={{ my: 1 }} />
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/empresa');
                handleProfileMenuClose();
              }}
              selected={isActive('/empresa')}
            >
              <ListItemIcon>
                <BusinessIcon color={isActive('/empresa') ? 'info' : undefined} />
              </ListItemIcon>
              <ListItemText primary="Configurações da Empresa" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <Dialog open={confirmDialogOpen} onClose={handleCancelAction}>
        <DialogTitle>
          {dialogAction === 'switch'
            ? 'Confirmar Mudança de Perfil'
            : 'Confirmar Criação de Perfil'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {dialogAction === 'switch'
              ? 'Ao mudar de perfil, você terá acesso a diferentes:'
              : 'Ao criar um novo perfil, você terá um espaço separado para gerenciar:'}
          </Typography>
          <ul>
            <li>Favoritos</li>
            <li>Acompanhadas</li>
            <li>Boletins</li>
            <li>Recomendações</li>
            <li>Preferências</li>
            <li>Informações da empresa</li>
            <li>Buscas salvas</li>
          </ul>
          <Typography>Deseja continuar?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelAction} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
          setDeleteConfirmText('');
        }}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza que deseja excluir este perfil? Esta ação não pode ser desfeita e todos os
            dados relacionados serão perdidos:
            <ul>
              <li>Licitações favoritas</li>
              <li>Licitações em acompanhamento</li>
              <li>Quadro Kanban</li>
              <li>Boletins</li>
              <li>Documentos</li>
              <li>Buscas salvas</li>
              <li>Notificações</li>
            </ul>
          </Typography>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Digite <strong>excluir</strong> para confirmar:
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={deleteConfirmText}
            onChange={(e) => setDeleteConfirmText(e.target.value)}
            placeholder="excluir"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowDeleteDialog(false);
              setDeleteConfirmText('');
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteProfile}
            color="error"
            variant="contained"
            disabled={deleteConfirmText.toLowerCase() !== 'excluir'}
          >
            Excluir Perfil
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfileSelect;
