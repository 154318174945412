import CustomButton from '@components/Input/CustomButton';
import { COMPANY_WHATSAPP, WHATSAPP_DEFAULT_MESSAGE } from '@constants/index';
import { useAnalytics } from '@hooks/useAnalytics';
import { WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface WhatsAppButtonProps {
  position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ position = 'bottom-right' }) => {
  const { trackEvent } = useAnalytics();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    trackEvent('whatsapp_button', {
      action: 'click',
    });
  };

  const getPositionStyle = () => {
    if (isMobile) {
      return {
        bottom: position.startsWith('bottom') ? '20px' : undefined,
        top: position.startsWith('top') ? '20px' : undefined,
        left: position.endsWith('left') ? '16px' : undefined,
        right: position.endsWith('right') ? '16px' : undefined,
        minWidth: 'unset',
        width: 'auto',
        borderRadius: '50%',
        padding: '12px',
      };
    }

    switch (position) {
      case 'bottom-left':
        return { bottom: '20px', left: '20px' };
      case 'top-left':
        return { top: '20px', left: '20px' };
      case 'top-right':
        return { top: '20px', right: '20px' };
      case 'bottom-right':
      default:
        return { bottom: '20px', right: '20px' };
    }
  };

  const encodedMessage = encodeURIComponent(WHATSAPP_DEFAULT_MESSAGE);
  const whatsappUrl = `https://wa.me/${COMPANY_WHATSAPP.replace(/\D/g, '')}?text=${encodedMessage}`;

  return (
    <CustomButton
      icon={<WhatsAppIcon sx={{ fontSize: isMobile ? '1.5rem' : '1.25rem' }} />}
      text={isMobile ? '' : 'Fale Conosco'}
      backgroundColor="#25D366"
      hoverColor="#1da750"
      href={whatsappUrl}
      onClick={handleClick}
      sx={{
        position: 'fixed',
        zIndex: 9999,
        ...getPositionStyle(),
        ...(isMobile && {
          padding: '8px',
          minWidth: 'unset',
          '& svg': {
            margin: 0,
          },
        }),
      }}
    />
  );
};

export default WhatsAppButton;
