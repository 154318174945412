import Api from '@services/api';
import {
  AiProfile,
  LicitacaoStatusFilter,
  LicitacaoViewFormat,
  RecommendedLicitacao,
  RecommendedLicitacoes,
} from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface RecommendedState {
  recommended: RecommendedLicitacoes[];
  recommendedList: RecommendedLicitacao[];
  recommendedRealtimeList: RecommendedLicitacao[];
  aiProfile: AiProfile | null;
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  currentRealtimePage: number;
  totalRecommended: number;
  totalPages: number;
  totalRealtimePages: number;
  totalRealtimeRecommended: number;
  itemsPerPage: number;
  viewFormat: LicitacaoViewFormat;
  lastFetch: number | null;
  lastFetchList: number | null;
  lastFetchAiProfile: number | null;
  lastFetchRealtime: number | null;
  currentSearchQuery: string | null;
  fetchRecommended: (force?: boolean) => Promise<void>;
  fetchRecommendedLicitacoes: (
    page?: number,
    forceRefresh?: boolean,
    status?: LicitacaoStatusFilter,
    itemsPerPage?: number,
  ) => Promise<void>;
  fetchRealtimeRecommended: (
    page?: number,
    searchQueryName?: string,
    forceRefresh?: boolean,
  ) => Promise<void>;
  fetchAiProfile: (force?: boolean) => Promise<void>;
  resetStore: () => void;
  setCurrentPage: (page: number) => void;
  setItemsPerPage: (perPage: number) => void;
  setViewFormat: (format: LicitacaoViewFormat) => void;
  setCurrentSearchQuery: (searchQueryName: string | null) => void;
  status: LicitacaoStatusFilter;
  setStatus: (status: LicitacaoStatusFilter) => void;
  isRealtimeLoading: boolean;
}

export const useRecommendedStore = create<RecommendedState>()(
  persist(
    (set, get) => ({
      recommended: [],
      recommendedList: [],
      recommendedRealtimeList: [],
      isLoading: false,
      error: null,
      currentPage: 1,
      currentRealtimePage: 1,
      totalRecommended: 0,
      totalPages: 0,
      totalRealtimePages: 0,
      totalRealtimeRecommended: 0,
      itemsPerPage: 20,
      viewFormat: 'list',
      lastFetch: null,
      lastFetchAiProfile: null,
      lastFetchList: null,
      lastFetchRealtime: null,
      currentSearchQuery: null,
      aiProfile: null,
      status: 'open',
      isRealtimeLoading: false,

      fetchAiProfile: async (force = false) => {
        const now = Date.now();
        const lastFetch = get().lastFetchAiProfile;
        const CACHE_DURATION = 5 * 60 * 1000;

        if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const profile = await Api.getAiProfile();
          set({
            aiProfile: profile,
            isLoading: false,
            lastFetchAiProfile: now,
          });
        } catch (error) {
          console.error('Error fetching AI profile:', error);
          set({
            error: 'Erro ao buscar perfil de recomendações',
            isLoading: false,
            lastFetchAiProfile: null,
          });
        }
      },

      fetchRealtimeRecommended: async (
        page: number = 1,
        searchQueryName: string | null = null,
        forceRefresh = false,
      ) => {
        const now = Date.now();
        const lastFetchRealtime = get().lastFetchRealtime;
        const currentSearchQuery = get().currentSearchQuery;
        const CACHE_DURATION = 5 * 60 * 1000;

        if (
          !forceRefresh &&
          page === 1 &&
          lastFetchRealtime &&
          now - lastFetchRealtime < CACHE_DURATION &&
          currentSearchQuery === searchQueryName
        ) {
          return;
        }

        set({ isRealtimeLoading: true, error: null });
        try {
          const response = await Api.getRealtimeRecommended(
            page,
            get().itemsPerPage,
            searchQueryName || undefined,
          );
          set({
            recommendedRealtimeList: response.licitacoes || [],
            currentRealtimePage: page,
            totalRealtimePages: response.paginas || 0,
            totalRealtimeRecommended: response.totalLicitacoes || 0,
            isRealtimeLoading: false,
            lastFetchRealtime: now,
            currentSearchQuery: searchQueryName || null,
            error: null,
          });
        } catch (error) {
          console.error('Error fetching realtime recommendations:', error);
          set({
            recommendedRealtimeList: [],
            error: 'Erro ao buscar licitações recomendadas',
            isRealtimeLoading: false,
            totalRealtimePages: 0,
            totalRealtimeRecommended: 0,
            lastFetchRealtime: now,
            currentSearchQuery: null,
          });
        }
      },

      fetchRecommended: async (force = false) => {
        const now = Date.now();
        const lastFetch = get().lastFetch;
        const CACHE_DURATION = 5 * 60 * 1000;

        if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getRecommended();
          set({
            recommended: response.recommended,
            totalRecommended: response.total,
            isLoading: false,
            lastFetch: now,
            lastFetchList: null,
          });
        } catch (error: any) {
          console.error('Error fetching recommended licitacoes:', error);
          const errorMessage = error.message || 'Erro ao buscar licitações recomendadas';
          set({ error: errorMessage, isLoading: false, lastFetch: null });
          throw error;
        }
      },

      fetchRecommendedLicitacoes: async (
        page: number = 1,
        forceRefresh = false,
        status: LicitacaoStatusFilter | undefined = undefined,
        itemsPerPage: number | undefined = undefined,
      ) => {
        const now = Date.now();
        const lastFetchList = get().lastFetchList;
        const CACHE_DURATION = 5 * 60 * 1000;

        const shouldSaveCache =
          (!status || status === get().status) &&
          (!itemsPerPage || itemsPerPage === get().itemsPerPage);
        if (
          !forceRefresh &&
          page === 1 &&
          lastFetchList &&
          now - lastFetchList < CACHE_DURATION &&
          shouldSaveCache
        ) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getRecommendedList(
            page,
            itemsPerPage ? itemsPerPage : get().itemsPerPage,
            status ? status : get().status,
          );
          set({
            recommendedList: response.licitacoes || [],
            currentPage: page,
            totalPages: response.paginas || 0,
            totalRecommended: response.totalLicitacoes || 0,
            isLoading: false,
            lastFetchList: shouldSaveCache ? now : null,
            error: null,
          });
        } catch (error) {
          console.error('Error fetching recommended licitacoes:', error);
          set({
            recommendedList: [],
            error: 'Erro ao buscar licitações recomendadas',
            isLoading: false,
            totalPages: 0,
            totalRecommended: 0,
            lastFetchList: shouldSaveCache ? now : null,
          });
        }
      },

      resetStore: () =>
        set({
          recommended: [],
          recommendedList: [],
          aiProfile: null,
          isLoading: false,
          error: null,
          currentPage: 1,
          currentRealtimePage: 1,
          totalRecommended: 0,
          totalPages: 0,
          totalRealtimePages: 0,
          totalRealtimeRecommended: 0,
          itemsPerPage: 20,
          currentSearchQuery: null,
          isRealtimeLoading: false,
          lastFetchRealtime: null,
          lastFetchList: null,
          lastFetch: null,
          status: 'open',
        }),

      setCurrentPage: (page: number) => set({ currentPage: page }),

      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage });
        set({ currentPage: 1 });
        get().fetchRecommendedLicitacoes(1, true);
      },

      setViewFormat: (format: LicitacaoViewFormat) => set({ viewFormat: format }),

      setCurrentSearchQuery: (searchQueryName: string | null) => {
        set({ currentSearchQuery: searchQueryName });
        if (searchQueryName) {
          get().fetchRealtimeRecommended(1, searchQueryName, true);
        } else {
          get().fetchRecommendedLicitacoes(1, true);
        }
      },

      setStatus: (status: LicitacaoStatusFilter) => {
        set({ status, lastFetchList: null, currentPage: 1 });
        get().fetchRecommendedLicitacoes(1, true);
      },
    }),
    {
      name: 'recommended-storage',
      partialize: (state) => ({
        itemsPerPage: state.itemsPerPage,
        viewFormat: state.viewFormat,
        status: state.status,
      }),
    },
  ),
);
