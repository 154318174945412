import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { alpha, SxProps, Theme } from '@mui/material/styles';
import React from 'react';

type MessageVariant = 'info' | 'warning' | 'error' | 'success';

interface MessageBoxProps {
  variant?: MessageVariant;
  title?: string;
  children: React.ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const MessageBox: React.FC<MessageBoxProps> = ({
  variant = 'info',
  title,
  children,
  className,
  sx = {},
  onClick = undefined,
}) => {
  const variantConfig = {
    info: {
      icon: InfoIcon,
      color: 'info.main',
      bgColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? alpha(theme.palette.info.main, 0.1) : 'info.lighter',
    },
    warning: {
      icon: WarningIcon,
      color: 'warning.main',
      bgColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? alpha(theme.palette.warning.main, 0.1) : 'warning.lighter',
    },
    error: {
      icon: ErrorIcon,
      color: 'error.main',
      bgColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? alpha(theme.palette.error.main, 0.1) : 'error.lighter',
    },
    success: {
      icon: CheckCircleIcon,
      color: 'success.main',
      bgColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? alpha(theme.palette.success.main, 0.1) : 'success.lighter',
    },
  };

  const { icon: Icon, color, bgColor } = variantConfig[variant];

  return (
    <Paper
      elevation={0}
      className={className}
      sx={{
        p: 2,
        borderRadius: 1,
        border: 1,
        borderColor: color,
        bgcolor: bgColor,
        ...sx,
      }}
      onClick={(e) => {
        onClick?.(e);
      }}
    >
      <Box display="flex" alignItems={title ? 'flex-start' : 'center'} gap={2}>
        <Icon sx={{ color, mt: title ? 0.5 : 0 }} />
        <Box flex={1}>
          {title && (
            <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
              {title}
            </Typography>
          )}
          {typeof children === 'string' ? (
            <Typography variant="body2" color="text.secondary">
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default MessageBox;
