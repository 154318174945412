import { FiebLicitacao, Licitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class FiebPortalService extends BasePortalService {
  name = 'FIEB';
  logo = '/images/portals/fieb.png';
  portal = Portal.FIEB;
  portalData: FiebLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as FiebLicitacao;
  }

  getBaseUrl(): string | null {
    return 'https://compras.fieb.org.br';
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.getPortalUrl();
  }

  getFollowUpUrl(): string | null {
    return this.getPortalUrl();
  }

  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return `https://login-public.fieb.org.br/fieb/auth/login`;
  }

  getRegisterUrl(): string | null {
    return 'https://compras.fieb.org.br/Empresa/CadastroExterno/ApresentacaoCadastro';
  }
}
