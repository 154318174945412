import EmptyStateBox from '@components/Common/EmptyStateBox';
import FeatureLimitAlert from '@components/Feature/FeatureLimitAlert';
import ListControls from '@components/Layout/ListControls';
import Pagination, { PaginationInfo } from '@components/Layout/Pagination';
import StatusFilter from '@components/Layout/StatusFilter';
import Recommendations from '@components/Recommended/Recommendations';
import LicitacaoCard from '@components/Search/LicitacaoCard';
import LicitacaoCardSkeleton from '@components/Search/LicitacaoCardSkeleton';
import LicitacaoColumnCard from '@components/Search/LicitacaoColumnCard';
import LicitacaoColumnCardSkeleton from '@components/Search/LicitacaoColumnCardSkeleton';
import { useNotification } from '@contexts/NotificationContext';
import { useProfile } from '@contexts/ProfileContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { useRecommendedStore } from '@store/recommendedStore';
import { FeatureId } from '@types';
import { scrollToTop } from '@utils/scroll';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

const RecommendedLicitacoes: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    recommendedList,
    fetchRecommendedLicitacoes,
    isLoading,
    error,
    currentPage,
    totalRecommended,
    totalPages,
    itemsPerPage,
    setItemsPerPage,
    setCurrentPage,
    viewFormat,
    setViewFormat,
    status,
    setStatus,
  } = useRecommendedStore();
  const { company, userPreferences } = useProfileStore();
  const { currentProfileId } = useProfile();
  const { showNotification } = useNotification();
  const { trackEvent } = useAnalytics();
  const { hasAccess } = useFeatureAccess(FeatureId.ADVANCED_RECOMMENDATIONS);
  const [searchParams, setSearchParams] = useSearchParams();

  // Memoize computed values
  const isOnboardingComplete = useMemo(
    () => Boolean(company?.cnaePrincipal?.id && userPreferences?.companySummary),
    [company?.cnaePrincipal?.id, userPreferences?.companySummary],
  );

  // Force column view on mobile, otherwise use store preference
  const effectiveViewFormat = isMobile ? 'column' : viewFormat;

  // Memoize handlers
  const loadData = useCallback(
    async (page: number = 1, forceRefresh = false) => {
      // if (isOnboardingComplete) {
      try {
        await fetchRecommendedLicitacoes(page, forceRefresh);
      } catch (error) {
        trackEvent('recommended_error', {
          action: 'load',
          error_message: error instanceof Error ? error.message : String(error),
          page,
          force_refresh: forceRefresh,
        });
        showNotification('Erro ao carregar licitações recomendadas', 'error');
      }

      // }
    },
    [
      isOnboardingComplete,
      fetchRecommendedLicitacoes,
      trackEvent,
      totalRecommended,
      itemsPerPage,
      company?.cnaePrincipal,
      userPreferences?.companySummary,
      showNotification,
    ],
  );

  useEffect(() => {
    const pageParam = parseInt(searchParams.get('pagina') || '1', 10);
    if (pageParam !== currentPage) {
      setCurrentPage(pageParam);
      loadData(pageParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentProfileId) {
      loadData(1, true);
    }
  }, [currentProfileId]);

  const handleRefresh = useCallback(() => {
    loadData(currentPage, true);
  }, [loadData, currentPage]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      searchParams.set('pagina', newPage.toString());
      setSearchParams(searchParams);
      scrollToTop('recommended-scroll-anchor');
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    if (error) {
      trackEvent('recommended_error', {
        error_message: error,
        current_page: currentPage,
      });
      showNotification(error, 'error');
    }
  }, [error, currentPage, trackEvent, showNotification]);

  // Memoize loading skeletons
  const loadingSkeletons = useMemo(
    () => (
      <Grid container spacing={2}>
        {effectiveViewFormat === 'column' ? (
          <>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <LicitacaoColumnCardSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            {[...Array(5)].map((_, index) => (
              <LicitacaoCardSkeleton key={index} />
            ))}
          </Box>
        )}
      </Grid>
    ),
    [effectiveViewFormat],
  );

  // Memoize recommended list rendering
  const recommendedListContent = useMemo(
    () =>
      effectiveViewFormat === 'column' ? (
        <>
          {recommendedList.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${item.licitacaoId}-${currentPage}`}>
              <LicitacaoColumnCard
                className="recommendation-card"
                item={item}
                isFirst={index === 0}
                searchKeywords={[]}
              />
            </Grid>
          ))}
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
          {recommendedList.map((item, index) => (
            <LicitacaoCard
              key={`${item.licitacaoId}-${currentPage}`}
              className="recommendation-card"
              item={item}
              isFirst={index === 0}
              searchKeywords={[]}
            />
          ))}
        </Box>
      ),
    [recommendedList, currentPage, effectiveViewFormat],
  );
  return (
    <>
      <Helmet>
        <title>{`Licitações Recomendadas (${totalRecommended || 0}) - Licitou`}</title>
        <meta
          name="description"
          content="Veja as licitações recomendadas com base no seu perfil e preferências"
        />
      </Helmet>
      <Box className="recommended-list">
        {!hasAccess && (
          <FeatureLimitAlert
            title="Recomendações Básicas"
            message="Suas recomendações são baseadas apenas em suas preferências cadastradas. Para receber recomendações avançadas com análise de IA e avaliação de chances de sucesso, faça upgrade do seu plano."
            variant="info"
          />
        )}
        <Box mb={4}>
          <Recommendations title="Recomendações em tempo real" />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6" component="h2" color="primary">
              Todas as Recomendações
            </Typography>
            <StatusFilter
              value={status}
              onChange={(value) => setStatus(value)}
              disabled={isLoading}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <PaginationInfo
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalRecommended}
              itemsPerPage={itemsPerPage}
              isLoading={isLoading}
              id="recommended-scroll-anchor"
            />
            <ListControls
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={(value) => setItemsPerPage(value)}
              viewFormat={effectiveViewFormat}
              onViewFormatChange={(value) => setViewFormat(value)}
              onRefresh={handleRefresh}
              disabled={isLoading}
            />
          </Box>
        </Box>
        <Grid container spacing={2} className="recommended-grid">
          {recommendedListContent}
        </Grid>

        {isLoading && recommendedList.length === 0 && loadingSkeletons}

        {totalRecommended > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalRecommended}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
          />
        )}

        {!isLoading && !error && totalRecommended === 0 && (
          <EmptyStateBox
            title={
              isOnboardingComplete
                ? 'Ainda não temos recomendações para você'
                : 'Precisamos de mais informações para recomendar licitações'
            }
            className="recommended-empty"
          >
            {isOnboardingComplete ? (
              <>
                <Typography color="text.secondary" paragraph>
                  Não se preocupe! Nosso sistema está trabalhando para encontrar as melhores
                  licitações para o seu perfil.
                </Typography>
                <Typography color="text.secondary">
                  Com o tempo, aprenderemos mais sobre suas preferências e começaremos a fornecer
                  recomendações personalizadas. Continue usando o sistema e atualizando suas
                  preferências para obter os melhores resultados.
                </Typography>
              </>
            ) : (
              <>
                <Typography color="text.secondary" paragraph>
                  Para podermos recomendar licitações relevantes para você, precisamos que você
                  complete seu perfil e preferências.
                </Typography>
                <Button
                  component={RouterLink}
                  to="/empresa/perfil"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  className="recommended-complete-profile"
                >
                  Completar Perfil
                </Button>
              </>
            )}
          </EmptyStateBox>
        )}
      </Box>
    </>
  );
};

export default memo(RecommendedLicitacoes);
