import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { CreditCard, Receipt } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { usePaymentStore, useProfileStore } from '@store';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { AnyPaymentMethod } from '@types';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PaymentForm from './PaymentForm';
import TaxInfoDialog from './TaxInfoDialog';

interface PaymentMethodsProps {
  title?: string;
  flow: 'edit' | 'view';
  onSubmit?: (paymentMethodId: string, paymentType: 'card' | 'boleto') => void;
  onBack?: () => void;
}
if (!process.env.REACT_APP_STRIPE_KEY) {
  throw new Error('REACT_APP_STRIPE_KEY is not set');
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  title = 'Métodos de Pagamento',
  flow = 'view',
  onSubmit = () => {},
  onBack = () => {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showNotification } = useNotification();
  const stripeRef = useRef<Stripe | null>(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { userData } = useProfileStore();
  const { paymentMethods, addPaymentMethod, nextBillingDates } = usePaymentStore();
  const { trackEvent } = useAnalytics();
  const [openBoletoDialog, setOpenBoletoDialog] = useState(false);

  const handlePaymentMethodSelect = async (
    paymentMethodId: string,
    paymentType: 'card' | 'boleto',
  ) => {
    const methodToSelect = paymentType === 'boleto' ? 'boleto' : paymentMethodId;
    setSelectedPaymentMethodId(methodToSelect);
    if (flow === 'edit') {
      onSubmit(methodToSelect, paymentType);
    }
  };

  const loadStripeInstance = useCallback(async () => {
    if (!stripeRef.current) {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
      stripeRef.current = stripeInstance;
    }
  }, []);

  useEffect(() => {
    if (openDialog && !stripeRef.current) {
      loadStripeInstance();
    }
  }, [openDialog, loadStripeInstance]);

  const handleAddPaymentMethod = useCallback(
    async (paymentMethodId: string, paymentType: 'card' | 'boleto' = 'card') => {
      try {
        if (paymentType === 'card') {
          await addPaymentMethod(paymentMethodId);
        }
        trackEvent('payment_method_created', {
          is_first_method: !paymentMethods?.length,
          type: paymentType,
        });
        showNotification('Método de pagamento adicionado com sucesso', 'success');
        setOpenDialog(false);
        onSubmit(paymentMethodId, paymentType);
      } catch (error: any) {
        trackEvent('payment_error', {
          action: 'add_payment_method',
          error_message: error.message,
          type: paymentType,
        });
        return;
      }
    },
    [addPaymentMethod, onSubmit, showNotification],
  );
  useEffect(() => {
    if (paymentMethods.length > 0) {
      const defaultBoletoMethod = paymentMethods.find(
        (method) => method.type === 'boleto' && method.isDefault,
      );
      const defaultCardMethod = paymentMethods.find(
        (method) => method.type === 'card' && method.isDefault && !defaultBoletoMethod,
      );

      setSelectedPaymentMethodId(
        defaultBoletoMethod?.id || defaultCardMethod?.id || paymentMethods[0].id,
      );
    }
  }, [paymentMethods]);

  const handleSubmit = () => {
    if (selectedPaymentMethodId) {
      onSubmit(
        selectedPaymentMethodId,
        selectedPaymentMethodId.includes('card') ? 'card' : 'boleto',
      );
    } else {
      showNotification('Por favor, selecione um método de pagamento', 'warning');
    }
  };

  const handleOpenDialog = async () => {
    await loadStripeInstance();
    setOpenDialog(true);
  };

  const handleBack = () => {
    onBack();
  };

  const handleBoletoSubmit = async () => {
    try {
      if (!userData?.taxId || !userData?.taxName || !userData?.address) {
        throw new Error('Dados fiscais incompletos');
      }
      setSelectedPaymentMethodId('boleto');
      onSubmit('boleto', 'boleto');
      setOpenBoletoDialog(false);
    } catch (error: any) {
      showNotification(error.message || 'Erro ao selecionar boleto', 'error');
    }
  };

  const defaultBoletoMethod = paymentMethods.find(
    (method) => method.type === 'boleto' && method.isDefault,
  );

  const paymentMethodBoxStyles = (isSelected: boolean) => ({
    mb: 2,
    p: 1.5,
    border: isSelected
      ? `2px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    cursor: 'pointer',
    width: isMobile ? '100%' : '200px',
    height: isMobile ? 'auto' : '180px',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[4],
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
    '&:active': {
      transform: 'translateY(0)',
      transition: 'transform 0.1s',
    },
  });

  const renderPaymentMethod = (method: AnyPaymentMethod) => (
    <Box
      key={method.id}
      sx={paymentMethodBoxStyles(selectedPaymentMethodId === method.id)}
      onClick={() =>
        handlePaymentMethodSelect(method.id, method.type === 'boleto' ? 'boleto' : 'card')
      }
    >
      <Typography variant={isMobile ? 'body1' : 'h6'}>
        {method.type === 'boleto' ? (
          <>
            <Receipt sx={{ mr: 1 }} />
            Boleto
          </>
        ) : (
          <>
            <CreditCard sx={{ mr: 1 }} />
            Cartão {method.brand.toUpperCase()}
          </>
        )}
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} color="text.secondary" sx={{ mt: 2 }}>
        {method.type === 'boleto'
          ? nextBillingDates.length > 0
            ? `Vencimento em ${format(new Date(nextBillingDates[0]), 'dd/MM/yyyy')}`
            : 'Vencimento indefinido'
          : `Terminando em ${method.last4}`}
      </Typography>
      {method.isDefault && !defaultBoletoMethod && (
        <Chip label="Padrão" color="primary" size={isMobile ? 'small' : 'medium'} sx={{ mt: 2 }} />
      )}
    </Box>
  );

  const renderBoletoOption = (isBoletoDefault?: boolean) => (
    <Box
      key="boleto-fixed"
      sx={paymentMethodBoxStyles(selectedPaymentMethodId === 'boleto')}
      onClick={() => {
        if (userData?.taxId && userData?.taxName && userData?.address) {
          handlePaymentMethodSelect('boleto', 'boleto');
        } else {
          setOpenBoletoDialog(true);
        }
      }}
    >
      <Typography variant={isMobile ? 'body1' : 'h6'}>
        <Receipt sx={{ mr: 1 }} />
        Boleto Bancário
      </Typography>
      {userData?.taxId && (
        <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
          CPF/CNPJ: {userData.taxId.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
        </Typography>
      )}
      {isBoletoDefault && (
        <Chip label="Padrão" color="primary" size={isMobile ? 'small' : 'medium'} sx={{ mt: 2 }} />
      )}
      {userData?.taxId && (
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setOpenBoletoDialog(true);
          }}
          sx={{ mt: 1 }}
        >
          Editar CPF/CNPJ
        </Button>
      )}
    </Box>
  );

  return (
    <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
      <CardHeader title={<Typography variant={isMobile ? 'h6' : 'h5'}>{title}</Typography>} />

      <CardContent>
        {paymentMethods.length > 0 ? (
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            flexWrap={isMobile ? 'nowrap' : 'wrap'}
            gap={2}
          >
            {renderBoletoOption(defaultBoletoMethod?.isDefault)}
            {paymentMethods.filter((method) => method.type === 'card').map(renderPaymentMethod)}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '200px',
              gap: 2,
            }}
          >
            <Typography variant={isMobile ? 'body2' : 'body1'} color="text.secondary">
              Nenhum cartão cadastrado
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row' }}>
              {renderBoletoOption()}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
                startIcon={<CreditCard />}
              >
                Adicionar Cartão
              </Button>
            </Box>
          </Box>
        )}
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'flex-end',
          p: isMobile ? 1.5 : 2,
          gap: 1,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'stretch',
        }}
      >
        {flow === 'edit' ? (
          <>
            <Button variant="outlined" onClick={handleBack} sx={{ height: '40px' }}>
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedPaymentMethodId}
              onClick={handleSubmit}
              sx={{ height: '40px' }}
            >
              Continuar
            </Button>
          </>
        ) : paymentMethods.length > 0 ? (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenDialog}
              startIcon={<CreditCard />}
              sx={{ height: '40px' }}
            >
              Adicionar Novo Cartão
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !selectedPaymentMethodId ||
                (userData?.subscriptionStatus !== 'active' &&
                  selectedPaymentMethodId === 'boleto') ||
                (selectedPaymentMethodId === 'boleto' && !userData?.taxId && !userData?.taxName) ||
                (selectedPaymentMethodId === 'boleto'
                  ? defaultBoletoMethod?.isDefault
                  : paymentMethods.find((method) => method.id === selectedPaymentMethodId)
                      ?.isDefault)
              }
              onClick={handleSubmit}
            >
              Tornar método de pagamento padrão
            </Button>
          </>
        ) : null}
      </CardActions>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Adicionar Método de Pagamento</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Adicione um novo método de pagamento.
          </DialogContentText>
          {stripeRef.current ? (
            <Elements stripe={stripeRef.current}>
              <PaymentForm onSubmit={handleAddPaymentMethod} onClose={() => setOpenDialog(false)} />
            </Elements>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
      </Dialog>
      <TaxInfoDialog
        open={openBoletoDialog}
        onClose={() => setOpenBoletoDialog(false)}
        onSubmit={handleBoletoSubmit}
      />
    </Card>
  );
};

export default PaymentMethods;
