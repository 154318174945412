import { useAnalytics } from '@hooks/useAnalytics';
import { useAuth } from '@hooks/useAuth';
import BusinessIcon from '@mui/icons-material/Business';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/Help';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import TuneIcon from '@mui/icons-material/Tune';
import { Avatar, Box, Divider, Menu, MenuItem, Switch, Typography } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';
import { useProfileStore } from '@store/profileStore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserMenu: React.FC<{ dataTour?: string }> = ({ dataTour }) => {
  const { trackEvent } = useAnalytics();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { mode, setMode } = useColorScheme();
  const { userData } = useProfileStore();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleColorModeToggle = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    trackEvent('color_mode', {
      action: 'change',
      previous_mode: mode,
      new_mode: newMode,
    });
    setMode(newMode);
  };

  const handleLogout = () => {
    trackEvent('user_logout', {});
    signOut();
    handleClose();
  };

  const handleHelpCenter = () => {
    trackEvent('help_center', {
      action: 'open',
      from_path: window.location.pathname,
    });
    window.open('https://intercom.help/licitou/', '_blank');
    handleClose();
  };

  return (
    <>
      <Box onClick={handleMenu} sx={{ cursor: 'pointer' }} data-tour={dataTour}>
        <Avatar
          src={userData?.photoURL || user?.photoURL || undefined}
          alt={userData?.email || user?.email || 'Usuário'}
        >
          {!userData?.photoURL && !user?.photoURL && <PersonIcon />}
        </Avatar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled>
          <Typography variant="body2">{user?.email}</Typography>
        </MenuItem>
        <Divider />
        {/* Company and Profile Section */}
        <Box>
          <MenuItem onClick={() => handleNavigation('/empresa/perfil')}>
            <BusinessIcon fontSize="small" style={{ marginRight: 8 }} />
            Empresa
          </MenuItem>
          <MenuItem onClick={() => handleNavigation('/empresa/preferencias')}>
            <TuneIcon fontSize="small" style={{ marginRight: 8 }} />
            Preferências
          </MenuItem>
          {/* <MenuItem onClick={() => handleNavigation('/empresa/multi')}>
            <BusinessIcon fontSize="small" style={{ marginRight: 8 }} />
            Multi-Empresa
          </MenuItem> */}
        </Box>
        <Divider />
        {/* User Settings Section */}
        <Box>
          <MenuItem onClick={() => handleNavigation('/configuracoes/pagamento')}>
            <PaymentIcon fontSize="small" style={{ marginRight: 8 }} />
            Pagamento
          </MenuItem>
          <MenuItem onClick={() => handleNavigation('/configuracoes/perfil')}>
            <PersonIcon fontSize="small" style={{ marginRight: 8 }} />
            Perfil
          </MenuItem>
          <MenuItem onClick={() => handleNavigation('/configuracoes/notificacoes')}>
            <NotificationsIcon fontSize="small" style={{ marginRight: 8 }} />
            Notificações
          </MenuItem>
          <MenuItem onClick={() => handleNavigation('/configuracoes/historico')}>
            <HistoryIcon fontSize="small" style={{ marginRight: 8 }} />
            Histórico
          </MenuItem>
        </Box>
        <Divider />
        {/* System Settings Section */}
        <Box>
          <MenuItem>
            <DarkModeIcon fontSize="small" style={{ marginRight: 8 }} />
            Modo escuro
            <Switch
              checked={mode === 'dark'}
              onChange={handleColorModeToggle}
              color="primary"
              size="small"
              style={{ marginLeft: 'auto' }}
            />
          </MenuItem>
          <MenuItem onClick={handleHelpCenter}>
            <HelpIcon fontSize="small" style={{ marginRight: 8 }} />
            Central de Ajuda
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ExitToAppIcon fontSize="small" style={{ marginRight: 8 }} />
            Sair
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};

export default UserMenu;
