import { ESFERAS, getModalidadeInfo, PORTAL_SHORT_NAMES } from '@constants';
import {
  AttachMoney,
  Business,
  Category,
  Description,
  Dns,
  Public as EsferaIcon,
  Event,
  EventAvailable,
  ListAlt,
  LocationOn,
  Public as PortalIcon,
  Today,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { Esfera, Licitacao, RecommendedLicitacao } from '@types';
import { formatIsoDate, getLicitacaoStatus, isLicitacaoClosed, isNew } from '@utils';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Annotation from '../Annotation/Annotation';
import MatchingItems from '../Search/MatchingItems';
import HighlightedText from './HighlightedText';
import LicitacaoActions from './LicitacaoActions';

interface LicitacaoCardProps {
  item: Licitacao | RecommendedLicitacao;
  isFirst: boolean;
  searchKeywords?: string[];
  className?: string;
}

const LicitacaoCard: React.FC<LicitacaoCardProps> = ({
  item,
  isFirst,
  searchKeywords = [],
  className,
}) => {
  const theme = useTheme();
  const { isFavorite } = useFavoritesStore();
  const { isFollowing } = useFollowingStore();
  const modalidadeInfo = getModalidadeInfo(item.id_tipo);
  const isNewLicitacao = isNew(item.createdAt);
  const closed = isLicitacaoClosed(item.abertura_datetime, item.fim_recebimento_propostas_datetime);
  const currentStatus = getLicitacaoStatus(
    item.status,
    item.abertura_datetime,
    item.fim_recebimento_propostas_datetime,
  );
  const [showFullObjeto, setShowFullObjeto] = useState(false);

  const InfoItem = ({
    icon: Icon,
    label,
    value,
  }: {
    icon: React.ElementType;
    label: string;
    value: string;
  }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mb: 0.5 }}>
        <Tooltip title={label}>
          <Icon fontSize="small" sx={{ color: theme.palette.primary.main, opacity: 0.7 }} />
        </Tooltip>
        <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
          {label}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'medium' }}>
        {value}
      </Typography>
    </Box>
  );

  const getMatchingItems = useMemo(() => {
    if (item.highlights && item.highlights['items.descricao']) {
      // If we have highlights for items.descricao, use all of them
      return item.highlights['items.descricao'].map((descricao) => ({
        descricao,
        isHighlighted: true,
      }));
    }

    // If no highlights, fall back to the previous keyword-based search
    return (
      item.items
        ?.slice(0, 5)
        ?.filter((licitacaoItem) =>
          searchKeywords.some((keyword) =>
            licitacaoItem.descricao.toLowerCase().includes(keyword.toLowerCase()),
          ),
        )
        .map((matchingItem) => ({ descricao: matchingItem.descricao, isHighlighted: false })) || []
    );
  }, [item.highlights, item.items, searchKeywords]);

  const getGradientColor = () => {
    return theme.palette.mode === 'dark'
      ? `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`
      : 'linear-gradient(to bottom, transparent, white)';
  };

  return (
    <Grid
      item
      xs={12}
      data-tour={isFirst ? 'licitacao-card' : undefined}
      className={className}
      sx={{
        maxWidth: '1200px !important',
        width: '100%',
        mx: 'auto', // Centers the card
      }}
    >
      <Link to={`/licitacao/${item.id}`} style={{ textDecoration: 'none' }}>
        <Card
          sx={{
            mb: 2,
            boxShadow: 3,
            '&:hover': { boxShadow: 6 },
            position: 'relative',
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            opacity: closed ? 0.8 : 1,
            ...(isFollowing(item.id) && {
              borderLeft: `4px solid ${theme.palette.primary.main}`,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '4px',
                background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${alpha(
                  theme.palette.primary.main,
                  0.1,
                )} 100%)`,
              },
            }),
          }}
        >
          <Box
            sx={{ position: 'absolute', top: 8, left: isFollowing(item.id) ? 12 : 8, zIndex: 1 }}
          >
            {isNewLicitacao && <Chip label="Nova" color="primary" size="small" sx={{ mr: 1 }} />}
            {closed && <Chip label="Encerrada" color="error" size="small" />}
          </Box>
          <CardContent>
            <Box mb={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <modalidadeInfo.icon
                  fontSize="small"
                  style={{ color: modalidadeInfo.color, marginRight: 0.5 }}
                />
                <Typography variant="subtitle2" color="primary">
                  {item.titulo} - {item.orgao}
                </Typography>
                <Box sx={{ marginLeft: 'auto' }}>
                  <LicitacaoActions licitacaoId={item.id} isFirst={isFirst} />
                </Box>
              </Box>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <InfoItem
                  icon={PortalIcon}
                  label="Portal"
                  value={PORTAL_SHORT_NAMES[item.id_portal]}
                />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={LocationOn}
                  label="Local"
                  value={`${item.municipio} - ${item.uf}`}
                />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={EventAvailable}
                  label="Data de abertura"
                  value={item.abertura_datetime ? formatIsoDate(item.abertura_datetime) : '-'}
                />
              </Grid>
              <Grid item xs={2}>
                <InfoItem icon={Dns} label="Status" value={currentStatus} />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={ListAlt}
                  label="Número do Edital"
                  value={item.numeroEdital || '-'}
                />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={Today}
                  label="Data publicação"
                  value={
                    item.publicacao_datetime
                      ? formatIsoDate(item.publicacao_datetime)
                      : formatIsoDate(item.createdAt)
                  }
                />
              </Grid>
              {/* second row */}
              <Grid item xs={2}>
                <InfoItem icon={Description} label="Modalidade" value={modalidadeInfo.name} />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={AttachMoney}
                  label="Valor"
                  value={
                    item.valor > 0
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(item.valor)
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={Event}
                  label="Data encerramento"
                  value={
                    item.fim_recebimento_propostas_datetime
                      ? formatIsoDate(item.fim_recebimento_propostas_datetime)
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <InfoItem icon={Category} label="Código CNAE" value={item.cnae || '-'} />
              </Grid>
              <Grid item xs={2}>
                <InfoItem icon={Business} label="Código UASG" value={item.codigo_uasg || '-'} />
              </Grid>
              <Grid item xs={2}>
                <InfoItem
                  icon={EsferaIcon}
                  label="Esfera"
                  value={ESFERAS[item.esfera as Esfera] || 'Não informada'}
                />
              </Grid>
            </Grid>
            <Box mt={1}>
              <Box
                sx={{
                  position: 'relative',
                  maxHeight: showFullObjeto ? 'none' : '150px',
                  overflow: 'hidden',
                  mb: 2,
                }}
              >
                <HighlightedText field="objeto" text={item.objeto} highlights={item.highlights} />
                {!showFullObjeto && item.objeto.length > 500 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      height: '50px',
                      background: getGradientColor(),
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowFullObjeto(true);
                      }}
                    >
                      Ver mais
                    </Button>
                  </Box>
                )}
              </Box>
              <MatchingItems items={getMatchingItems} highlights={item.highlights} />
            </Box>
            {(isFavorite(item.id) || isFollowing(item.id)) && (
              <Annotation
                licitacaoId={item.id}
                isVisible={true}
                type={isFollowing(item.id) ? 'following' : 'favorite'}
              />
            )}
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default LicitacaoCard;
