import { useFeature } from '@contexts/FeatureContext';
import { Button, ButtonProps } from '@mui/material';
import { FeatureId } from '@types';
import React from 'react';

interface UpgradeButtonProps extends Omit<ButtonProps, 'onClick'> {
  featureId?: FeatureId;
  message?: string;
  compact?: boolean;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  featureId,
  message = 'Fazer Upgrade',
  compact = false,
  ...buttonProps
}) => {
  const { openUpgradeModal, trackUpgradeClick } = useFeature();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    trackUpgradeClick(featureId, { is_compact: compact });
    openUpgradeModal(featureId);
  };

  return (
    <Button
      variant={compact ? 'text' : 'contained'}
      color="primary"
      size={compact ? 'small' : 'medium'}
      onClick={handleClick}
      {...buttonProps}
    >
      {message}
    </Button>
  );
};

export default UpgradeButton;
