import Api from '@services/api';
import { Notification, NotificationStatusFilter } from '@types';
import { create } from 'zustand';

interface NotificationState {
  notifications: Notification[];
  unreadCount: number;
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalNotifications: number;
  hasMore: boolean;
  status: NotificationStatusFilter;
  setStatus: (status: NotificationStatusFilter) => void;
  fetchNotifications: (page?: number, perPage?: number) => Promise<void>;
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  deleteNotification: (notificationId: string) => Promise<void>;
  resetStore: () => void;
  setCurrentPage: (page: number) => void;
}

export const useNotificationStore = create<NotificationState>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  isLoading: false,
  error: null,
  currentPage: 1,
  totalNotifications: 0,
  hasMore: true,
  status: 'all',

  setStatus: (status) => {
    set({ status, currentPage: 1, notifications: [] });
    get().fetchNotifications(1);
  },

  fetchNotifications: async (page: number = 1, perPage: number = 20) => {
    set({ isLoading: true, error: null });
    try {
      const response = await Api.getNotifications(page, perPage, get().status);
      set((state) => ({
        notifications:
          page === 1 ? response.notifications : [...state.notifications, ...response.notifications],
        unreadCount: response.unreadCount,
        totalNotifications: response.total,
        isLoading: false,
        currentPage: page,
        hasMore: response.totalPages > page,
      }));
    } catch (error: any) {
      console.error('Error fetching notifications:', error);
      const errorMessage = error.message || 'Erro ao buscar notificações';
      set({ error: errorMessage, isLoading: false, hasMore: false });
      throw error;
    }
  },

  markAsRead: async (notificationId: string) => {
    const previousState = get();
    try {
      await Api.markNotificationAsRead(notificationId);
      set((state) => {
        const notification = state.notifications.find((n) => n.id === notificationId);
        const shouldDecrementCount = notification && !notification.read;

        return {
          notifications: state.notifications.map((n) =>
            n.id === notificationId ? { ...n, read: true } : n,
          ),
          unreadCount: shouldDecrementCount
            ? Math.max(0, state.unreadCount - 1)
            : state.unreadCount,
        };
      });
    } catch (error: any) {
      console.error('Error marking notification as read:', error);
      const errorMessage = error.message || 'Erro ao marcar notificação como lida';
      set({ ...previousState, error: errorMessage });
      throw error;
    }
  },

  markAllAsRead: async () => {
    const previousState = get();
    try {
      await Api.markAllNotificationsAsRead();
      set((state) => ({
        notifications: state.notifications.map((n) => ({ ...n, read: true })),
        unreadCount: 0,
      }));
    } catch (error: any) {
      console.error('Error marking all notifications as read:', error);
      const errorMessage = error.message || 'Erro ao marcar todas notificações como lidas';
      set({ ...previousState, error: errorMessage });
      throw error;
    }
  },

  deleteNotification: async (notificationId: string) => {
    const previousState = get();
    try {
      await Api.deleteNotification(notificationId);
      set((state) => ({
        notifications: state.notifications.filter((n) => n.id !== notificationId),
        unreadCount: state.notifications.find((n) => n.id === notificationId)?.read
          ? state.unreadCount
          : state.unreadCount - 1,
      }));
    } catch (error: any) {
      console.error('Error deleting notification:', error);
      const errorMessage = error.message || 'Erro ao deletar notificação';
      set({ ...previousState, error: errorMessage });
      throw error;
    }
  },

  setCurrentPage: (page: number) => set({ currentPage: page }),

  resetStore: () =>
    set({
      notifications: [],
      unreadCount: 0,
      isLoading: false,
      error: null,
      currentPage: 1,
      totalNotifications: 0,
      hasMore: true,
      status: 'all',
    }),
}));
