import { useNotification } from '@contexts/NotificationContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import RecommendIcon from '@mui/icons-material/Recommend';
import { Box, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useRecommendedStore } from '@store/recommendedStore';
import { RecommendedLicitacao } from '@types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RecommendedCard from './RecommendedCard';
import RecommendedCardSkeleton from './RecommendedCardSkeleton';

interface RecommendationsProps {
  title?: string;
}

const Recommendations: React.FC<RecommendationsProps> = ({
  title = 'Recomendações personalizadas',
}) => {
  const { recommendedRealtimeList, isRealtimeLoading, error, fetchRealtimeRecommended } =
    useRecommendedStore();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [shuffledList, setShuffledList] = useState<RecommendedLicitacao[]>([]);

  const { showNotification } = useNotification();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));

  const getColumnCount = () => {
    if (isXs) return 1;
    if (isSm) return 2;
    if (isMd) return 3;
    return 4;
  };

  const columnCount = getColumnCount();

  useEffect(() => {
    fetchRealtimeRecommended(1);
  }, [fetchRealtimeRecommended]);

  useEffect(() => {
    if (recommendedRealtimeList.length > 0) {
      const shuffled = [...recommendedRealtimeList];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      setShuffledList(shuffled);
      setShowRightArrow(shuffled.length > columnCount);
    } else {
      setShuffledList([]);
      setShowRightArrow(false);
    }
  }, [recommendedRealtimeList, columnCount]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, [handleScroll]);

  const scroll = useCallback(
    (direction: 'left' | 'right') => {
      if (scrollContainerRef.current) {
        const cardWidth = scrollContainerRef.current.clientWidth / columnCount;
        const scrollAmount = cardWidth * columnCount;
        scrollContainerRef.current.scrollBy({
          left: direction === 'left' ? -scrollAmount : scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [columnCount],
  );

  const titleContent = useMemo(
    () => (
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <RecommendIcon color="primary" />
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </Box>
    ),
    [title],
  );

  if (isRealtimeLoading && !recommendedRealtimeList.length) {
    return (
      <>
        {titleContent}
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            py: 1,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            '& > *': {
              flex: '0 0 auto',
              width: `calc(${100 / columnCount}% - ${((columnCount - 1) * 16) / columnCount}px)`,
            },
          }}
        >
          {[...Array(columnCount)].map((_, index) => (
            <RecommendedCardSkeleton key={index} />
          ))}
        </Box>
      </>
    );
  }

  if (!recommendedRealtimeList.length) {
    return (
      <>
        {titleContent}
        <Paper sx={{ p: 2, backgroundColor: 'background.paper', borderRadius: 2 }}>
          <Box display="flex" alignItems="center">
            <InfoIcon color="info" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Nenhuma recomendação encontrada no momento
            </Typography>
          </Box>
        </Paper>
      </>
    );
  }

  return (
    <>
      {titleContent}
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={scrollContainerRef}
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            py: 1,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            '& > *': {
              flex: '0 0 auto',
              width: `calc(${100 / columnCount}% - ${((columnCount - 1) * 16) / columnCount}px)`,
            },
          }}
        >
          {shuffledList.map((licitacao, index) => (
            <RecommendedCard key={licitacao.id} licitacao={licitacao} isFirst={index === 0} />
          ))}
        </Box>

        {showLeftArrow && (
          <IconButton
            onClick={() => scroll('left')}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              backgroundColor: 'background.paper',
              boxShadow: 3,
              width: 40,
              height: 80,
              borderRadius: '0 8px 8px 0',
              '&:hover': {
                backgroundColor: 'action.hover',
                boxShadow: 6,
              },
            }}
          >
            <ChevronLeftIcon sx={{ fontSize: 32 }} />
          </IconButton>
        )}

        {showRightArrow && (
          <IconButton
            onClick={() => scroll('right')}
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              backgroundColor: 'background.paper',
              boxShadow: 3,
              width: 40,
              height: 80,
              borderRadius: '8px 0 0 8px',
              '&:hover': {
                backgroundColor: 'action.hover',
                boxShadow: 6,
              },
            }}
          >
            <ChevronRightIcon sx={{ fontSize: 32 }} />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default React.memo(Recommendations);
