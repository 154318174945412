export const isEqual = (value: any, other: any): boolean => {
  if (value === other) return true;

  if (value == null || other == null) return false;

  if (typeof value !== typeof other) return false;

  if (typeof value !== 'object') return value === other;

  if (Array.isArray(value) !== Array.isArray(other)) return false;

  const keysValue = Object.keys(value);
  const keysOther = Object.keys(other);

  if (keysValue.length !== keysOther.length) return false;

  return keysValue.every((key) => keysOther.includes(key) && isEqual(value[key], other[key]));
};
