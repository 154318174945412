import InfoSection from '@components/Common/InfoSection';
import MessageBox from '@components/Common/MessageBox';
import DocumentsRequiredModal from '@components/Documents/DocumentsRequiredModal';
import FeatureGate from '@components/Feature/FeatureGate';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import {
  Description as DescriptionIcon,
  FilePresent as FilePresentIcon,
  Folder as FolderIcon,
} from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FeatureId, Licitacao, PortalService } from '@types';
import { useState } from 'react';

interface DocumentsStepProps {
  licitacao: Licitacao;
  portalService: PortalService;
}

export const DocumentsStep: React.FC<DocumentsStepProps> = ({ licitacao }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const { hasAccess: hasDocAccess } = useFeatureAccess(FeatureId.DOC_VERIFICATION);

  return (
    <>
      <Box
        sx={{
          height: 'auto',
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: isMobile ? 1.5 : 3,
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 1.5,
            width: '100%',
            minHeight: 0,
          }}
        >
          {/* Left Column */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              color="primary"
              sx={{
                fontSize: isMobile ? '0.9rem' : '1rem',
                mb: 0.5,
              }}
            >
              Documentação para Habilitação
            </Typography>

            <InfoSection
              title="Gestão de Documentos"
              sx={{
                fontSize: '0.875rem',
              }}
            >
              <ul
                style={{
                  paddingLeft: isMobile ? '1rem' : '2rem',
                  margin: isMobile ? '0.5rem 0' : '1rem 0',
                }}
              >
                <li>Acesse a área de Documentos para gerenciar seus arquivos</li>
                <li>Mantenha seus documentos atualizados</li>
                <li>Organize por categorias</li>
                {!isMobile && (
                  <>
                    <li>Configure alertas de vencimento</li>
                    <li>Utilize os modelos disponíveis</li>
                  </>
                )}
              </ul>
            </InfoSection>

            {!isMobile && (
              <InfoSection title="Requisitos de Autenticação" sx={{ fontSize: '0.875rem' }}>
                <ul>
                  <li>Documentos digitais devem ter certificação ICP-Brasil</li>
                  <li>Cópias podem precisar de autenticação em cartório</li>
                  <li>Declarações devem ser assinadas pelo responsável legal</li>
                  <li>Procurações precisam ter firma reconhecida</li>
                </ul>
              </InfoSection>
            )}
          </Box>

          {/* Right Column */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
              }}
            >
              {/* Document Buttons Section */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                  Verifique os documentos necessários:
                </Typography>
                <FeatureGate
                  featureId={FeatureId.DOC_VERIFICATION}
                  message="Faça upgrade para analisar documentos"
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size={isMobile ? 'small' : 'medium'}
                    startIcon={<FilePresentIcon />}
                    disabled={licitacao?.documents?.length === 0}
                    onClick={hasDocAccess ? () => setDocumentsModalOpen(true) : undefined}
                  >
                    {isMobile ? 'Ver Documentos' : 'Ver lista de Documentos Necessários'}
                  </Button>
                </FeatureGate>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                  Gerencie seus documentos:
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                  startIcon={<FolderIcon />}
                  href="/documentos/documentos"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isMobile ? 'Área de Documentos' : 'Acessar Área de Documentos'}
                </Button>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                  Crie declarações personalizadas:
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                  startIcon={<DescriptionIcon />}
                  href="/documentos/declaracoes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isMobile ? 'Declarações' : 'Gerar Declarações'}
                </Button>
              </Box>
            </Box>

            <MessageBox
              variant="info"
              title="Utilize a área de Documentos"
              sx={{ mt: isMobile ? 1 : 2 }}
            >
              {isMobile
                ? 'Mantenha seu cadastro atualizado e gere declarações personalizadas.'
                : 'Utilize a área de Documentos para manter seu cadastro atualizado e gerar declarações personalizadas para cada licitação.'}
            </MessageBox>
          </Box>
        </Box>
      </Box>

      {hasDocAccess && (
        <DocumentsRequiredModal
          open={documentsModalOpen}
          onClose={() => setDocumentsModalOpen(false)}
          licitacao={licitacao}
        />
      )}
    </>
  );
};
