import { KanbanColumn } from '@types';
export const FIXED_KANBAN_COLUMNS: KanbanColumn[] = [
  {
    id: 'oportunidade',
    title: 'Oportunidade',
    position: 0,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'qualificacao',
    title: 'Qualificação',
    position: 1,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'disputa',
    title: 'Disputa',
    position: 2,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'classificacao',
    title: 'Classificação',
    position: 3,
    licitacoesIds: [],
    licitacoes: [],
  },
  {
    id: 'contrato',
    title: 'Contrato',
    position: 4,
    licitacoesIds: [],
    licitacoes: [],
  },
];

export const DEFAULT_BOARD_ID = 'default';
export const DEFAULT_BOARD_NAME = 'Meu Quadro de Licitações';
