import DocumentSections from '@components/Documents/DocumentSections';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { useDocumentsStore } from '@store/documentsStore';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

const Documents: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    isLoading,
    error,
    documents,
    fetchDocuments,
    downloadDocument,
    downloadAllDocuments,
    deleteDocument,
  } = useDocumentsStore();
  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
      trackEvent('document_error', {
        error: error,
      });
    }
  }, [error, showNotification]);

  const handleDownload = useCallback(
    async (documentId: string, fileName: string, childId?: string) => {
      try {
        if (isMobile) {
          showNotification('Iniciando download...', 'info');
        }
        const blob = await downloadDocument(documentId, childId);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        showNotification('Documento baixado com sucesso', 'success');
      } catch (error) {
        console.error('Error downloading document:', error);
        trackEvent('document_error', {
          action: 'download',
        });
        showNotification('Erro ao baixar documento', 'error');
      }
    },
    [downloadDocument, showNotification, isMobile],
  );

  const handleDownloadAll = useCallback(
    async (section?: string) => {
      try {
        if (isMobile) {
          showNotification('Preparando download de múltiplos arquivos...', 'info');
        }
        const blob = await downloadAllDocuments(section);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = section ? `${section}_documents.zip` : 'all_documents.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        showNotification('Documentos baixados com sucesso', 'success');
      } catch (error) {
        console.error('Error downloading all documents:', error);
        trackEvent('document_error', {
          action: 'download_all',
        });
        showNotification('Erro ao baixar documentos', 'error');
      }
    },
    [downloadAllDocuments, showNotification, isMobile],
  );

  const handleDownloadSelected = useCallback(
    async (selectedDocuments: string[]) => {
      try {
        if (isMobile) {
          showNotification('Preparando download dos documentos selecionados...', 'info');
        }
        const blob = await downloadAllDocuments(undefined, selectedDocuments);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'selected_documents.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        showNotification('Documentos selecionados baixados com sucesso', 'success');
      } catch (error) {
        console.error('Error downloading selected documents:', error);
        trackEvent('document_error', {
          action: 'download_selected',
        });
        showNotification('Erro ao baixar documentos selecionados', 'error');
      }
    },
    [downloadAllDocuments, showNotification, isMobile],
  );

  const handleDelete = useCallback(
    async (documentId: string) => {
      try {
        await deleteDocument(documentId);
        trackEvent('document_delete', {
          document_id: documentId,
        });
        showNotification('Documento excluído com sucesso', 'success');
      } catch (error) {
        trackEvent('document_error', {
          action: 'delete',
        });
        console.error('Error deleting document:', error);
        showNotification('Erro ao excluir documento', 'error');
      }
    },
    [deleteDocument, showNotification],
  );

  const documentSectionsProps = useMemo(
    () => ({
      showNotification,
      documents,
      isLoading,
      handleDownloadSelected,
      handleDownload,
      handleDownloadAll,
      handleDelete,
      isMobile,
    }),
    [
      documents,
      isLoading,
      handleDownloadSelected,
      handleDownload,
      handleDownloadAll,
      handleDelete,
      showNotification,
      isMobile,
    ],
  );

  return (
    <>
      <Helmet>
        <title>Documentos da Empresa - Licitou</title>
        <meta
          name="description"
          content="Gerencie todos os documentos necessários para participar de licitações: certidões, declarações e documentos da empresa"
        />
      </Helmet>

      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
          sx={{
            position: isMobile ? 'fixed' : 'relative',
            top: isMobile ? '50%' : 'auto',
            left: isMobile ? '50%' : 'auto',
            transform: isMobile ? 'translate(-50%, -50%)' : 'none',
            zIndex: isMobile ? 1000 : 'auto',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ p: isMobile ? 0 : 2 }}>
        <DocumentSections {...documentSectionsProps} />
      </Box>
    </>
  );
};

export default React.memo(Documents);
