import MessageBox from '@components/Common/MessageBox';
import Loading from '@components/Layout/Loading';
import ComparePaymentPlansModal from '@components/Payment/ComparePaymentPlansModal';
import PaymentMethods from '@components/Payment/PaymentMethods';
import PlanSelection from '@components/Payment/PlanSelection';
import { planDurations, plans } from '@constants/plans';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { Receipt } from '@mui/icons-material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { usePaymentStore } from '@store/paymentStore';
import { useProfileStore } from '@store/profileStore';
import { DurationId, PlanId, SubscriptionStatus } from '@types';
import React, { Suspense, useMemo, useState } from 'react';

interface SubscriptionFlowProps {
  onClose: () => void;
  onSuccess?: () => void;
  initialStep?: number;
  initialDurationId: DurationId | null;
  initialPlanId: PlanId | null;
  subscriptionId?: string;
}

const SubscriptionFlow: React.FC<SubscriptionFlowProps> = ({
  onClose,
  onSuccess = () => {},
  initialStep = 0,
  initialDurationId,
  initialPlanId,
  subscriptionId: propSubscriptionId,
}) => {
  const theme = useTheme();
  const {
    paymentMethods,
    confirmPaymentAndCreateSubscription,
    switchSubscriptionPlan,
    currentPlans,
    createBoletoPaymentIntent,
    switchToBoleto,
  } = usePaymentStore();
  const { userData } = useProfileStore();

  // Get subscription ID from props or current plans
  const subscriptionId = useMemo(() => {
    if (propSubscriptionId) return propSubscriptionId;
    const activePlan = currentPlans && currentPlans.length > 0 ? currentPlans[0] : null;
    return activePlan?.id || null;
  }, [propSubscriptionId, currentPlans]);

  const [activeStep, setActiveStep] = useState(initialStep);
  const [selectedDurationId, setSelectedDurationId] = useState<DurationId>(
    initialDurationId || DurationId.MENSAL,
  );
  const [selectedPlanId, setSelectedPlanId] = useState<PlanId | null>(initialPlanId || null);
  const [isChangingPlan, setIsChangingPlan] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [boletoUrl, setBoletoUrl] = useState<string | null>(null);

  const steps = subscriptionId
    ? ['Selecionar Novo Plano', 'Selecionar Método de Pagamento', 'Confirmar Alteração']
    : ['Selecionar Plano', 'Selecionar Método de Pagamento', 'Confirmar Assinatura'];

  const handleBack = () => {
    if (activeStep === 0) {
      onClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNext = (step: number) => {
    setActiveStep(step);
  };

  const handleSetPaymentMethod = async (
    paymentMethodId: string,
    paymentType: 'card' | 'boleto',
  ) => {
    try {
      if (paymentType === 'boleto') {
        setPaymentMethodId('boleto');
      } else {
        setPaymentMethodId(paymentMethodId);
      }
      handleNext(2);
    } catch (error: any) {
      showNotification(error.message || 'Erro ao processar pagamento', 'error');
    }
  };

  const handlePlanSelection = async (planId: PlanId, duration: DurationId) => {
    setSelectedPlanId(planId);
    setSelectedDurationId(duration);
    setIsChangingPlan(!!subscriptionId);
    handleNext(1);
  };

  const handleSignUp = async () => {
    if (!selectedPlanId || !selectedDurationId) {
      showNotification('Por favor, preencha todos os campos necessários', 'error');
      return;
    }

    try {
      if (subscriptionId) {
        if (paymentMethodId === 'boleto') {
          await switchToBoleto(subscriptionId);
        } else {
          await switchSubscriptionPlan(subscriptionId, selectedPlanId, selectedDurationId);
        }
        showNotification('Plano atualizado com sucesso', 'success');
        onSuccess();
        trackEvent('plan_changed', {
          plan_id: selectedPlanId,
          duration_id: selectedDurationId,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        if (paymentMethodId === 'boleto') {
          const result = await createBoletoPaymentIntent(selectedPlanId, selectedDurationId);
          setBoletoUrl(result.boleto_url);
          showNotification('Boleto gerado com sucesso', 'success');
        } else {
          await confirmPaymentAndCreateSubscription(
            selectedPlanId,
            selectedDurationId,
            paymentMethodId!,
          );
          showNotification('Assinatura criada com sucesso', 'success');
        }
        onSuccess();
      }

      trackEvent(subscriptionId ? 'plan_changed' : 'subscription_created', {
        plan_id: selectedPlanId,
        duration_id: selectedDurationId,
        payment_type: paymentMethodId === 'boleto' ? 'boleto' : 'card',
      });

      if (subscriptionId) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error: any) {
      trackEvent('subscription_error', {
        action: 'create',
        error_message: error.message,
        plan_id: selectedPlanId,
        duration_id: selectedDurationId,
      });
      showNotification(error.message || 'Erro ao processar a operação', 'error');
    }
  };

  const stepComponents = useMemo(
    () => ({
      0: () => {
        const disabledPlans = subscriptionId
          ? [
              {
                planId: userData?.planId as PlanId,
                durationId: userData?.durationId as DurationId,
                message: 'Você já está inscrito neste plano',
              },
            ]
          : [];

        return (
          <PlanSelection
            onChoosePlan={handlePlanSelection}
            defaultPlanId={initialPlanId}
            defaultDurationId={initialDurationId}
            onBack={handleBack}
            onCompare={() => setIsCompareModalOpen(true)}
            disabledPlans={disabledPlans}
          />
        );
      },
      1: () => {
        if (!selectedPlanId || !selectedDurationId) {
          handleNext(0);
          return null;
        }
        return (
          <Suspense fallback={<Loading />}>
            <PaymentMethods
              flow="edit"
              onSubmit={handleSetPaymentMethod}
              onBack={handleBack}
              title={
                subscriptionId ? 'Confirmar Método de Pagamento' : 'Selecionar Método de Pagamento'
              }
            />
          </Suspense>
        );
      },
      2: () => {
        if (!selectedPlanId || !selectedDurationId || !paymentMethodId) {
          handleNext(0);
          return null;
        }
        const paymentMethod = paymentMethods.find((method) => method.id === paymentMethodId);
        const plan = plans.find((plan) => plan.id === selectedPlanId);
        const duration = planDurations.find((d) => d.id === selectedDurationId);
        const price =
          plan?.durationPrices[planDurations.findIndex((d) => d.id === selectedDurationId)];

        return (
          <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
            <CardHeader
              title={subscriptionId ? 'Confirmar Alteração de Plano' : 'Confirmar Assinatura'}
            />
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {subscriptionId
                  ? 'Por favor, confirme os detalhes do novo plano. A alteração será feita imediatamente.'
                  : 'Por favor, confirme os detalhes do seu plano e forma de pagamento para iniciar a assinatura.'}
              </Typography>

              {subscriptionId && (
                <MessageBox variant="info">
                  O valor não utilizado do seu plano atual será automaticamente creditado, e você
                  pagará apenas a diferença para o novo plano. A cobrança será proporcional ao
                  período restante do ciclo atual.
                </MessageBox>
              )}

              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Plano Selecionado:
                </Typography>
                <Typography variant="h6">{plan?.name}</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Duração:
                </Typography>
                <Typography variant="h6">{duration?.text}</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Total:
                </Typography>
                <Typography variant="h6">{price}</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Dados do Pagamento:
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant="body1">
                    {paymentMethodId === 'boleto'
                      ? 'Boleto'
                      : paymentMethod?.type === 'card'
                        ? `${paymentMethod.brand.toUpperCase()} terminando em ${paymentMethod.last4}`
                        : 'Método de pagamento não selecionado'}
                  </Typography>
                  {paymentMethodId === 'boleto' && boletoUrl && (
                    <Button
                      variant="outlined"
                      size="small"
                      href={boletoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<Receipt />}
                    >
                      Baixar Boleto
                    </Button>
                  )}
                </Box>
                {paymentMethodId === 'boleto' && (
                  <MessageBox variant="info" sx={{ mt: 2 }}>
                    Sua assinatura será ativada assim que o pagamento do boleto for confirmado. O
                    boleto tem vencimento em 3 dias úteis.
                  </MessageBox>
                )}
              </Box>
            </CardContent>
            <CardActions
              sx={{
                p: 2,
                gap: 2,
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: { xs: 'stretch', sm: 'flex-end' },
                '& > button': {
                  width: { xs: '100%', sm: 'auto' },
                },
              }}
            >
              <Button variant="outlined" onClick={handleBack} sx={{ height: '40px' }}>
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSignUp}
                sx={{ height: '40px' }}
              >
                {subscriptionId ? 'Confirmar Alteração' : 'Confirmar Assinatura'}
              </Button>
            </CardActions>
          </Card>
        );
      },
    }),
    [
      isChangingPlan,
      userData?.subscriptionStatus,
      userData?.planId,
      selectedPlanId,
      selectedDurationId,
      handlePlanSelection,
      handleBack,
      handleNext,
      handleSetPaymentMethod,
      setIsCompareModalOpen,
      subscriptionId,
      userData?.planId,
      userData?.durationId,
      boletoUrl,
    ],
  );

  return (
    <Paper sx={{ p: 3, mb: 4, backgroundColor: theme.palette.background.paper, borderRadius: 2 }}>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep < 2 && selectedPlanId && (
        <Box
          sx={{ mb: 3, px: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" color="text.secondary">
            Plano selecionado: {plans.find((p) => p.id === selectedPlanId)?.name} (
            {planDurations.find((d) => d.id === selectedDurationId)?.text})
            {activeStep === 1 && paymentMethodId && (
              <>
                <br />
                {paymentMethodId === 'boleto' ? (
                  'Boleto Bancário'
                ) : (
                  <>
                    {(() => {
                      const method = paymentMethods.find((m) => m.id === paymentMethodId);
                      if (method?.type === 'card') {
                        return (
                          <>
                            {method.brand.toUpperCase()} •••• {method.last4}
                          </>
                        );
                      }
                      return null;
                    })()}
                  </>
                )}
              </>
            )}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CompareArrowsIcon />}
            onClick={() => setIsCompareModalOpen(true)}
            sx={{ ml: 2 }}
          >
            Comparar Planos
          </Button>
        </Box>
      )}

      {activeStep === 0 && stepComponents[0]()}
      {activeStep === 1 && stepComponents[1]()}
      {activeStep === 2 && stepComponents[2]()}

      <ComparePaymentPlansModal
        isOpen={isCompareModalOpen}
        onClose={() => setIsCompareModalOpen(false)}
        plans={plans}
        planDurations={planDurations}
        onChoose={(planId, durationId) => {
          handlePlanSelection(planId as PlanId, durationId as DurationId);
          setIsCompareModalOpen(false);
        }}
        primaryButtonText={
          userData?.subscriptionStatus === SubscriptionStatus.TRIALING
            ? 'Escolher Plano'
            : 'Começar Agora'
        }
        disabledPlans={
          subscriptionId
            ? [
                {
                  planId: userData?.planId as PlanId,
                  durationId: userData?.durationId as DurationId,
                  message: 'Você já está inscrito neste plano',
                },
              ]
            : []
        }
        featuredPlanId={PlanId.PREMIUM}
      />
    </Paper>
  );
};

export default SubscriptionFlow;
