import BackToTop from '@components/Layout/BackToTop';
import ListControls from '@components/Layout/ListControls';
import Pagination, { PaginationInfo } from '@components/Layout/Pagination';
import Recommendations from '@components/Recommended/Recommendations';
import LicitacaoCard from '@components/Search/LicitacaoCard';
import LicitacaoCardSkeleton from '@components/Search/LicitacaoCardSkeleton';
import LicitacaoColumnCard from '@components/Search/LicitacaoColumnCard';
import LicitacaoColumnCardSkeleton from '@components/Search/LicitacaoColumnCardSkeleton';
import SavedSearches from '@components/Search/SavedSearches';
import LicitacoesSearchBox from '@components/Search/SearchFilters';
import SearchWelcomeMessage from '@components/Search/SearchWelcomeMessage';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Box, Button, Grid, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { useProfileStore } from '@store/profileStore';
import {
  Licitacao,
  LicitacaoSortOption,
  LicitacoesSearchParams,
  RecommendedLicitacao,
} from '@types';
import { parseSearchParams } from '@utils';
import { isEqual } from '@utils/isEqual';
import { scrollToTop } from '@utils/scroll';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

const Licitacoes: React.FC = () => {
  const { currentProfileId, userPreferences } = useProfileStore();
  const { showNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();
  const {
    licitacoes,
    isLoading,
    error,
    totalLicitacoes,
    fetchLicitacoes,
    sortOption,
    setSortOption,
    itemsPerPage,
    totalPages,
    lastSearchParams,
    viewFormat,
    setViewFormat,
    setItemsPerPage,
    resetLastSearchParams,
    clearResults,
  } = useLicitacoesStore();
  const { trackEvent } = useAnalytics();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams, setSearchParams] = useState<LicitacoesSearchParams>({});
  const [isSearching, setIsSearching] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
  const [page, setPage] = useState(() => {
    const urlParams = new URLSearchParams(location.search);
    return parseInt(urlParams.get('pagina') || '1', 10);
  });
  const isFirstMount = useRef(true);

  const [activeSearchId, setActiveSearchId] = useState<string | null>(null);

  const updateURL = useCallback(
    (params: LicitacoesSearchParams, pageNum: number) => {
      const urlSearchParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => urlSearchParams.append(key, v.toString()));
        } else if (value !== undefined && value !== '' && value !== null) {
          urlSearchParams.append(key, value.toString());
        }
      });
      urlSearchParams.set('pagina', pageNum.toString());
      navigate({ search: urlSearchParams.toString() }, { replace: true });
    },
    [navigate],
  );

  const normalizeParams = (params: LicitacoesSearchParams) => {
    const normalized = { ...params };

    Object.entries(normalized).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        delete normalized[key as keyof LicitacoesSearchParams];
      } else if (value === '' || value === undefined) {
        delete normalized[key as keyof LicitacoesSearchParams];
      } else if (typeof value === 'boolean') {
        (normalized as LicitacoesSearchParams)[key as keyof LicitacoesSearchParams] = value as any;
      }
    });

    // Add standard params
    normalized.pagina = normalized.pagina || 1;
    normalized.licitacoesPorPagina = normalized.licitacoesPorPagina || itemsPerPage;
    normalized.sortBy = normalized.sortBy || sortOption;

    return normalized;
  };

  const loadLicitacoes = useCallback(
    async (pageNum: number, params: LicitacoesSearchParams, forceRefresh = false) => {
      const normalizedNewParams = normalizeParams({ ...params, pagina: pageNum });
      const normalizedCurrentParams = normalizeParams(searchParams);

      if (!forceRefresh && (isSearching || isEqual(normalizedNewParams, normalizedCurrentParams))) {
        return;
      }
      setSearchParams(normalizedNewParams);

      setIsSearching(true);
      try {
        await fetchLicitacoes(normalizedNewParams);
        setPage(pageNum);
        updateURL(params, pageNum);
      } catch (error) {
        showNotification('Erro ao carregar licitações', 'error');
      } finally {
        setIsSearching(false);
      }
    },
    [
      fetchLicitacoes,
      itemsPerPage,
      updateURL,
      page,
      searchParams,
      isSearching,
      sortOption,
      setSearchParams,
      showNotification,
    ],
  );

  const handleSearch = useCallback(
    (params: Partial<LicitacoesSearchParams>, forceRefresh = false) => {
      const newParams = params as LicitacoesSearchParams;

      const keywords = params.palavra_chave
        ? Array.isArray(params.palavra_chave)
          ? params.palavra_chave
          : [params.palavra_chave]
        : userPreferences?.keywords || [];

      setSearchKeywords(keywords);

      trackEvent('search_performed', {
        keywords: keywords,
        filters: JSON.stringify(newParams),
      });

      loadLicitacoes(1, newParams, forceRefresh);
    },
    [loadLicitacoes, userPreferences?.keywords],
  );

  const handleRefresh = useCallback(() => {
    handleSearch({ ...searchParams, pagina: page }, true);
  }, [handleSearch, searchParams, page]);

  const handleSortOptionChange = useCallback(
    (value: LicitacaoSortOption) => {
      setSortOption(value);
    },
    [setSortOption],
  );

  useEffect(() => {
    if (error) {
      const errorData = {
        error_message: error,
        filters: JSON.stringify(searchParams),
        page,
      };
      trackEvent('search_error', errorData);
      showNotification(error, 'error');
    }
  }, [error]);

  // Single effect to handle both initial load and subsequent URL changes
  useEffect(() => {
    const urlParams = parseSearchParams(location.search);
    const pageFromUrl = parseInt(new URLSearchParams(location.search).get('pagina') || '1', 10);

    if (isFirstMount.current) {
      isFirstMount.current = false;
      if (Object.keys(urlParams).length > 0) {
        setSearchParams(urlParams);
        setSearchKeywords(urlParams.palavra_chave || []);
        loadLicitacoes(pageFromUrl, urlParams);
      }
    } else if (!isSearching && location.search !== '?') {
      // Only load if URL params changed and we're not clearing filters
      const normalizedUrlParams = normalizeParams({ ...urlParams, pagina: pageFromUrl });
      const normalizedCurrentParams = normalizeParams(searchParams);

      if (JSON.stringify(normalizedUrlParams) !== JSON.stringify(normalizedCurrentParams)) {
        setSearchParams(urlParams);
        setSearchKeywords(urlParams.palavra_chave || []);
        loadLicitacoes(pageFromUrl, urlParams);
      }
    }
  }, [location.search, currentProfileId]);

  const handleSavedSearchSelect = useCallback(
    (params: LicitacoesSearchParams, searchId: string) => {
      setActiveSearchId(searchId);
      handleSearch(params);
    },
    [handleSearch],
  );

  const clearAllFilters = useCallback(() => {
    setActiveSearchId(null);
    setSearchParams({});
    setSearchKeywords([]);
    clearResults();

    // Clear URL without triggering a search
    navigate('?', { replace: true });

    // Reset store state without triggering a search
    resetLastSearchParams();
  }, [navigate, resetLastSearchParams, clearResults]);

  const hasResults = useMemo(() => licitacoes.length > 0, [licitacoes]);
  const showWelcomeMessage = useMemo(
    () => !isLoading && !hasResults && !lastSearchParams,
    [isLoading, hasResults, lastSearchParams],
  );
  const showNoResults = useMemo(
    () => !isLoading && !hasResults && !!lastSearchParams,
    [isLoading, hasResults, lastSearchParams],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage !== page && !isSearching) {
        loadLicitacoes(newPage, searchParams);
        scrollToTop('search-scroll-anchor');
      }
    },
    [loadLicitacoes, searchParams, page, isSearching],
  );

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
      trackEvent('search_error', {
        error_message: error,
        filters: JSON.stringify(searchParams),
        page,
      });
    }
  }, [error]);

  // useEffect(() => {
  //   if (currentProfileId && !isFirstMount.current) {
  //     clearAllFilters();
  //   }
  // }, [currentProfileId, clearAllFilters]);

  const effectiveViewFormat = useMemo(
    () => (isMobile ? 'column' : viewFormat),
    [isMobile, viewFormat],
  );

  const loadingSkeletons = useMemo(
    () => (
      <Grid container spacing={2}>
        {effectiveViewFormat === 'column' ? (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <LicitacaoColumnCardSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <LicitacaoCardSkeleton key={index} />
            ))}
          </Box>
        )}
      </Grid>
    ),
    [effectiveViewFormat],
  );

  const licitacoesList = useMemo(
    () =>
      effectiveViewFormat === 'column' ? (
        <>
          {licitacoes.map((item: Licitacao | RecommendedLicitacao, index: number) => (
            <Grid item xs={12} sm={6} md={4} key={`${item.id}-${page}`}>
              <LicitacaoColumnCard
                className="licitacao-card"
                item={item}
                isFirst={index === 0}
                searchKeywords={lastSearchParams?.palavra_chave || searchKeywords}
              />
            </Grid>
          ))}
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
          {licitacoes.map((item: Licitacao | RecommendedLicitacao, index: number) => (
            <LicitacaoCard
              key={`${item.id}-${page}`}
              className="licitacao-card"
              item={item}
              isFirst={index === 0}
              searchKeywords={lastSearchParams?.palavra_chave || searchKeywords}
            />
          ))}
        </Box>
      ),
    [licitacoes, page, effectiveViewFormat, lastSearchParams?.palavra_chave, searchKeywords],
  );

  return (
    <>
      <Helmet>
        <title>Buscar Licitações - Licitou</title>
        <meta
          name="description"
          content="Busque licitações por palavras-chave, localização e outros filtros"
        />
      </Helmet>
      <div id="search-scroll-anchor"></div>
      <LicitacoesSearchBox
        handleSearch={handleSearch}
        clearAllFilters={clearAllFilters}
        isLoading={isLoading || isSearching}
        initialValues={searchParams}
        className="search-box"
      />
      <SavedSearches
        currentSearchParams={lastSearchParams || searchParams}
        onSearchSelect={handleSavedSearchSelect}
        activeSearchId={activeSearchId}
      />
      <Box className="search-results">
        <SearchWelcomeMessage
          lastSearchParams={lastSearchParams}
          visible={showWelcomeMessage || showNoResults}
        />
        {(showWelcomeMessage || showNoResults) && (
          <Box mt={4}>
            <Recommendations />
          </Box>
        )}
        <Box sx={{ display: hasResults ? 'block' : 'none' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" my={4}>
            <Box display="flex" alignItems="center" gap={2}>
              <Select
                value={sortOption}
                onChange={(e) => handleSortOptionChange(e.target.value as LicitacaoSortOption)}
                size="small"
              >
                <MenuItem value="relevance">Relevância</MenuItem>
                <MenuItem value="date">Mais Novas</MenuItem>
                <MenuItem value="price">Maior Preço</MenuItem>
                <MenuItem value="opening_soon">Abrindo em Breve</MenuItem>
              </Select>
              <Button
                variant="outlined"
                size="small"
                onClick={clearAllFilters}
                startIcon={!isMobile ? <SearchOffIcon /> : undefined}
                sx={{
                  borderColor: 'divider',
                  color: 'text.secondary',
                  '&:hover': {
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    bgcolor: 'action.hover',
                  },
                }}
              >
                {isMobile ? <SearchOffIcon /> : 'Nova Busca'}
              </Button>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <PaginationInfo
                currentPage={page}
                totalPages={totalPages}
                totalItems={totalLicitacoes}
                itemsPerPage={itemsPerPage}
                isLoading={isLoading}
                id="search-scroll-anchor"
              />
              <ListControls
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={(value) => setItemsPerPage(value)}
                viewFormat={effectiveViewFormat}
                onViewFormatChange={(value) => setViewFormat(value)}
                onRefresh={handleRefresh}
                disabled={isLoading}
              />
            </Box>
          </Box>

          <Grid container spacing={2} className="search-results-grid">
            {isLoading && licitacoes.length === 0 ? loadingSkeletons : licitacoesList}
          </Grid>

          {totalLicitacoes > 0 && (
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              totalItems={totalLicitacoes}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              isLoading={isLoading}
            />
          )}
        </Box>
      </Box>
      <BackToTop />
    </>
  );
};

export default React.memo(Licitacoes);
