import SubscriptionModal from '@components/Payment/SubscriptionModal';
import { features, plans } from '@constants/plans';
import { useAnalytics } from '@hooks/useAnalytics';
import { usePaymentStore } from '@store/paymentStore';
import { FeatureId, PlanId } from '@types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface FeatureContextType {
  openUpgradeModal: (featureId?: FeatureId) => void;
  getRecommendedPlan: (featureId?: FeatureId) => PlanId;
  trackUpgradeClick: (featureId?: FeatureId, metadata?: Record<string, any>) => void;
}

const FeatureContext = createContext<FeatureContextType | null>(null);

export const FeatureProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState<FeatureId | undefined>();
  const { trackEvent } = useAnalytics();
  const { fetchPaymentInfo, currentPlans, isLoading } = usePaymentStore();

  const getRecommendedPlan = useCallback((featureId?: FeatureId) => {
    if (!featureId) return PlanId.PREMIUM;

    const feature = features[featureId];
    if (!feature) return PlanId.PREMIUM;

    const planWithFeature = plans.find((plan) => plan.features.includes(featureId));
    return planWithFeature?.id || PlanId.PREMIUM;
  }, []);

  const openUpgradeModal = useCallback(
    (featureId?: FeatureId) => {
      if (!isLoading && !currentPlans.length) {
        fetchPaymentInfo();
      }
      setSelectedFeature(featureId);
      setIsModalOpen(true);
    },
    [isLoading, currentPlans.length, fetchPaymentInfo],
  );

  const trackUpgradeClick = useCallback(
    (featureId?: FeatureId, metadata: Record<string, any> = {}) => {
      trackEvent('upgrade', {
        action: 'button_clicked',
        feature_id: featureId,
        recommended_plan: getRecommendedPlan(featureId),
        ...metadata,
      });
    },
    [trackEvent, getRecommendedPlan],
  );

  const value = useMemo(
    () => ({
      openUpgradeModal,
      getRecommendedPlan,
      trackUpgradeClick,
    }),
    [openUpgradeModal, getRecommendedPlan, trackUpgradeClick],
  );

  return (
    <FeatureContext.Provider value={value}>
      {children}
      <SubscriptionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialPlanId={getRecommendedPlan(selectedFeature)}
        title="Fazer Upgrade"
      />
    </FeatureContext.Provider>
  );
};

export const useFeature = () => {
  const context = useContext(FeatureContext);
  if (!context) {
    throw new Error('useFeature must be used within a FeatureProvider');
  }
  return context;
};
