import { Box, Card, CardContent, Skeleton } from '@mui/material';

const RecommendedCardSkeleton = () => {
  return (
    <Card
      sx={{
        height: '100%',
        minHeight: '150px',
        boxShadow: 3,
        position: 'relative',
      }}
    >
      <CardContent
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box>
          <Box display="flex" alignItems="flex-start" gap={1} mb={0.5}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton width="80%" height={24} />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton width="60%" height={20} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 0.5,
            mb: 0.5,
          }}
        >
          {[...Array(4)].map((_, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <Skeleton variant="circular" width={16} height={16} />
              <Skeleton width="70%" height={16} />
            </Box>
          ))}
        </Box>

        <Box sx={{ flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              minHeight: 0,
              overflow: 'hidden',
            }}
          >
            <Skeleton width="100%" height={16} />
            <Skeleton width="95%" height={16} />
            <Skeleton width="90%" height={16} />
            <Skeleton width="85%" height={16} />
          </Box>
          <Box mt="auto" pt={0.5}>
            <Skeleton width="100%" height={32} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RecommendedCardSkeleton;
