import { useNotification } from '@contexts/NotificationContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { AddressElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';

if (!process.env.REACT_APP_STRIPE_KEY) {
  throw new Error('REACT_APP_STRIPE_KEY is not set');
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

interface TaxInfoDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const TaxInfoDialog: React.FC<TaxInfoDialogProps> = ({ open, onClose, onSubmit }) => {
  const { userData, updateTaxInfo } = useProfileStore();
  const { showNotification } = useNotification();
  const [taxId, setTaxId] = useState(userData?.taxId || '');
  const [taxName, setTaxName] = useState(userData?.taxName || userData?.name || '');
  const [addressComplete, setAddressComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressValue, setAddressValue] = useState<any | null>(null);
  const theme = useTheme();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!addressValue) {
        throw new Error('Endereço incompleto');
      }
      const taxIdCleaned = taxId.replace(/\D/g, '');
      if (taxIdCleaned.length !== 11 && taxIdCleaned.length !== 14) {
        throw new Error('CPF/CNPJ inválido');
      }

      if (taxName.length < 3) {
        throw new Error('Nome/Razão Social inválido');
      }

      await updateTaxInfo(taxIdCleaned, taxName, addressValue.address);
      await onSubmit();
      onClose();
    } catch (error: any) {
      showNotification(error.message || 'Erro ao atualizar dados fiscais', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isNewTaxInfo = taxId !== userData?.taxId || taxName !== userData?.taxName;
  const buttonLabel = isNewTaxInfo ? 'Salvar e Continuar' : 'Continuar';

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Dados Fiscais para Boleto</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Dados do Pagador
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="CPF/CNPJ"
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
                fullWidth
                required
                disabled={isSubmitting}
                helperText="Este CPF/CNPJ será usado para gerar o boleto"
              />
              <TextField
                label="Nome/Razão Social"
                value={taxName}
                onChange={(e) => setTaxName(e.target.value)}
                fullWidth
                required
                disabled={isSubmitting}
                helperText="Este nome será usado para gerar o boleto"
              />
            </Box>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Endereço de Cobrança
            </Typography>
            <Elements
              stripe={stripePromise}
              options={{
                appearance: {
                  theme: theme.palette.mode === 'dark' ? 'night' : 'stripe',
                  variables: {
                    colorText: theme.palette.text.primary,
                    colorTextSecondary: theme.palette.text.secondary,
                    colorBackground: theme.palette.background.paper,
                    colorPrimary: theme.palette.primary.main,
                  },
                },
              }}
            >
              <AddressElement
                options={{
                  mode: 'billing',
                  defaultValues: {
                    name: userData?.name || '',
                    address: {
                      line1: userData?.address?.street || '',
                      line2: userData?.address?.complement || '',
                      city: userData?.address?.city || '',
                      state: userData?.address?.state || '',
                      postal_code: userData?.address?.postalCode || '',
                      country: 'BR',
                    },
                  },
                  fields: {
                    phone: 'never',
                  },
                  display: {
                    name: 'organization',
                  },
                }}
                onChange={(event) => {
                  setAddressComplete(event.complete);
                  setAddressValue(event.value);
                }}
              />
            </Elements>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!taxId || !taxName || !addressComplete || isSubmitting}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaxInfoDialog;
