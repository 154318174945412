import EmptyStateBox from '@components/Common/EmptyStateBox';
import FollowingButton from '@components/Following/FollowingButton';
import ListControls from '@components/Layout/ListControls';
import Pagination, { PaginationInfo } from '@components/Layout/Pagination';
import StatusFilter from '@components/Layout/StatusFilter';
import Recommendations from '@components/Recommended/Recommendations';
import LicitacaoCard from '@components/Search/LicitacaoCard';
import LicitacaoCardSkeleton from '@components/Search/LicitacaoCardSkeleton';
import LicitacaoColumnCard from '@components/Search/LicitacaoColumnCard';
import LicitacaoColumnCardSkeleton from '@components/Search/LicitacaoColumnCardSkeleton';
import { useNotification } from '@contexts/NotificationContext';
import { useProfile } from '@contexts/ProfileContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFollowingStore } from '@store/followingStore';
import { useProfileStore } from '@store/profileStore';
import { LicitacaoStatusFilter } from '@types';
import { scrollToTop } from '@utils/scroll';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

const FollowingLicitacoes: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showNotification } = useNotification();
  const {
    following,
    followingLicitacoes,
    fetchFollowingLicitacoes,
    isLoading,
    error,
    currentPage,
    totalFollowing,
    totalPages,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    viewFormat,
    setViewFormat,
    status,
    setStatus,
  } = useFollowingStore();
  const { currentProfileId } = useProfile();
  const { trackEvent } = useAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userPreferences } = useProfileStore();

  // Force column view on mobile, otherwise use store preference
  const effectiveViewFormat = useMemo(
    () => (isMobile ? 'column' : viewFormat),
    [isMobile, viewFormat],
  );

  const loadData = useCallback(
    async (page: number, forceRefresh = false) => {
      try {
        await fetchFollowingLicitacoes(page, forceRefresh);
      } catch (error) {
        trackEvent('following_error', {
          action: 'load',
          error_message: error instanceof Error ? error.message : String(error),
          page,
          force_refresh: forceRefresh,
        });
        showNotification('Erro ao carregar licitações acompanhadas', 'error');
      }
    },
    [fetchFollowingLicitacoes, trackEvent, totalFollowing, itemsPerPage, showNotification],
  );

  useEffect(() => {
    const pageParam = parseInt(searchParams.get('pagina') || '1', 10);
    if (pageParam !== currentPage) {
      setCurrentPage(pageParam);
      loadData(pageParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentProfileId) {
      loadData(1, true);
    }
  }, [currentProfileId]);

  // refresh when user add or remove a following
  useEffect(() => {
    loadData(1, true);
  }, [following]);

  useEffect(() => {
    if (error) {
      trackEvent('following_error', {
        error_message: error,
        current_page: currentPage,
      });
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  const handleRefresh = useCallback(() => {
    loadData(currentPage, true);
  }, [loadData, currentPage]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      searchParams.set('pagina', newPage.toString());
      setSearchParams(searchParams);
      scrollToTop('following-scroll-anchor');
    },
    [searchParams, setSearchParams],
  );

  const handleStatusChange = useCallback(
    (newStatus: LicitacaoStatusFilter) => {
      setStatus(newStatus);
    },
    [status, setStatus],
  );

  const loadingSkeletons = useMemo(
    () => (
      <Grid container spacing={2}>
        {effectiveViewFormat === 'column' ? (
          <>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <LicitacaoColumnCardSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            {[...Array(5)].map((_, index) => (
              <LicitacaoCardSkeleton key={index} />
            ))}
          </Box>
        )}
      </Grid>
    ),
    [effectiveViewFormat],
  );

  const followingListContent = useMemo(
    () =>
      effectiveViewFormat === 'column' ? (
        <>
          {followingLicitacoes.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${item.id}-${currentPage}`}>
              <LicitacaoColumnCard className="following-card" item={item} isFirst={index === 0} />
            </Grid>
          ))}
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
          {followingLicitacoes.map((item, index) => (
            <LicitacaoCard
              key={`${item.id}-${currentPage}`}
              className="following-card"
              item={item}
              isFirst={index === 0}
            />
          ))}
        </Box>
      ),
    [followingLicitacoes, currentPage, effectiveViewFormat, userPreferences?.keywords],
  );

  return (
    <>
      <Helmet>
        <title>{`Licitações Acompanhadas (${totalFollowing}) - Licitou`}</title>
        <meta
          name="description"
          content="Visualize e gerencie todas as licitações que você está acompanhando"
        />
      </Helmet>
      <Box id="following-list" className="following-list">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h6" component="h2" color="primary">
              Licitações Acompanhadas
            </Typography>
            <StatusFilter value={status} onChange={handleStatusChange} disabled={isLoading} />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <PaginationInfo
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalFollowing}
              itemsPerPage={itemsPerPage}
              isLoading={isLoading}
              id="following-scroll-anchor"
            />
            <ListControls
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={(value) => setItemsPerPage(value)}
              viewFormat={effectiveViewFormat}
              onViewFormatChange={(value) => setViewFormat(value)}
              onRefresh={handleRefresh}
              disabled={isLoading}
            />
          </Box>
        </Box>
        <Grid container spacing={2} className="following-grid">
          {followingListContent}
        </Grid>

        {totalFollowing > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalFollowing}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
          />
        )}

        {isLoading && followingLicitacoes.length === 0 && loadingSkeletons}
        {!isLoading && !error && totalFollowing === 0 && (
          <>
            <EmptyStateBox
              title="Você ainda não está acompanhando nenhuma licitação"
              className="following-empty"
            >
              <Typography color="text.secondary" paragraph>
                Ao navegar pelas licitações, favorite uma licitação e então clique no botão{' '}
                <FollowingButton /> de uma licitação para estar disponível aqui.
              </Typography>
              <Typography color="text.secondary">
                Acompanhar uma licitação é uma forma de receber notificações sempre que houver
                atualizações ou alterações na licitação.
              </Typography>
              <Typography color="text.secondary">
                E você também poderá adicionar elas ao seu quadro kanban e ela aparecerá em sua
                agenda.
              </Typography>
            </EmptyStateBox>
            <Box mt={4}>
              <Recommendations title="Licitações recomendadas para você" />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default FollowingLicitacoes;
