import UpgradePrompt from '@components/Feature/UpgradePrompt';
import { AI_CHAT_EXAMPLE_QUESTIONS } from '@constants';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import {
  Autorenew as AutorenewIcon,
  Close as CloseIcon,
  Feedback as FeedbackIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useAIStore } from '@store/aiStore';
import { FeatureId, Licitacao } from '@types';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import AIChatFeedback from './AIChatFeedback';

interface AIChatModalProps {
  open: boolean;
  onClose: () => void;
  licitacao: Licitacao;
}

const MAX_RANDOM_QUESTIONS = 5;

// Lazy load components with chunk names
const ReactMarkdown = lazy(() => import(/* webpackChunkName: "react-markdown" */ 'react-markdown'));

const AIChatModal: React.FC<AIChatModalProps> = ({ open, onClose, licitacao }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [question, setQuestion] = useState('');
  const { messages, isLoading, error, sendMessage, clearMessages, setError } = useAIStore();
  const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const { hasAccess, reason } = useFeatureAccess(FeatureId.AI_ANALYSIS);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (open) {
      const shuffled = [...AI_CHAT_EXAMPLE_QUESTIONS].sort(() => 0.5 - Math.random());
      setRandomQuestions(shuffled.slice(0, MAX_RANDOM_QUESTIONS));
    } else {
      clearMessages();
    }
  }, [open]);

  useEffect(() => {
    if (error) {
      trackEvent('ai_chat_error', {
        licitacao_id: licitacao.id,
        error_message: error,
        total_messages: messages.length,
      });
      showNotification('Erro ao processar a pergunta. Por favor, tente novamente.', 'error');
    }
  }, [error]);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async () => {
    if (!question.trim()) return;

    trackEvent('ai_chat', {
      action: 'send_question',
      licitacao_id: licitacao.id,
      question_length: question.length,
      total_messages: messages.length,
      is_example: false,
    });

    await sendMessage(licitacao.id, question);
    setQuestion('');
  };

  const handleExampleQuestionClick = (question: string) => {
    trackEvent('ai_chat', {
      action: 'send_question',
      licitacao_id: licitacao.id,
      question,
      total_messages: messages.length,
      is_example: true,
    });

    sendMessage(licitacao.id, question);
    setQuestion('');
  };

  const handleFeedbackOpen = () => {
    setFeedbackOpen(true);
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  const darkModeBgColor = '#1e1e1e';

  const formatMessage = (content: string) => {
    const sections = content.split('\n\n');
    return (
      <Suspense fallback={<div>Carregando o chat ...</div>}>
        {sections.map((section, index) => {
          if (section.startsWith('*')) {
            const [title, ...items] = section.split('\n');
            return (
              <Box key={index} mb={2}>
                <Typography variant="subtitle1">{title.replace('* ', '')}</Typography>
                <List dense>
                  {items.map((item, itemIndex) => (
                    <ListItem key={itemIndex}>
                      <ListItemText
                        primary={
                          <ReactMarkdown
                            components={{
                              p: ({ children }) => (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: theme.palette.getContrastText(
                                      theme.palette.mode === 'dark'
                                        ? darkModeBgColor
                                        : theme.palette.background.paper,
                                    ),
                                  }}
                                >
                                  {children}
                                </Typography>
                              ),
                            }}
                          >
                            {item.trim()}
                          </ReactMarkdown>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            );
          } else {
            return (
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  color: theme.palette.getContrastText(
                    theme.palette.mode === 'dark'
                      ? darkModeBgColor
                      : theme.palette.background.paper,
                  ),
                }}
              >
                <ReactMarkdown>{section}</ReactMarkdown>
              </Typography>
            );
          }
        })}
      </Suspense>
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((event.key === 'Enter' && !event.shiftKey) || (isMobile && event.key === 'unidentified')) {
      event.preventDefault();
      if (question.trim() && !isLoading) {
        handleSubmit();
      }
    }
  };

  if (!hasAccess) {
    trackEvent('ai_chat_error', {
      action: 'not_available',
      licitacao_id: licitacao.id,
      reason: reason,
    });
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Análise com IA</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UpgradePrompt
            message="Análise de editais com IA não disponível no seu plano"
            reason={reason}
            featureId={FeatureId.AI_ANALYSIS}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            bgcolor:
              theme.palette.mode === 'dark' ? darkModeBgColor : theme.palette.background.paper,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: theme.palette.getContrastText(
              theme.palette.mode === 'dark' ? darkModeBgColor : theme.palette.background.paper,
            ),
            p: isMobile ? 1.5 : 2,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant={isMobile ? 'subtitle1' : 'h6'}>Pergunte à LicitAI</Typography>
            <Box>
              {!isMobile && (
                <Button
                  startIcon={<FeedbackIcon />}
                  onClick={handleFeedbackOpen}
                  size="small"
                  sx={{ mr: 1, textTransform: 'none' }}
                  color="primary"
                >
                  O que achou da LicitAI?
                </Button>
              )}
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ p: isMobile ? 1.5 : 2 }}>
          <Typography
            variant={isMobile ? 'body1' : 'subtitle1'}
            gutterBottom
            sx={{
              color: theme.palette.getContrastText(
                theme.palette.mode === 'dark' ? darkModeBgColor : theme.palette.background.paper,
              ),
              fontSize: isMobile ? '0.875rem' : 'inherit',
            }}
          >
            {isMobile
              ? `${licitacao.titulo.slice(0, 100)}${licitacao.titulo.length > 100 ? '...' : ''}`
              : licitacao.titulo}
          </Typography>

          {messages.length > 0 ? (
            <Box
              mt={isMobile ? 1 : 2}
              maxHeight={isMobile ? 'calc(100vh - 250px)' : 400}
              overflow="auto"
            >
              {messages.map((message) => (
                <Box
                  key={message.id}
                  mb={isMobile ? 1 : 2}
                  textAlign={message.isUser ? 'right' : 'left'}
                >
                  <Box
                    sx={{
                      bgcolor: message.isUser
                        ? theme.palette.primary.main
                        : theme.palette.mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.05)'
                          : theme.palette.grey[200],
                      color: message.isUser
                        ? theme.palette.primary.contrastText
                        : theme.palette.getContrastText(
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.05)'
                              : theme.palette.grey[200],
                          ),
                      p: isMobile ? 1.5 : 2,
                      borderRadius: 2,
                      display: 'inline-block',
                      maxWidth: '90%',
                      wordBreak: 'break-word',
                      fontSize: isMobile ? '0.875rem' : 'inherit',
                    }}
                  >
                    {message.isUser ? (
                      <Typography variant={isMobile ? 'body2' : 'body1'}>
                        {message.content}
                      </Typography>
                    ) : (
                      formatMessage(message.content)
                    )}
                  </Box>
                </Box>
              ))}
              {isLoading && (
                <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                  <AutorenewIcon sx={{ animation: 'spin 2s linear infinite', mr: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    {isMobile
                      ? 'Processando...'
                      : 'Processando sua pergunta... Isso pode levar alguns segundos.'}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              mt={isMobile ? 1 : 2}
              sx={{
                fontSize: isMobile ? '0.813rem' : '0.875rem',
                '& .MuiTypography-root': { fontSize: 'inherit' },
              }}
            >
              <Typography
                variant={isMobile ? 'subtitle1' : 'h6'}
                gutterBottom
                sx={{ fontSize: isMobile ? '0.9rem' : '1rem !important' }}
              >
                Olá! Como posso ajudar? 👋
              </Typography>

              <Typography variant="body2" paragraph>
                {isMobile
                  ? 'Pergunte sobre qualquer aspecto desta licitação.'
                  : 'Pergunte sobre qualquer aspecto desta licitação. Estou aqui para ajudar!'}
              </Typography>

              <Box
                display="grid"
                gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'}
                gap={isMobile ? 0.5 : 1}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="primary"
                    sx={{ fontSize: isMobile ? '0.7rem' : '0.8rem !important' }}
                  >
                    Análise do Edital
                  </Typography>
                  <List dense sx={{ py: 0 }}>
                    <ListItem sx={{ py: 0.25 }}>
                      <ListItemText
                        primary="Requisitos técnicos"
                        primaryTypographyProps={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0.25 }}>
                      <ListItemText
                        primary="Critérios de habilitação"
                        primaryTypographyProps={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0.25 }}>
                      <ListItemText
                        primary="Prazos importantes"
                        primaryTypographyProps={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}
                      />
                    </ListItem>
                  </List>
                </Box>

                <Box>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="primary"
                    sx={{ fontSize: isMobile ? '0.7rem' : '0.8rem !important' }}
                  >
                    Documentação
                  </Typography>
                  <List dense sx={{ py: 0 }}>
                    <ListItem sx={{ py: 0.25 }}>
                      <ListItemText
                        primary="Documentos necessários"
                        primaryTypographyProps={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0.25 }}>
                      <ListItemText
                        primary="Exigências específicas"
                        primaryTypographyProps={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0.25 }}>
                      <ListItemText
                        primary="Certificações"
                        primaryTypographyProps={{ fontSize: isMobile ? '0.6rem' : '0.75rem' }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Typography variant="caption" color="text.secondary" sx={{ whiteSpace: 'nowrap' }}>
                  Dicas:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  <Chip
                    size="small"
                    label="Seja específico"
                    variant="outlined"
                    sx={{
                      height: '24px',
                      '& .MuiChip-label': {
                        fontSize: '0.75rem',
                        px: 1.5,
                        color: theme.palette.primary.main,
                      },
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                  <Chip
                    size="small"
                    label="Forneça contexto"
                    variant="outlined"
                    sx={{
                      height: '24px',
                      '& .MuiChip-label': {
                        fontSize: '0.75rem',
                        px: 1.5,
                        color: theme.palette.primary.main,
                      },
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                  <Chip
                    size="small"
                    label="Evite perguntas genéricas"
                    variant="outlined"
                    sx={{
                      height: '24px',
                      '& .MuiChip-label': {
                        fontSize: '0.75rem',
                        px: 1.5,
                        color: theme.palette.primary.main,
                      },
                      borderColor: theme.palette.primary.main,
                    }}
                  />
                </Box>
              </Box>

              <Box mt={1}>
                <Typography variant="caption" color="text.secondary" display="block" mb={0.5}>
                  Experimente:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={0.5}>
                  {randomQuestions.map((q, index) => (
                    <Chip
                      key={index}
                      label={q}
                      onClick={() => handleExampleQuestionClick(q)}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{
                        height: '24px',
                        '& .MuiChip-label': {
                          fontSize: '0.75rem',
                          px: 1.5,
                          color: theme.palette.primary.main,
                        },
                        borderColor: theme.palette.primary.main,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.main,
                          '& .MuiChip-label': {
                            color: theme.palette.primary.contrastText,
                          },
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: isMobile ? 1 : 2 }}>
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                mb: 0.5,
                color: theme.palette.warning.main,
                fontStyle: 'italic',
                textAlign: 'center',
                fontSize: isMobile ? '0.7rem' : '0.75rem',
              }}
            >
              ⚠️ Verifique as informações no Edital
            </Typography>
            <TextareaAutosize
              minRows={isMobile ? 3 : 4}
              maxRows={isMobile ? 5 : 6}
              placeholder={
                isMobile
                  ? 'Digite sua pergunta...'
                  : 'Digite sua pergunta aqui... (Enter para enviar)'
              }
              value={question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyPress}
              disabled={isLoading}
              style={{
                width: '100%',
                padding: isMobile ? '10px' : '12px',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                borderRadius: '4px',
                fontFamily: 'inherit',
                fontSize: isMobile ? '0.875rem' : '1rem',
                resize: 'vertical',
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: isMobile ? 1 : 1.5 }}>
          {isMobile && (
            <Button
              startIcon={<FeedbackIcon />}
              onClick={handleFeedbackOpen}
              size="small"
              sx={{ mr: 'auto' }}
              color="primary"
            >
              Feedback
            </Button>
          )}
          <Button onClick={handleClose} color="primary" size={isMobile ? 'small' : 'medium'}>
            Fechar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size={isMobile ? 'small' : 'medium'}
            startIcon={isLoading ? <CircularProgress size={16} color="inherit" /> : <SendIcon />}
            disabled={!question.trim() || isLoading}
          >
            {isLoading ? 'Processando...' : 'Enviar'}
          </Button>
        </DialogActions>
      </Dialog>
      <AIChatFeedback open={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
    </>
  );
};

export default AIChatModal;
