import { useProfileStore } from '@store/profileStore';
import { FeatureAccessResult, FeatureId } from '@types';
import { useMemo } from 'react';

export function useFeatureAccess(featureId: FeatureId, count?: number): FeatureAccessResult {
  const { checkFeatureAccess, userData, isInitialized } = useProfileStore();

  return useMemo(() => {
    if (!isInitialized) {
      return { hasAccess: false, reason: 'Carregando...' };
    }
    return checkFeatureAccess(featureId, count);
  }, [
    featureId,
    count,
    checkFeatureAccess,
    userData?.subscriptionStatus,
    userData?.planId,
    isInitialized,
  ]);
}
