import PromoteFavoritesModal from '@components/Following/PromoteFavoritesModal';
import { useNotification } from '@contexts/NotificationContext';
import { Add as AddIcon, CalendarMonth, List, ViewKanban } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Following: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { totalFollowing, isLoading, error } = useFollowingStore();
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const { showNotification } = useNotification();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/acompanhando/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'lista';

  const TabLabel: React.FC<{ label: string; icon: React.ReactNode }> = ({ label, icon }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      {React.cloneElement(icon as React.ReactElement, { sx: { mr: 1 } })}
      {label}
    </Box>
  );

  const handlePromoteSuccess = () => {
    showNotification('Licitações promovidas com sucesso', 'success');
  };

  const getViewTitle = () => {
    switch (value) {
      case 'lista':
        return 'Lista';
      case 'kanban':
        return 'Kanban';
      case 'agenda':
        return 'Agenda';
      default:
        return 'Lista';
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Acompanhando (${totalFollowing}) - ${getViewTitle()}`}</title>
        <meta
          name="description"
          content="Acompanhe suas licitações em diferentes visualizações: lista, kanban ou agenda"
        />
      </Helmet>
      <Box sx={{ my: isMobile ? 0.5 : 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            borderBottom: 1,
            borderColor: 'divider',
            gap: isMobile ? 2 : 0,
          }}
        >
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            component="h1"
            color="primary"
            sx={{
              width: isMobile ? '100%' : '20%',
              flexShrink: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Acompanhando ({totalFollowing})
            {isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              width: isMobile ? '100%' : 'auto',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="following tabs"
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: isMobile ? '0.875rem' : '1rem',
                  minHeight: '48px',
                  padding: '6px 16px',
                },
                '& .Mui-selected': {
                  fontWeight: 'bold',
                },
              }}
              variant={isMobile ? 'fullWidth' : 'standard'}
            >
              <Tab
                label={<TabLabel label={isMobile ? '' : 'Lista'} icon={<List />} />}
                value="lista"
              />
              <Tab
                label={<TabLabel label={isMobile ? '' : 'Kanban'} icon={<ViewKanban />} />}
                value="kanban"
              />
              <Tab
                label={<TabLabel label={isMobile ? '' : 'Agenda'} icon={<CalendarMonth />} />}
                value="agenda"
              />
            </Tabs>

            <Button
              variant="contained"
              color="primary"
              startIcon={!isMobile && <AddIcon />}
              onClick={() => setIsPromoteModalOpen(true)}
              sx={{ ml: 2 }}
            >
              {isMobile ? <AddIcon /> : 'Promover Favoritos'}
            </Button>
          </Box>
        </Box>

        <PromoteFavoritesModal
          open={isPromoteModalOpen}
          onClose={() => setIsPromoteModalOpen(false)}
          onSuccess={handlePromoteSuccess}
        />
        <Box sx={{ p: isMobile ? 0.5 : 2 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Following;
