import {
  AiProfile,
  AiSearch,
  AiSearchResponse,
  Company,
  Esfera,
  LicitacoesSearchParams,
  Portal,
  SearchQueryParams,
  UserPreferences,
} from '@types';

export const buildAiSearches = (aiProfile: AiProfile): AiSearch[] => {
  const searches = aiProfile.searchQueries.map(
    (query: SearchQueryParams): AiSearch => ({
      name: query.name,
      explanation: query.explicacao,
      params: {
        palavra_chave: query.palavraChave,
        palavras_excluidas: query.palavrasExcluidas,
        uf: query.estados,
        municipio_ibge: query.municipios,
        cnae: query.cnaes,
        valor_min: query.valorMin?.toString(),
        valor_max: query.valorMax?.toString(),
        match_exact: false,
      } as Partial<LicitacoesSearchParams>,
    }),
  );

  return searches;
};

export const getPreferencesAsSearchParams = (
  preferences: UserPreferences,
  company?: Company | null,
): LicitacoesSearchParams => {
  const cnaes: string[] = [];

  if (company?.cnaePrincipal) {
    cnaes.push(company.cnaePrincipal.id);
  }

  if (company?.cnaesSecundarias) {
    cnaes.push(...company.cnaesSecundarias.map((cnae) => cnae.id));
  }

  return {
    uf: preferences.states || [],
    municipio_ibge: preferences.cities || [],
    cnae: cnaes,
    palavra_chave: preferences.keywords || [],
    palavras_excluidas: preferences.excludedKeywords || [],
    modalidade: preferences.modalidades || [],
    esfera: (preferences.esferas?.map(String) as Esfera[]) || [],
    id_portal: (preferences.portals?.map(String) as Portal[]) || [],
    match_exact: false,
  } as Partial<LicitacoesSearchParams>;
};

export const buildAiGeneratedSearch = (response: AiSearchResponse): AiSearch => ({
  name: 'Resultado da Busca AI',
  explanation: response.explanation,
  params: {
    palavra_chave: response.searchParameters.palavraChave,
    palavras_excluidas: response.searchParameters.palavrasExcluidas,
    uf: response.searchParameters.estados,
    municipio_ibge: response.searchParameters.municipios,
    cnae: response.searchParameters.cnaes,
    valor_min: response.searchParameters.valorMin?.toString(),
    valor_max: response.searchParameters.valorMax?.toString(),
    match_exact: false,
  } as Partial<LicitacoesSearchParams>,
});

export const buildPreferencesFromAiSearch = (
  response: AiSearchResponse,
): Partial<UserPreferences> => ({
  states: response.searchParameters.estados || [],
  cities: response.searchParameters.municipios || [],
  keywords: response.searchParameters.palavraChave || [],
  excludedKeywords: response.searchParameters.palavrasExcluidas || [],
});

export const cleanPreferences = (preferences: Partial<UserPreferences>): UserPreferences => {
  const cleanArrayField = (arr: any[] | undefined): string[] =>
    Array.isArray(arr)
      ? arr.filter((item) => item && item.trim && item.trim() !== '' && item !== 'ALL')
      : [];

  return {
    states: cleanArrayField(preferences.states),
    modalidades: cleanArrayField(preferences.modalidades),
    keywords: cleanArrayField(preferences.keywords),
    excludedKeywords: cleanArrayField(preferences.excludedKeywords),
    cities: cleanArrayField(preferences.cities),
    portals: cleanArrayField(preferences.portals) as Portal[],
    esferas: cleanArrayField(preferences.esferas) as Esfera[],
    companySummary: preferences.companySummary || '',
  };
};
