import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Documents: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/documentos/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'documentos';

  return (
    <>
      <Helmet>
        <title>
          {value === 'documentos'
            ? 'Documentos da Empresa - Licitou'
            : 'Modelos de Declarações - Licitou'}
        </title>
        <meta
          name="description"
          content="Gerencie documentos e declarações necessários para participar de licitações"
        />
      </Helmet>
      <Box sx={{ my: isMobile ? 0.5 : 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            borderBottom: 1,
            borderColor: 'divider',
            gap: isMobile ? 2 : 0,
          }}
        >
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            component="h1"
            color="primary"
            sx={{
              width: isMobile ? '100%' : '20%',
              flexShrink: 0,
            }}
          >
            Documentos
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="settings tabs"
            sx={{
              flex: 1,
              width: isMobile ? '100%' : 'auto',
            }}
            variant={isMobile ? 'scrollable' : 'standard'}
            scrollButtons={isMobile ? 'auto' : false}
          >
            <Tab label="Documentos" value="documentos" />
            <Tab label="Modelos de declarações" value="declaracoes" />
            <Tab label="Gerador de Propostas" value="proposta" />
          </Tabs>
        </Box>
        <Box sx={{ p: isMobile ? 0.5 : 2 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Documents;
