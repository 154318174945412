import InfoSection from '@components/Common/InfoSection';
import MessageBox from '@components/Common/MessageBox';
import FeatureGate from '@components/Feature/FeatureGate';
import AIChatModal from '@components/Licitacao/AIChatModal';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import {
  Chat as ChatIcon,
  Gavel as GavelIcon,
  Help as HelpIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FeatureId, Licitacao, PortalService } from '@types';
import { useState } from 'react';

interface DoubtsStepProps {
  licitacao: Licitacao;
  portalService: PortalService;
  portalName?: string;
}

export const DoubtsStep: React.FC<DoubtsStepProps> = ({
  licitacao,
  portalService,
  portalName = 'portal',
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [aiChatOpen, setAIChatOpen] = useState(false);
  const { hasAccess: hasAiAccess } = useFeatureAccess(FeatureId.AI_ANALYSIS);

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: isMobile ? 2 : 3,
        }}
      >
        <MessageBox variant="info" sx={{ mb: isMobile ? 2 : 3 }}>
          Pedidos de esclarecimentos e impugnações devem ser enviados através do site {portalName}{' '}
          ou conforme instruções no edital.
        </MessageBox>

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            width: '100%',
          }}
        >
          {/* Left Column */}
          <Box
            sx={{
              flex: 1,
              width: isMobile ? '100%' : '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: isMobile ? 2 : 3,
            }}
          >
            <InfoSection title="Prazos Importantes" icon={WarningIcon}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Fique atento aos prazos:
              </Typography>
              <ul style={{ paddingLeft: isMobile ? '1rem' : '2rem' }}>
                <li>Esclarecimentos: até 3 dias úteis antes da abertura</li>
                <li>Impugnações: até 3 dias úteis antes da abertura</li>
                <li>Respostas: até 2 dias úteis do recebimento</li>
              </ul>
            </InfoSection>

            {!isMobile && (
              <InfoSection title="Antes de enviar questionamentos">
                <ul>
                  <li>Leia atentamente todo o edital</li>
                  <li>Anote todas as dúvidas encontradas</li>
                  <li>Verifique se a dúvida já foi respondida</li>
                  <li>Agrupe questionamentos similares</li>
                  <li>Prepare as fundamentações legais</li>
                </ul>
              </InfoSection>
            )}

            <InfoSection title="Motivos para Impugnação" icon={GavelIcon}>
              <ul style={{ paddingLeft: isMobile ? '1rem' : '2rem' }}>
                <li>Exigências restritivas ou ilegais</li>
                <li>Especificações técnicas inadequadas</li>
                <li>Prazos inexequíveis</li>
                <li>Critérios obscuros</li>
              </ul>
            </InfoSection>
          </Box>

          {/* Right Column */}
          <Box
            sx={{
              flex: 1,
              width: isMobile ? '100%' : '50%',
              display: 'flex',
              flexDirection: 'column',
              gap: isMobile ? 2 : 3,
            }}
          >
            <InfoSection title="Tire suas dúvidas" icon={ChatIcon}>
              <Stack spacing={isMobile ? 1 : 2}>
                <Box>
                  <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                    Use nossa IA para analisar o edital:
                  </Typography>
                  <FeatureGate
                    featureId={FeatureId.AI_ANALYSIS}
                    message="Faça upgrade para usar a IA"
                  >
                    <Button
                      fullWidth={isMobile}
                      variant="contained"
                      color="warning"
                      size={isMobile ? 'small' : 'medium'}
                      onClick={hasAiAccess ? () => setAIChatOpen(true) : undefined}
                      startIcon={<ChatIcon />}
                    >
                      Pergunte à LicitAI
                    </Button>
                  </FeatureGate>
                </Box>

                <Box>
                  <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                    Canais oficiais do portal:
                  </Typography>
                  <Stack direction={isMobile ? 'column' : 'row'} spacing={1} width="100%">
                    <Button
                      fullWidth={isMobile}
                      variant="contained"
                      color="info"
                      size={isMobile ? 'small' : 'medium'}
                      startIcon={<HelpIcon />}
                      href={portalService.getEsclarecimentosUrl() || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {isMobile ? 'Esclarecimento' : 'Enviar Esclarecimento'}
                    </Button>
                    <Button
                      fullWidth={isMobile}
                      variant="contained"
                      color="error"
                      size={isMobile ? 'small' : 'medium'}
                      startIcon={<GavelIcon />}
                      href={portalService.getImpugnacaoUrl() || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {isMobile ? 'Impugnação' : 'Enviar Impugnação'}
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </InfoSection>

            <InfoSection title="Contatos Disponíveis">
              {licitacao.emailContato || licitacao.telefoneContato || licitacao.endereco ? (
                <Stack spacing={1}>
                  {licitacao.emailContato && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <InfoIcon color="info" fontSize="small" />
                      <Link href={`mailto:${licitacao.emailContato}`}>
                        {licitacao.emailContato}
                      </Link>
                    </Stack>
                  )}
                  {licitacao.telefoneContato && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <InfoIcon color="info" fontSize="small" />
                      <Link href={`tel:${licitacao.telefoneContato}`}>
                        {licitacao.telefoneContato}
                      </Link>
                    </Stack>
                  )}
                  {licitacao.endereco && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <InfoIcon color="info" fontSize="small" />
                      <Typography variant="body2">{licitacao.endereco}</Typography>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Nenhum contato adicional disponível.
                </Typography>
              )}
            </InfoSection>
          </Box>
        </Box>
      </Box>

      {hasAiAccess && (
        <AIChatModal open={aiChatOpen} onClose={() => setAIChatOpen(false)} licitacao={licitacao} />
      )}
    </>
  );
};
