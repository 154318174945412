import Api from '@services/api';
import { TrackEvent, TrackEventType } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TrackState {
  events: TrackEvent[];
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalEvents: number;
  totalPages: number;
  itemsPerPage: number;
  lastFetch: number | null;

  trackEvent: (type: TrackEventType, metadata: Record<string, any>) => Promise<void>;
  trackDocumentClick: (licitacaoId: string, documentIndex: number) => Promise<void>;
  fetchEvents: (params?: {
    type?: TrackEventType;
    page?: number;
    forceRefresh?: boolean;
  }) => Promise<void>;
  setCurrentPage: (page: number) => void;
  setItemsPerPage: (perPage: number) => void;
  resetStore: () => void;
}

export const useTrackStore = create<TrackState>()(
  persist(
    (set, get) => ({
      events: [],
      isLoading: false,
      error: null,
      currentPage: 1,
      totalEvents: 0,
      totalPages: 0,
      itemsPerPage: 20,
      lastFetch: null,

      trackEvent: async (type: TrackEventType, metadata: Record<string, any>) => {
        try {
          await Api.track(type, {
            ...metadata,
          });
        } catch (error) {
          console.error('Error tracking event:', error);
        }
      },

      trackDocumentClick: async (licitacaoId: string, documentIndex: number) => {
        await get().trackEvent('document_click', {
          licitacaoId,
          documentIndex: isNaN(documentIndex) ? 0 : documentIndex,
        });
      },

      fetchEvents: async (params = {}) => {
        const { type, page = 1, forceRefresh = false } = params;

        const now = Date.now();
        const lastFetch = get().lastFetch;
        const CACHE_DURATION = 5 * 60 * 1000;

        if (!forceRefresh && page === 1 && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getEvents({
            type,
            page,
            size: get().itemsPerPage,
          });

          set({
            events: response.events,
            totalEvents: response.total,
            totalPages: Math.ceil(response.total / get().itemsPerPage),
            currentPage: page,
            isLoading: false,
            lastFetch: page === 1 ? now : null,
            error: null,
          });
        } catch (error) {
          console.error('Error fetching events:', error);
          set({
            error: error instanceof Error ? error.message : 'Failed to fetch events',
            isLoading: false,
            lastFetch: null,
          });
        }
      },

      setCurrentPage: (page: number) => {
        set({ currentPage: page });
        get().fetchEvents({ page });
      },

      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage, currentPage: 1 });
        get().fetchEvents({ page: 1, forceRefresh: true });
      },

      resetStore: () =>
        set({
          events: [],
          isLoading: false,
          error: null,
          currentPage: 1,
          totalEvents: 0,
          totalPages: 0,
          itemsPerPage: 20,
          lastFetch: null,
        }),
    }),
    {
      name: 'track-storage',
      partialize: (state) => ({
        itemsPerPage: state.itemsPerPage,
      }),
    },
  ),
);
