import logo from '@assets/logo.png';
import NotificationMenu from '@components/Layout/NotificationMenu';
import ProfileSelect from '@components/Layout/ProfileSelect';
import UserMenu from '@components/Layout/UserMenu';
import {
  getTourIdFromPath,
  isTourAvailable,
  useTour as useTourContext,
} from '@contexts/TourContext';
import {
  ArrowDropDown as ArrowDropDownIcon,
  Description as DescriptionIcon,
  FiberManualRecord as FiberManualRecordIcon,
  Newspaper as NewspaperIcon,
  Recommend as RecommendIcon,
  Search as SearchIcon,
  Star as StarIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useRecommendedStore } from '@store/recommendedStore';
import { differenceInHours } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface MenuItem {
  name: string;
  path: string;
  icon: React.ReactElement;
  indicator?: React.ReactElement;
  chip?: React.ReactElement;
  subItems?: SubMenuItem[];
}

interface SubMenuItem {
  name: string;
  path: string;
  icon: React.ReactElement;
  chip?: React.ReactElement;
}
interface HeaderProps {
  className?: string;
  dataTourValue?: string;
  isLoading?: boolean;
}

const Header: React.FC<HeaderProps> = ({ className, dataTourValue, isLoading = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const { recommended } = useRecommendedStore();
  const [anchorEl, setAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});
  const { setIsOpen, setCurrentTourId } = useTourContext();

  const isActive = (path: string) => location.pathname.startsWith(path);

  const handleSubmenuClick = (event: React.MouseEvent<HTMLButtonElement>, itemName: string) => {
    setAnchorEl((prev) => ({ ...prev, [itemName]: event.currentTarget }));
  };

  const handleClose = (itemName: string) => {
    setAnchorEl((prev) => ({ ...prev, [itemName]: null }));
  };

  const handleNavigation = (path: string, itemName: string) => {
    navigate(path);
    handleClose(itemName);
  };

  const handleTourClick = () => {
    const tourId = getTourIdFromPath(location.pathname);
    setCurrentTourId(tourId);
    setIsOpen(true);
  };

  const hasNewRecommended = useMemo(() => {
    if (!recommended) return false;
    if (recommended.length > 0) {
      const hasNew = recommended.some((item) => {
        const recommendedDate = new Date(item.createdAt);
        const today = new Date();
        return differenceInHours(today, recommendedDate) < 8;
      });
      return hasNew;
    }
    return false;
  }, [recommended]);

  const newIndicator = (
    <FiberManualRecordIcon
      sx={{
        fontSize: '0.5rem',
        color: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    />
  );

  const newChip = (
    <Chip
      label="Nova"
      color="primary"
      size="small"
      sx={{
        position: 'absolute',
        top: -8,
        right: -8,
        zIndex: 1,
      }}
    />
  );

  const desktopMenuItems = [
    {
      name: 'Busca',
      path: '/busca',
      icon: <SearchIcon />,
    },
    {
      name: 'Favoritas',
      path: '/minhas-licitacoes/favoritas',
      icon: <StarIcon />,
    },
    {
      name: 'Recomendadas',
      path: '/minhas-licitacoes/recomendadas',
      icon: <RecommendIcon />,
      chip: hasNewRecommended ? newChip : null,
    },
    {
      name: 'Acompanhando',
      path: '/acompanhando',
      icon: <VisibilityIcon />,
    },
    {
      name: 'Boletins',
      path: '/boletins',
      icon: <NewspaperIcon />,
    },
    {
      name: 'Documentos',
      path: '/documentos',
      icon: <DescriptionIcon />,
    },
  ];

  const tabletMenuItems = [
    {
      name: 'Busca',
      path: '/busca',
      icon: <SearchIcon />,
    },
    {
      name: 'Favoritas',
      path: '/minhas-licitacoes',
      icon: <StarIcon />,
      indicator: hasNewRecommended ? newIndicator : null,
      subItems: [
        { name: 'Favoritas', path: '/minhas-licitacoes/favoritas', icon: <StarIcon /> },
        {
          name: 'Recomendadas',
          path: '/minhas-licitacoes/recomendadas',
          icon: <RecommendIcon />,
          chip: hasNewRecommended ? newChip : null,
        },
      ],
    },
    {
      name: 'Acompanhando',
      path: '/acompanhando',
      icon: <VisibilityIcon />,
    },
    {
      name: 'Boletins',
      path: '/boletins',
      icon: <NewspaperIcon />,
    },
    {
      name: 'Documentos',
      path: '/documentos',
      icon: <DescriptionIcon />,
    },
  ];

  const mobileMenuItems = useMemo(() => {
    const mainItems = tabletMenuItems.slice(0, 3);
    const moreItems = tabletMenuItems.slice(3);

    return [
      ...mainItems,
      {
        name: 'more',
        path: '',
        icon: <MoreVertIcon />,
        subItems: moreItems.map((item) => ({
          name: item.name,
          path: item.path,
          icon: item.icon,
        })),
      },
    ];
  }, [tabletMenuItems]);

  const getMenuItems = () => {
    if (isMobile) return mobileMenuItems;
    if (isTablet) return tabletMenuItems;
    return desktopMenuItems;
  };

  const getMenuLabel = (item: MenuItem) => {
    if (item.name === 'Favoritas') {
      return location.pathname.includes('/minhas-licitacoes/recomendadas')
        ? 'Recomendadas'
        : 'Favoritas';
    }
    return item.name;
  };

  const getMenuIcon = (item: MenuItem) => {
    if (item.name === 'Favoritas') {
      return location.pathname.includes('/minhas-licitacoes/recomendadas') ? (
        <RecommendIcon />
      ) : (
        <StarIcon />
      );
    }
    return item.icon;
  };

  const renderMenuItem = (item: MenuItem, isMobile: boolean) => {
    if (item.subItems) {
      return (
        <React.Fragment key={item.name}>
          <Box sx={{ position: 'relative' }}>
            {isMobile ? (
              <IconButton
                color="inherit"
                onClick={(e) => handleSubmenuClick(e, item.name)}
                sx={{
                  color: isActive(item.path)
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                }}
              >
                {React.cloneElement(getMenuIcon(item), {
                  style: {
                    color: isActive(item.path)
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  },
                })}
                {item.indicator}
              </IconButton>
            ) : (
              <Button
                color="inherit"
                onClick={(e) => handleSubmenuClick(e, item.name)}
                startIcon={getMenuIcon(item)}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                  color: isActive(item.path)
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                  backgroundColor: isActive(item.path)
                    ? theme.palette.action.selected
                    : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                {getMenuLabel(item)}
                {item.indicator}
              </Button>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl[item.name]}
            open={Boolean(anchorEl[item.name])}
            onClose={() => handleClose(item.name)}
          >
            {item.subItems.map((subItem) => (
              <MenuItem
                key={subItem.name}
                onClick={() => handleNavigation(subItem.path, item.name)}
              >
                {React.cloneElement(subItem.icon, { style: { marginRight: 8 } })}
                {subItem.name}
                {subItem.chip && (
                  <Box id={`sub-item-chip-box-${subItem.name}`} sx={{ ml: 1 }}>
                    {subItem.chip}
                  </Box>
                )}
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    } else {
      return isMobile ? (
        <Box sx={{ position: 'relative' }} key={item.name}>
          {item.chip && (
            <Box sx={{ position: 'absolute', top: -8, right: -8, zIndex: 1 }}>{item.chip}</Box>
          )}
          <IconButton
            color="inherit"
            onClick={() => navigate(item.path)}
            sx={{
              color: isActive(item.path) ? theme.palette.primary.main : theme.palette.text.primary,
            }}
          >
            {React.cloneElement(item.icon, {
              style: {
                color: isActive(item.path)
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
              },
            })}
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ position: 'relative' }} key={item.name}>
          {item.chip && (
            <Box sx={{ position: 'absolute', top: -8, right: -8, zIndex: 1 }}>{item.chip}</Box>
          )}
          <Button
            color="inherit"
            onClick={() => navigate(item.path)}
            startIcon={item.icon}
            sx={{
              color: isActive(item.path) ? theme.palette.primary.main : theme.palette.text.primary,
              backgroundColor: isActive(item.path) ? theme.palette.action.selected : 'transparent',
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              ...(isTablet && {
                padding: '6px 8px',
                minWidth: 'unset',
                fontSize: '0.875rem',
                '& .MuiButton-startIcon': {
                  marginRight: '4px',
                },
              }),
            }}
          >
            {item.name}
          </Button>
        </Box>
      );
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Toolbar
        sx={{
          ...(isTablet && {
            minHeight: '56px',
            padding: '0 8px',
          }),
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Link
            to="/busca"
            style={{
              textDecoration: 'none',
              color: theme.palette.text.primary,
              display: 'flex',
              alignItems: 'center',
              marginRight: isTablet ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            <img
              src={logo}
              alt="Licitou Logo"
              style={{
                height: isTablet ? 32 : 40,
                marginRight: isMobile ? 0 : isTablet ? 4 : 8,
              }}
            />
            {!isMobile && <Typography variant={isTablet ? 'subtitle1' : 'h6'}>Licitou</Typography>}
          </Link>
          <ProfileSelect />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: isMobile ? 0.5 : isTablet ? 0.5 : 1,
          }}
          className={className}
        >
          {getMenuItems().map((item) => renderMenuItem(item as MenuItem, isMobile))}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: isMobile ? 0.5 : isTablet ? 1 : 2,
            }}
          />
          {!isLoading && <NotificationMenu />}
          {!isMobile && !isTablet && (
            <>
              {isTourAvailable(location.pathname) && (
                <IconButton
                  color="inherit"
                  onClick={handleTourClick}
                  sx={{
                    ml: isTablet ? 0.5 : 1,
                    padding: isTablet ? '4px' : '8px',
                    color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                  }}
                >
                  <HelpOutlineIcon />
                </IconButton>
              )}
              {/* <IconButton
                color="inherit"
                onClick={() => navigate('/empresa')}
                data-tour={dataTourValue || 'config-button'}
                sx={{
                  ml: isTablet ? 0.5 : 1,
                  mr: isTablet ? 1 : 2,
                  padding: isTablet ? '4px' : '8px',
                  color: isActive('/empresa')
                    ? theme.palette.info.main
                    : theme.palette.text.primary,
                }}
              >
                <BusinessIcon />
              </IconButton> */}
            </>
          )}
          <UserMenu data-tour={dataTourValue || 'config-button'} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
