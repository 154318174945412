import Api from '@services/api';
import { Favorite, Licitacao, LicitacaoStatusFilter, LicitacaoViewFormat } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface FavoritesState {
  favorites: Favorite[];
  favoriteLicitacoes: Licitacao[];
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalFavorites: number;
  totalPages: number;
  itemsPerPage: number;
  viewFormat: LicitacaoViewFormat;
  setItemsPerPage: (perPage: number) => void;
  setViewFormat: (format: LicitacaoViewFormat) => void;
  fetchFavorites: (force?: boolean) => Promise<void>;
  fetchFavoritesLicitacoes: (
    page?: number,
    forceRefresh?: boolean,
    status?: LicitacaoStatusFilter,
    itemsPerPage?: number,
  ) => Promise<void>;
  addFavorite: (licitacaoId: string) => Promise<void>;
  removeFavorite: (licitacaoId: string) => Promise<void>;
  isFavorite: (licitacaoId: string) => boolean;
  resetStore: () => void;
  setCurrentPage: (page: number) => void;
  lastFetch: number | null;
  lastFetchList: number | null;
  status: LicitacaoStatusFilter;
  setStatus: (status: LicitacaoStatusFilter) => void;
}

export const useFavoritesStore = create<FavoritesState>()(
  persist(
    (set, get) => ({
      favorites: [],
      favoriteLicitacoes: [],
      isLoading: false,
      error: null,
      currentPage: 1,
      totalFavorites: 0,
      totalPages: 0,
      itemsPerPage: 20,
      viewFormat: 'list',
      lastFetch: null,
      lastFetchList: null,
      status: 'open',

      fetchFavorites: async (force = false) => {
        set({ isLoading: true, error: null });
        try {
          const now = Date.now();
          const lastFetch = get().lastFetch;
          const CACHE_DURATION = 5 * 60 * 1000;

          if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
            return;
          }

          const response = await Api.getFavorites();
          set({
            favorites: response.favorites,
            totalFavorites: response.total,
            isLoading: false,
            lastFetch: now,
            lastFetchList: null,
          });
        } catch (error: any) {
          console.error('Error fetching favorites:', error);
          const errorMessage = error.message || 'Erro ao buscar favoritos';
          set({ error: errorMessage, isLoading: false, lastFetch: null });
          throw error;
        }
      },

      fetchFavoritesLicitacoes: async (
        page: number = 1,
        forceRefresh = false,
        status: LicitacaoStatusFilter | undefined = undefined,
        itemsPerPage: number | undefined = undefined,
      ) => {
        const now = Date.now();
        const lastFetchList = get().lastFetchList;
        const CACHE_DURATION = 5 * 60 * 1000;

        const shouldSaveCache =
          (!status || status === get().status) &&
          (!itemsPerPage || itemsPerPage === get().itemsPerPage);

        if (
          !forceRefresh &&
          page === 1 &&
          lastFetchList &&
          now - lastFetchList < CACHE_DURATION &&
          shouldSaveCache
        ) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getFavoriteLicitacoes(
            page,
            itemsPerPage ? itemsPerPage : get().itemsPerPage,
            status ? status : get().status,
          );
          set({
            favoriteLicitacoes: response.licitacoes,
            totalFavorites: response.totalLicitacoes,
            totalPages: response.paginas,
            isLoading: false,
            currentPage: page,
            lastFetchList: shouldSaveCache ? now : null,
          });
        } catch (error) {
          console.error('Error fetching favorite licitacoes:', error);
          set({
            error: 'Erro ao buscar licitações favoritas',
            isLoading: false,
            totalPages: 0,
          });
        }
      },

      addFavorite: async (licitacaoId: string) => {
        const previousFavorites = [...get().favorites];
        set({ isLoading: true, error: null });
        try {
          const newFavorite = await Api.addFavorite(licitacaoId);
          set((state) => ({
            favorites: [...state.favorites, newFavorite],
            lastFetchList: null,
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error adding favorite:', error);
          const errorMessage = error.message || 'Erro ao adicionar favorito';
          set({ error: errorMessage, favorites: previousFavorites, isLoading: false });
          throw error;
        }
      },

      removeFavorite: async (licitacaoId: string) => {
        const previousState = {
          favorites: [...get().favorites],
          favoriteLicitacoes: [...get().favoriteLicitacoes],
        };
        set({ isLoading: true, error: null });
        try {
          await Api.removeFavorite(licitacaoId);
          set((state) => ({
            favorites: state.favorites.filter((fav) => fav.licitacaoId !== licitacaoId),
            favoriteLicitacoes: state.favoriteLicitacoes.filter((l) => l.id !== licitacaoId),
            lastFetchList: null,
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error removing favorite:', error);
          const errorMessage = error.message || 'Erro ao remover favorito';
          set({ ...previousState, error: errorMessage, isLoading: false });
          throw error;
        }
      },

      isFavorite: (licitacaoId: string) => {
        return get().favorites.some((fav) => fav.licitacaoId === licitacaoId);
      },

      setCurrentPage: (page: number) => set({ currentPage: page }),
      resetStore: () =>
        set({
          favorites: [],
          favoriteLicitacoes: [],
          isLoading: false,
          error: null,
          currentPage: 1,
          totalFavorites: 0,
          totalPages: 0,
          itemsPerPage: 20,
          viewFormat: 'list',
          lastFetchList: null,
          status: 'open',
        }),

      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage, lastFetchList: null, currentPage: 1 });
        get().fetchFavoritesLicitacoes(1, true);
      },

      setViewFormat: (format: LicitacaoViewFormat) => set({ viewFormat: format }),

      setStatus: (status: LicitacaoStatusFilter) => {
        set({ status, lastFetchList: null, currentPage: 1 });
        get().fetchFavoritesLicitacoes(1, true);
      },
    }),
    {
      name: 'favorites-storage',
      partialize: (state) => ({
        itemsPerPage: state.itemsPerPage,
        viewFormat: state.viewFormat,
        status: state.status,
      }),
    },
  ),
);
