import MessageBox from '@components/Common/MessageBox';
import UpgradeButton from '@components/Feature/UpgradeButton';
import AiSearchModal from '@components/Search/AiSearchModal';
import { useAnalytics } from '@hooks/useAnalytics';
import { useFeatureAccess } from '@hooks/useFeatureAccess';
import { AutoAwesome, Close, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useBulletinStore } from '@store/bulletinStore';
import { useProfileStore } from '@store/profileStore';
import { useRecommendedStore } from '@store/recommendedStore';
import { useSavedSearchesStore } from '@store/savedSearchesStore';
import { Bulletin, FeatureId, LicitacoesSearchParams } from '@types';
import {
  buildAiGeneratedSearch,
  buildAiSearches,
  getPreferencesAsSearchParams,
} from '@utils/search';
import React, { useEffect, useState } from 'react';
import BulletinSearchParams from './BulletinSearchFilters';

interface BulletinModalProps {
  open: boolean;
  onClose: () => void;
  onCreateBulletin?: (name: string, searchParams: LicitacoesSearchParams) => Promise<void>;
  onEditBulletin?: (
    bulletinId: string,
    name: string,
    searchParams: LicitacoesSearchParams,
  ) => Promise<void>;
  usePreferences?: boolean;
  bulletin?: Bulletin;
}

const BulletinModal: React.FC<BulletinModalProps> = ({
  open,
  onClose,
  onCreateBulletin = undefined,
  onEditBulletin = undefined,
  usePreferences = false,
  bulletin = undefined,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [name, setName] = useState('');
  const [editingBulletin, setEditingBulletin] = useState<Bulletin | undefined>(bulletin);
  const [searchParams, setSearchParams] = useState<LicitacoesSearchParams>({});
  const [initialSearchParams, setInitialSearchParams] = useState<LicitacoesSearchParams>({});
  const { isLoadingBulletins } = useBulletinStore();
  const [error, setError] = useState('');
  const { trackEvent } = useAnalytics();
  const { userPreferences, company } = useProfileStore();
  const { savedSearches } = useSavedSearchesStore();
  const { aiProfile } = useRecommendedStore();
  const [isAiSearchModalOpen, setIsAiSearchModalOpen] = useState(false);
  const { hasAccess: hasAiAccess } = useFeatureAccess(FeatureId.AI_ANALYSIS);

  useEffect(() => {
    if (open) {
      const params =
        usePreferences && userPreferences
          ? getPreferencesAsSearchParams(userPreferences, company)
          : bulletin?.searchParams || {};

      setName(usePreferences ? 'Boletim de Preferências' : bulletin?.name || '');
      setInitialSearchParams(params);
      setSearchParams(params);
      setEditingBulletin(bulletin);
    }
  }, [open, bulletin, usePreferences, userPreferences, company]);

  const handleSubmit = async (isCreate: boolean) => {
    if (name === '') {
      setError('O nome do boletim é obrigatório');
      trackEvent('bulletin_error', {
        error_type: 'empty_name',
        is_editing: !isCreate,
      });
      return;
    }

    try {
      if (isCreate && onCreateBulletin) {
        await onCreateBulletin(name, searchParams);
        trackEvent('bulletin_create', {
          name_length: name.length,
          params_count: Object.keys(searchParams).length,
        });
      } else if (onEditBulletin && editingBulletin?.id) {
        await onEditBulletin(editingBulletin?.id, name, searchParams);
        trackEvent('bulletin_update', {
          bulletin_id: editingBulletin.id,
          name_changed: name !== editingBulletin.name,
          params_changed:
            JSON.stringify(searchParams) !== JSON.stringify(editingBulletin.searchParams),
        });
      }
      handleOnClose();
    } catch (error) {
      trackEvent('bulletin_error', {
        is_create: isCreate,
        error_message: error instanceof Error ? error.message : String(error),
      });
    }
  };

  const handleOnClose = () => {
    setName('');
    setEditingBulletin(undefined);
    onClose();
  };

  const handleParamsChange = (newParams: LicitacoesSearchParams) => {
    setSearchParams(newParams);
  };

  const handleSuggestionSelect = (params: LicitacoesSearchParams, suggestionName: string) => {
    setName(`Boletim - ${suggestionName}`);
    setSearchParams(params);
    setInitialSearchParams(params);
  };

  const handleAiSearchGenerated = (response: any) => {
    const aiGeneratedSearch = buildAiGeneratedSearch(response);
    handleSuggestionSelect(aiGeneratedSearch.params, aiGeneratedSearch.name);
  };

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      maxWidth="md"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: `calc(100% - ${isMobile ? '100px' : '120px'})`,
          margin: isMobile ? '16px' : '32px',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle>{editingBulletin ? 'Editar Boletim' : 'Criar Novo Boletim'}</DialogTitle>
        <IconButton onClick={handleOnClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        {!editingBulletin && (
          <Box sx={{ mb: 3 }}>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Sugestões de busca:
              </Typography>
              {hasAiAccess ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={!isMobile ? <AutoAwesome fontSize="small" /> : undefined}
                  onClick={() => setIsAiSearchModalOpen(true)}
                >
                  {isMobile ? <AutoAwesome /> : 'Novo Boletim AI'}
                </Button>
              ) : (
                <UpgradeButton
                  featureId={FeatureId.AI_ANALYSIS}
                  message="Usar AI para criar boletim"
                  compact
                  size="small"
                  startIcon={<AutoAwesome fontSize="small" />}
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {userPreferences && (
                <Chip
                  icon={<Settings fontSize="small" />}
                  label="Baseado em Preferências"
                  onClick={() =>
                    handleSuggestionSelect(
                      getPreferencesAsSearchParams(userPreferences, company),
                      'Preferências',
                    )
                  }
                  sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}
                />
              )}
              {aiProfile &&
                buildAiSearches(aiProfile).map((search, index) => (
                  <Chip
                    key={`ai-${index}`}
                    icon={<AutoAwesome fontSize="small" />}
                    label={search.name}
                    onClick={() => handleSuggestionSelect(search.params, search.name)}
                    sx={{ backgroundColor: 'primary.light', color: 'primary.dark' }}
                  />
                ))}
              {savedSearches.map((search) => (
                <Chip
                  key={search.id}
                  label={search.name}
                  onClick={() => handleSuggestionSelect(search.params, search.name)}
                />
              ))}
            </Box>
          </Box>
        )}

        <TextField
          autoFocus
          margin="dense"
          label="Nome do Boletim"
          fullWidth
          value={name}
          error={error !== ''}
          helperText={error}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />

        <MessageBox variant="info" sx={{ mb: 3 }}>
          Os campos de busca abaixo ajudarão a filtrar as licitações relevantes para o seu boletim.
          Preencha os campos desejados para personalizar suas buscas e receber notificações
          específicas.
        </MessageBox>

        <BulletinSearchParams
          onParamsChange={handleParamsChange}
          initialParams={initialSearchParams}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Cancelar</Button>
        <Button
          onClick={() => handleSubmit(editingBulletin === undefined)}
          variant="contained"
          color="primary"
          disabled={isLoadingBulletins}
        >
          {isLoadingBulletins
            ? editingBulletin
              ? 'Editando...'
              : 'Criando...'
            : editingBulletin
              ? 'Salvar'
              : 'Criar'}
        </Button>
      </DialogActions>

      <AiSearchModal
        open={isAiSearchModalOpen}
        onClose={() => setIsAiSearchModalOpen(false)}
        onSearchGenerated={handleAiSearchGenerated}
        context="Esta busca é para criar um boletim de licitações, então adicione estados, cidades e palavras-chave baseado no perfil da empresa e nas preferências do usuário. Evite palavras-chave genéricas como 'serviço', 'produto', 'material', 'equipamento', etc."
        title="Gerar Boletim com AI"
        description="Descreva o tipo de licitações que você quer receber notificações. A AI irá gerar um boletim personalizado baseado no seu perfil."
        placeholder="Ex: Quero receber notificações de licitações de material de escritório em Minas Gerais, principalmente papel A4, canetas e grampeadores"
      />
    </Dialog>
  );
};

export default BulletinModal;
