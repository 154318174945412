import { Recommend, Star } from '@mui/icons-material';
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useRecommendedStore } from '@store/recommendedStore';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const RecommendedLicitacao: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { totalFavorites } = useFavoritesStore();
  const { totalRecommended } = useRecommendedStore();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/minhas-licitacoes/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'recomendadas';

  const TabLabel: React.FC<{ label: string; count?: number; icon: React.ReactNode }> = ({
    label,
    count,
    icon,
  }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      {React.cloneElement(icon as React.ReactElement, { sx: { mr: 1 } })}
      {label}
      {count !== undefined && (
        <Box
          component="span"
          sx={{
            marginLeft: '8px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            borderRadius: '16px',
            padding: '2px 8px',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            minWidth: '24px',
            textAlign: 'center',
            lineHeight: 1.5,
            boxShadow: `0 0 0 1px ${theme.palette.divider}`,
          }}
        >
          {count}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Helmet>
        <title>
          {value === 'recomendadas'
            ? `Licitações Recomendadas ${totalRecommended ? `(${totalRecommended})` : ''} - Licitou`
            : `Licitações Favoritas ${totalFavorites ? `(${totalFavorites})` : ''} - Licitou`}
        </title>
        <meta
          name="description"
          content="Acompanhe suas licitações recomendadas e favoritas em um só lugar"
        />
      </Helmet>
      <Box sx={{ my: isMobile ? 0.5 : 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            borderBottom: 1,
            borderColor: 'divider',
            gap: isMobile ? 2 : 0,
          }}
        >
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            component="h1"
            color="primary"
            sx={{
              width: isMobile ? '100%' : '20%',
              flexShrink: 0,
            }}
          >
            Minhas Licitações
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="minhas licitacoes tabs"
            sx={{
              flex: 1,
              width: isMobile ? '100%' : 'auto',
              '& .MuiTab-root': {
                textTransform: 'none',
                fontWeight: 'medium',
                fontSize: isMobile ? '0.875rem' : '1rem',
                minHeight: '48px',
                padding: '6px 16px',
              },
              '& .Mui-selected': {
                fontWeight: 'bold',
              },
            }}
            variant={isMobile ? 'fullWidth' : 'standard'}
          >
            <Tab
              label={<TabLabel label="Favoritas" count={totalFavorites} icon={<Star />} />}
              value="favoritas"
            />
            <Tab
              label={
                <TabLabel
                  label="Recomendadas"
                  count={totalRecommended > 0 ? totalRecommended : undefined}
                  icon={<Recommend />}
                />
              }
              value="recomendadas"
            />
          </Tabs>
        </Box>
        <Box sx={{ p: isMobile ? 0.5 : 2 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default RecommendedLicitacao;
