import InfoSection from '@components/Common/InfoSection';
import MessageBox from '@components/Common/MessageBox';
import AddToCalendarButton from '@components/Licitacao/AddToCalendarButton';
import AddToKanbanButton from '@components/Licitacao/AddToKanbanButton';
import { Event as EventIcon } from '@mui/icons-material';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Licitacao, PortalService } from '@types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

interface DoneStepProps {
  portalService: PortalService;
  licitacao: Licitacao;
  portalName: string;
}

export const DoneStep: React.FC<DoneStepProps> = ({ licitacao }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const formattedDate = licitacao.abertura_datetime
    ? format(new Date(licitacao.abertura_datetime), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
        locale: ptBR,
      })
    : 'Data não definida';

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: isMobile ? 2 : 3,
      }}
    >
      <MessageBox variant="success">
        {isMobile
          ? 'Parabéns! Você completou todos os passos necessários.'
          : 'Se você chegou até aqui, completou todos os passos para participar da licitação! Mantenha-se conectado ao portal para prosseguir com o processo.'}
      </MessageBox>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          width: '100%',
          mt: isMobile ? 1 : 3,
        }}
      >
        {/* Left Column */}
        <Box
          sx={{
            flex: 1,
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 2 : 3,
          }}
        >
          <InfoSection title="Próximos Passos">
            <AddToKanbanButton
              licitacao={licitacao}
              onSuccess={() => navigate('/acompanhando/kanban')}
              fullWidth
              sx={{ mb: isMobile ? 1 : 2 }}
            />
          </InfoSection>

          <InfoSection title="Sessão Pública">
            <Stack spacing={isMobile ? 1 : 2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <EventIcon color="primary" fontSize="small" />
                <Typography variant="body2">
                  {isMobile ? 'Abertura:' : 'Data de abertura:'} {formattedDate}
                </Typography>
              </Stack>
              <AddToCalendarButton licitacao={licitacao} />
            </Stack>
          </InfoSection>

          <InfoSection title="Durante a Sessão">
            <Typography variant="body2" component="div">
              <ul style={{ paddingLeft: isMobile ? '1rem' : '2rem' }}>
                <li>Mantenha-se conectado ao portal</li>
                <li>Acompanhe o chat do pregoeiro</li>
                <li>Prepare-se para os lances</li>
                {!isMobile && (
                  <>
                    <li>Responda prontamente às solicitações</li>
                    <li>Tenha os documentos complementares em mãos</li>
                  </>
                )}
              </ul>
            </Typography>
          </InfoSection>
        </Box>

        {/* Right Column */}
        <Box
          sx={{
            flex: 1,
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            gap: isMobile ? 2 : 3,
          }}
        >
          <InfoSection title="Após a Sessão">
            <Typography variant="body2" component="div">
              <ul style={{ paddingLeft: isMobile ? '1rem' : '2rem' }}>
                <li>Monitore o resultado</li>
                <li>Acompanhe recursos</li>
                <li>Verifique convocações</li>
                {!isMobile && <li>Prepare-se para assinatura do contrato se vencedor</li>}
              </ul>
            </Typography>
          </InfoSection>

          {!isMobile && (
            <InfoSection title="Em Caso de Vitória">
              <Typography variant="body2" component="div">
                <ul>
                  <li>Envie a documentação original no prazo</li>
                  <li>Providencie a garantia contratual</li>
                  <li>Assine o contrato dentro do prazo</li>
                  <li>Prepare a logística para o fornecimento</li>
                  <li>Mantenha contato com o fiscal do contrato</li>
                </ul>
              </Typography>
            </InfoSection>
          )}
        </Box>
      </Box>
    </Box>
  );
};
