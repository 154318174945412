import { useAuth } from '@contexts/AuthContext';
import {
  trackEvent as firebaseTrackEvent,
  trackPageView as firebaseTrackPageView,
} from '@services/firebase';
import { useProfileStore } from '@store/profileStore';
import { AppErrorEventType, AppEventType, SubscriptionStatus, TrackEventType } from '@types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useAnalytics = () => {
  const location = useLocation();
  const { user } = useAuth();
  const { company, userData } = useProfileStore();

  const trackPublicEvent = (
    eventName: TrackEventType | AppErrorEventType | AppEventType,
    eventParams?: Record<string, any>,
  ) => {
    firebaseTrackEvent(eventName, {
      is_public: true,
      path: location.pathname,
      ...eventParams,
    });
  };

  const trackPageView = (title: string, customParams?: Record<string, any>) => {
    const baseParams = user
      ? {
          user_id: user.uid,
          user_role: userData?.role,
          company_id: company?.razaoSocial,
          subscription_status: userData?.subscriptionStatus,
          path: location.pathname,
          page_title: title,
          is_trial: userData?.subscriptionStatus === SubscriptionStatus.TRIALING,
          is_active: userData?.isActive,
          is_public: false,
        }
      : {
          path: location.pathname,
          page_title: title,
          is_public: true,
        };

    firebaseTrackPageView(title, {
      ...baseParams,
      ...customParams,
    });
  };

  const trackEvent = (
    eventName: TrackEventType | AppErrorEventType | AppEventType,
    eventParams?: Record<string, any>,
  ) => {
    const baseParams = user
      ? {
          user_id: user.uid,
          user_role: userData?.role,
          company_id: company?.razaoSocial,
          subscription_status: userData?.subscriptionStatus,
          is_public: false,
        }
      : {
          is_public: true,
          path: location.pathname,
        };

    firebaseTrackEvent(eventName, {
      ...baseParams,
      ...eventParams,
    });
  };

  useEffect(() => {
    trackPageView(document.title);
  }, [location.pathname, user, userData?.subscriptionStatus]);

  return {
    trackEvent,
    trackPublicEvent,
    trackPageView,
  };
};
