import { features } from '@constants/plans';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DisabledPlan, FeatureId, Plan, PlanDuration, PlanId } from '@types';
import React, { useState } from 'react';

interface ComparePaymentPlansModalProps {
  isOpen: boolean;
  onClose: () => void;
  plans: Plan[];
  planDurations: PlanDuration[];
  onChoose: (planId: string, durationId: string) => void;
  primaryButtonText?: string;
  disabledPlans?: DisabledPlan[];
  featuredPlanId?: PlanId;
}

const ComparePaymentPlansModal: React.FC<ComparePaymentPlansModalProps> = ({
  isOpen,
  onClose,
  plans,
  planDurations,
  onChoose,
  primaryButtonText = 'Começar Agora',
  disabledPlans = [],
  featuredPlanId = PlanId.PREMIUM,
}) => {
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const theme = useTheme();

  const isPlanDisabled = (planId: PlanId) => {
    return disabledPlans.some(
      (disabled) =>
        disabled.planId === planId && disabled.durationId === planDurations[activeDurationIndex].id,
    );
  };

  const getDisabledMessage = (planId: PlanId) => {
    const disabledPlan = disabledPlans.find(
      (disabled) =>
        disabled.planId === planId && disabled.durationId === planDurations[activeDurationIndex].id,
    );
    return disabledPlan?.message;
  };

  const renderFeatureRow = (featureId: FeatureId | boolean) => {
    if (typeof featureId !== 'string') return null;
    const feature = features[featureId];
    if (!feature) return null;

    return (
      <Box key={featureId} display="flex" alignItems="center" mt={1}>
        <Box width={24} display="flex" alignItems="center">
          {feature ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
        <Box ml={1} flex={1}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="body2">{feature.title}</Typography>
            {feature.comingSoon && (
              <Chip
                label="Em breve"
                size="small"
                color="warning"
                sx={{ height: 20, fontSize: '0.75rem' }}
              />
            )}
          </Box>
          {feature.description && (
            <Typography variant="caption" color="text.secondary" display="block">
              {feature.description}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { borderRadius: 2 } }}
    >
      <DialogContent sx={{ p: 4 }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>

        <Stack spacing={4} alignItems="center">
          <Typography variant="h4" component="h2" textAlign="center">
            Compare os planos
          </Typography>

          <ToggleButtonGroup
            value={activeDurationIndex}
            exclusive
            onChange={(_, value) => value !== null && setActiveDurationIndex(value)}
            sx={{ mb: 4 }}
          >
            {planDurations.map((duration, index) => (
              <ToggleButton key={duration.id} value={index}>
                {duration.switcherText}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            width="100%"
            justifyContent="center"
          >
            {plans.map((plan) => {
              const isDisabled = isPlanDisabled(plan.id as PlanId);
              const disabledMessage = getDisabledMessage(plan.id as PlanId);
              const isFeatured = plan.id === featuredPlanId;

              return (
                <Card
                  key={plan.id}
                  sx={{
                    width: { xs: '100%', md: '30%' },
                    p: 3,
                    position: 'relative',
                    transform: isFeatured ? 'scale(1.02)' : 'none',
                    transition: 'all 0.3s ease',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    border:
                      theme.palette.mode === 'light'
                        ? isFeatured
                          ? `2px solid ${theme.palette.secondary.main}`
                          : 'none'
                        : isFeatured
                          ? `2px solid ${theme.palette.secondary.light}`
                          : 'none',
                    bgcolor: isDisabled ? 'action.disabledBackground' : 'background.paper',
                    opacity: isDisabled ? 0.7 : 1,
                    '&:hover': {
                      transform: isDisabled
                        ? 'none'
                        : isFeatured
                          ? 'scale(1.03) translateY(-4px)'
                          : 'translateY(-4px)',
                      boxShadow: isDisabled ? undefined : theme.shadows[8],
                    },
                  }}
                >
                  {isFeatured && !isDisabled && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        bgcolor: 'secondary.main',
                        color: 'secondary.contrastText',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        fontWeight: 'medium',
                      }}
                    >
                      Recomendado
                    </Box>
                  )}

                  {isDisabled && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: isFeatured ? 50 : 16,
                        right: 16,
                        bgcolor: 'info.main',
                        color: 'info.contrastText',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.75rem',
                      }}
                    >
                      {disabledMessage}
                    </Box>
                  )}

                  <Stack spacing={2}>
                    <Typography variant="h6">{plan.name}</Typography>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          textDecoration: 'line-through',
                          color: 'text.secondary',
                          mb: 1,
                        }}
                      >
                        {plan.originalPrice[activeDurationIndex]}
                      </Typography>
                      <Typography variant="h4">
                        {plan.durationPrices[activeDurationIndex]}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle2">
                      {planDurations[activeDurationIndex].text}
                    </Typography>

                    <Box sx={{ my: 2 }}>
                      <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                        {plan.mainFeature}
                      </Typography>

                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Recursos Básicos
                      </Typography>
                      {plan.features.slice(0, 8).map(renderFeatureRow)}

                      {plan.features.some((f) =>
                        features[f as FeatureId]?.availableIn.includes(plan.id),
                      ) && (
                        <>
                          <Typography variant="subtitle2" color="text.secondary" mt={3} mb={1}>
                            Recursos Premium
                          </Typography>
                          {plan.features
                            .slice(8, 14)
                            .filter((f) => features[f as FeatureId]?.availableIn.includes(plan.id))
                            .map(renderFeatureRow)}
                        </>
                      )}

                      {plan.id === 'multi_empresa' && (
                        <>
                          <Typography variant="subtitle2" color="text.secondary" mt={3} mb={1}>
                            Recursos Multi-Empresa
                          </Typography>
                          {plan.features.slice(14).map(renderFeatureRow)}
                        </>
                      )}
                    </Box>

                    <Button
                      variant={isFeatured ? 'contained' : 'outlined'}
                      color={isFeatured ? 'secondary' : 'primary'}
                      onClick={() =>
                        !isDisabled && onChoose(plan.id, planDurations[activeDurationIndex].id)
                      }
                      fullWidth
                      disabled={isDisabled}
                    >
                      {isDisabled ? disabledMessage : primaryButtonText}
                    </Button>
                  </Stack>
                </Card>
              );
            })}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ComparePaymentPlansModal;
