import { FollowingItemsList } from '@components/Following/FollowingItemsList';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Licitacao, PortalService } from '@types';

interface ItemsStepProps {
  licitacao: Licitacao;
  portalService: PortalService;
}

export const ItemsStep: React.FC<ItemsStepProps> = ({ licitacao }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: isMobile ? 1 : 2,
      }}
    >
      <Box sx={{ mb: isMobile ? 0.5 : 1 }}>
        <Typography
          variant={isMobile ? 'subtitle1' : 'h6'}
          color="primary"
          sx={{
            fontSize: isMobile ? '0.9rem' : '1rem',
            mb: 0.5,
          }}
        >
          Seleção de Itens
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 0.5,
            fontSize: isMobile ? '0.813rem' : '0.875rem',
          }}
        >
          {isMobile
            ? 'Selecione os itens de interesse.'
            : 'Analise e selecione os itens que sua empresa tem interesse e capacidade de fornecer. Use a barra de busca para encontrar e adicionar itens à sua seleção.'}
        </Typography>
      </Box>

      <FollowingItemsList licitacao={licitacao} />
    </Box>
  );
};
